import { useRef } from 'react'
import { useOutsideClick } from 'hooks'
import Flex from 'components/priority/Flex/Flex'
import * as S from './HiddenMenu.styled'
import zLayers from 'utils/styling/zLayers'

export default ({
  show: [show, setShow] = [false, () => {}],
  parentElement = () => {},
  hiddenElement = () => {},
  full,
  left,
  margin,
  outsideClickType,
  ...props
}) => {
  const menu = useRef(null)
  useOutsideClick(menu, show, () => setShow(false), outsideClickType)

  return (
    <Flex column margin={margin} {...props} ref={menu}>
      {parentElement()}
      <S.RefWrapper ref={menu} show={show}>
        <S.HiddenMenu show={show} position="relative" zindex={zLayers.base}>
          <Flex position="absolute" right={left && '0px'} full={full}>
            {hiddenElement()}
          </Flex>
        </S.HiddenMenu>
      </S.RefWrapper>
    </Flex>
  )
}
