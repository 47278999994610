import styled, { css } from 'styled-components'
import spacing from 'styles/spacing'
import sizing from 'styles/sizing'
import decorations from 'styles/decorations'
import image from 'styles/image'

export const Image = styled.img(
  ({ ...props }) => css`
    ${spacing(props)}
    ${sizing(props)}
    ${decorations(props)}
    ${image(props)}
  `,
)
