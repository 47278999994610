import { css } from 'styled-components'
import type { DefaultTheme } from 'styled-components'
import type { CSS, ElementStyles, StyleVariant } from 'types/style'

type StyleVariantProps = {
  [K in StyleVariant]?: boolean
}

type AdditionalStyleProps = {
  [key: string]: CSS | undefined
}

export type StyleProps = StyleVariantProps &
  AdditionalStyleProps & {
    children?: React.ReactNode // QUESTION: can remove?
    theme?: DefaultTheme // QUESTION: can remove?
  }

type StyleTypes = {
  [K in keyof ElementStyles]?: CSS
} & {
  [key: string]: CSS | undefined
}

type VariantPropEntry = [prop: StyleVariant, value: boolean]

type AdditionalPropEntry = [
  prop: string,
  value: CSS | undefined | React.ReactNode | DefaultTheme, // QUESTION: can remove?
]

type PropEntry = VariantPropEntry | AdditionalPropEntry

/**
 * Converts boolean props to their corresponding CSS styles.
 *
 * @example
 * // Component usage:
 * <Button primary outline />
 *
 * // Style definitions:
 * const buttonStyles = {
 *   primary: css`background: blue;`,
 *   outline: css`border: 1px solid;`
 * }
 *
 * // In styled component:
 * ${props => propsToCSS(props, buttonStyles)}
 *
 * @param allProps - Component props object including:
 *   - Boolean flags for styles (e.g., primary: true)
 *
 * @param types - Object mapping prop names to CSS styles:
 *   - Keys match the boolean prop names
 *   - Values are CSS template literals
 *   - Can include a 'default' style applied when no props match
 *
 * @returns Combined CSS from all matching prop styles, or default style if none match
 *
 * @example
 * // Input:
 * allProps = { primary: true, outline: true }
 * types = {
 *   primary: css`color: blue;`,
 *   outline: css`border: 1px solid;`
 * }
 *
 * // Output:
 * css`
 *   color: blue;
 *   border: 1px solid;
 * `
 */
const propsToCSS = (allProps: StyleProps, types: StyleTypes) => {
  let totalCSS = `` as string | CSS
  // QUESTION: can remove children and theme?
  const { children, theme, ...props } = allProps
  const propArray: PropEntry[] = Object.entries(props)
  propArray.map(
    ([prop, value]: PropEntry) =>
      value &&
      types?.[prop as keyof StyleTypes] &&
      (totalCSS = css`
        ${totalCSS}${types?.[prop as keyof StyleTypes]}
      `),
  )

  const hasCSS = totalCSS !== ``
  const defaultStyle = types?.default || css``
  return hasCSS ? totalCSS : defaultStyle
}

export default propsToCSS
