import { useState, useEffect, useRef } from 'react'
import { Image } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { signUpGraphic } from 'assets'
import { getIpLocation as getIpLocationApi } from 'api/funeralHomes'
import { StepOne, StepTwo } from '.'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default () => {
  const defaultFuneralHomePayload = {
    name: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    ip_city: null,
    ip_country_code: null,
    ip_country_name: null,
    ip_address: null,
    ip_latitude: null,
    ip_longitude: null,
    ip_region_code: null,
    ip_region_name: null,
    ip_zip_code: null,
  }

  const defaultUserPayload = {
    email: '',
    name: '',
    funeral_home_id: '',
  }

  const handleError = useErrorHandler('SignUp')
  const [step, setStep] = useState(1)
  const [funeralHomePayload, setFuneralHomePayload] = useState(
    defaultFuneralHomePayload,
  )
  const [funeralHomeNameError, setFuneralHomeNameError] = useState(' ')
  const [userPayload, setUserPayload] = useState(defaultUserPayload)

  const nameInputRef = useRef()
  const funeralHomeNameInput = useRef()

  useEffect(() => {
    const getIpLocation = async () => {
      const { data } = await getIpLocationApi()
      if (data.ip) {
        setFuneralHomePayload((oldFormState) => ({
          ...oldFormState,
          ip_city: data.city,
          ip_country_code: data.country_code,
          ip_country_name: data.country_name,
          ip_address: data.ip,
          ip_latitude: data.latitude,
          ip_longitude: data.longitude,
          ip_region_code: data.region_code,
          ip_region_name: data.region_name,
          ip_zip_code: data.postal,
        }))
      }
    }

    try {
      if (step === 2) {
        getIpLocation()
        setFuneralHomeNameError(' ')
        return funeralHomeNameInput.current?.focus()
      }
      nameInputRef.current?.focus()
    } catch (error) {
      handleError(
        error?.response?.data?.message || error.message,
        error,
        null,
        'getIpLocation',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const handleSetFuneralHomePayload = (key, value) => {
    funeralHomePayload.hasOwnProperty(key) &&
      setFuneralHomePayload((prevState) => ({
        ...prevState,
        [key]: value,
      }))
  }

  const handleSetUserPayload = (key, value) => {
    userPayload.hasOwnProperty(key) &&
      setUserPayload((prevState) => ({
        ...prevState,
        [key]: value,
      }))
  }

  return (
    <Flex center padding="152px 0">
      <Flex
        maxHeight="720px"
        border="1px solid"
        borderColor="gray4"
        radius="2px"
      >
        <Image
          src={signUpGraphic}
          alt="sign up graphic"
          width="315px"
          height="auto"
        />
        <Flex center padding="130px 164px" position="relative">
          {step === 2 && (
            <Text
              onClick={() => setStep(1)}
              clickable
              size="14px"
              position="absolute"
              top="16px"
              left="16px"
              color="brand"
            >
              Back
            </Text>
          )}
          <Flex column width="300px">
            {step === 1 && (
              <StepOne
                userPayload={userPayload}
                handleSetUserPayload={handleSetUserPayload}
                nameInputRef={nameInputRef}
                setStep={setStep}
              />
            )}
            {step === 2 && (
              <StepTwo
                funeralHomeNameError={funeralHomeNameError}
                setFuneralHomeNameError={setFuneralHomeNameError}
                funeralHomePayload={funeralHomePayload}
                handleSetFuneralHomePayload={handleSetFuneralHomePayload}
                userPayload={userPayload}
                funeralHomeNameInput={funeralHomeNameInput}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
