import styled, { css } from 'styled-components'
import { DropIcon } from 'components'
import Icon from 'components/priority/Icon/Icon'
import Text from 'components/priority/Text/Text'
import sizing from 'styles/sizing'
import spacing from 'styles/spacing'
import colors from 'utils/styling/colors'

// Text and Icons

export const CellText = styled(Text)`
  white-space: nowrap;
  transition: 0.25s;
`

export const HeaderArrow = styled(DropIcon)(
  ({ selected }) => css`
    opacity: ${selected ? '1' : '0'};
  `,
)

export const GoIcon = styled(Icon)`
  & path {
    fill: ${colors('gray3')};
  }
`

// Table

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  display: block;
  overflow-y: auto;
  position: relative;

  /* width */
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 0 0 10px 10px ${colors('gray5')};
    border: solid transparent;
    border-width: 48px 0px 0px 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 10px 10px ${colors('gray4')};
    border: solid transparent;
    border-width: 48px 0px 0px 8px;
  }

  ${sizing}
  ${spacing}

  /* first row left padding */
  & td,
  th {
    padding-left: 12px;
  }
  & td + td,
  th + th {
    padding-left: 0;
  }
`

export const TableBody = styled.tbody`
  display: table;
  width: 100%;
`

// Rows

const rowStyling = css`
  height: 48px;
`

export const HeaderRow = styled.tr`
  ${rowStyling}
  cursor: pointer;
`

export const Row = styled.tr`
  ${rowStyling}
  cursor: pointer;
  transition: 0.25s;

  &:hover {
    background: ${colors('palePrimary')};
  }

  &:hover ${CellText} {
    color: ${colors('brand')};
  }

  &:hover ${GoIcon} {
    & path {
      fill: ${colors('brand')};
    }
  }
`

// Cells

const cellStyling = css`
  padding-right: 72px;
  height: 48px;
  border-bottom: 1px solid ${colors('gray5')};
`

export const Header = styled.th`
  ${cellStyling}
  top: 0;
  position: sticky;
  background: ${colors('white')};
  border-bottom: 1px solid ${colors('gray4')};
  text-align: left;

  &:hover ${HeaderArrow} {
    opacity: 1;
  }
`

export const Cell = styled.td`
  ${cellStyling}
`

export const IconCell = styled.td`
  ${cellStyling}
  width: 100%;
  padding-right: 12px;
  text-align: right;
`
