import { IconShowcase, PaletteShowcase } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'

export default () => (
  <>
    <Flex column align="center">
      {/* color palette */}
      <Text as="h2">Color Palette</Text>
      <PaletteShowcase />

      {/* icons */}
      <Flex center full column padding="48px">
        <Text as="h2" margin="0 0 32px 0">
          Icons
        </Text>
        <IconShowcase />
      </Flex>
    </Flex>
  </>
)
