import { Navigation } from 'components'
import Text from 'components/priority/Text/Text'

export default () => {
  return (
    <>
      <Navigation type="admin" />
      <Text margin="32px 0 0 0">Themes</Text>
    </>
  )
}
