import axios from 'api/axios.config'

export const getThemeCategoriesByCollection = async (collection_id) => {
  try {
    const response = await axios.get(
      `themeCategories?collection_id=${collection_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTES: This endpoint is not used
export const getThemesByCategory = async (theme_category_id) => {
  try {
    const response = await axios.get(
      `themes?theme_category_id=${theme_category_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

export const getPinnedThemes = async (collection_id) => {
  try {
    const response = await axios.get(
      `pinnedThemes?collection_id=${collection_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const createPinnedThemes = async (payload) => {
  try {
    const response = await axios.post(`createPinnedTheme`, payload)
    return response

    // expected payload
    // {
    //   collection_id: uuid,
    //   theme_id: uuid
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint is missing in backend
export const deletePinnedThemes = async (pinned_theme_id) => {
  try {
    const response = await axios.delete(`pinnedThemes/${pinned_theme_id}`)
    return response
  } catch (error) {
    throw error
  }
}

export const getThemeLayout = async (theme_id, layout_id) => {
  try {
    const response = await axios.get(
      `themeLayouts?layout_id=${layout_id}&theme_id=${theme_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const getThemeLayoutByProduct = async (product_id) => {
  try {
    const response = await axios.get(`themeLayouts?product_id=${product_id}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const getSelectedThemeLayoutByProduct = async (product_id, theme_id) => {
  try {
    const response = await axios.get(
      `themeLayouts?product_id=${product_id}&theme_id=${theme_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const getThemeLayoutByLayout = async (layout_id) => {
  try {
    const response = await axios.get(`themeLayouts?layout_id=${layout_id}`)
    return response
  } catch (error) {
    throw error
  }
}

export const getThemeById = async (theme_id) => {
  try {
    const response = await axios.get(`themes/${theme_id}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint is missing in backend
export const getWatermark = async (watermark_id) => {
  try {
    const response = await axios.get(`watermarks/${watermark_id}`)
    return response
  } catch (error) {
    throw error
  }
}
