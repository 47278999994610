import { load } from 'cheerio'
import type { CheerioAPI } from 'cheerio'
import type { CheerioElement } from 'types/global'

const preserveItalicsAndBolds = ({
  initialElement,
  targetElement,
  targetTag,
  findLast = true,
}: {
  initialElement: CheerioElement
  targetElement: CheerioAPI
  targetTag: string
  findLast?: boolean
}) => {
  if (!initialElement || !targetElement || !targetTag) return
  const $ = load('')
  const $element = $(initialElement)
  const hasEm = $element.find('em').length > 0
  const hasStrong = $element.find('strong').length > 0

  if (!hasEm && !hasStrong) return

  targetElement(targetTag).each((_, element) => {
    const loadedElement = targetElement(element)
    let spanText
    let lastElement

    if (!findLast) {
      spanText = loadedElement.text()
      lastElement = loadedElement
    } else {
      const lastSpan = loadedElement.find('span').last()
      const lastParagraph = loadedElement.find('p').last()
      lastElement = lastSpan.length > 0 ? lastSpan : lastParagraph
      spanText = lastElement.text()
    }

    let newContent = spanText

    if (hasEm) newContent = `<em>${newContent}</em>`
    if (hasStrong) newContent = `<strong>${newContent}</strong>`

    lastElement.html(newContent)
  })
}

export default preserveItalicsAndBolds
