import { useState, useEffect } from 'react'
import { useAsyncDebounce } from 'react-table'
import { SearchBar } from 'components'

export default ({ globalFilter, setGlobalFilter, searchPlaceholder }) => {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  useEffect(() => {
    !value && setGlobalFilter(null)
    // eslint-disable-next-line
  }, [value])

  return (
    <SearchBar
      placeholder={searchPlaceholder}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      value={[value, setValue]}
    />
  )
}
