import * as S from './Button.styled'
import Icon from 'components/priority/Icon/Icon'

export default ({ children, icon, iconLeft, ...props }) => (
  <S.Button {...props}>
    {iconLeft && <Icon icon={iconLeft} margin="0 8px 0 0" />}
    {children}
    {icon && <Icon icon={icon} margin="0 0 0 8px" />}
  </S.Button>
)
