import styled, { css } from 'styled-components'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import colors from 'utils/styling/colors'

export const EditorSearchBar = styled(Flex)`
  &:hover {
    background: ${colors('gray5')};
  }
`

export const SearchIcon = styled(Icon)(
  ({ selected }) => css`
    transform: rotate(${selected ? '90deg' : '0deg'});
    transition: 0.25s;
  `,
)
