// this hook is meant for using colors outside of styled components

import { themeAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import palettes from 'utils/styling/palettes'
import hexOpacity from 'utils/styling/hexOpacity'

export default () => {
  const theme = useRecoilValue(themeAtom)

  return (color, opacity) => {
    const themeColor =
      palettes?.[color]?.[theme] || palettes?.[color]?.default || color
    const solidColor = themeColor
    const transparentColor = themeColor
      ? `${themeColor}${hexOpacity?.[opacity] || 100}`
      : color

    return opacity ? transparentColor : solidColor
  }
}
