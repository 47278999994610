import { InsertCategoryItem } from 'components'
import { useRecoilValue } from 'recoil'
import { roleAtom } from 'atoms'

export default ({
  setMenu,
  showSlideout,
  selectedMenu,
  setSelectedMenu,
  setShowSlideout,
  menuConfig,
}) => {
  const userRole = useRecoilValue(roleAtom)

  const menus = menuConfig.filter((menu) => {
    return menu.allowedFor.includes(userRole)
  })

  return menus?.map((menu) => (
    <InsertCategoryItem
      selected={showSlideout && selectedMenu === menu.name}
      onClick={() => setMenu(menu.name)}
      key={menu.name}
      menu={menu.name}
      setSelectedMenu={setSelectedMenu}
      setShowSlideout={setShowSlideout}
    >
      {menu.displayLabel}
    </InsertCategoryItem>
  ))
}
