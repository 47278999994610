import type { Case, Order, Service } from 'types/case'
import dashboardDates from 'mocks/dates/dashboardDates'
import caseDates from 'mocks/dates/caseDates'
import convertObjectOfDates from 'utils/dates/convertObjectOfDates'
import isoStringToDate from 'utils/dates/isoStringToDate'

type OrderWithCase = Order & {
  case?: Case
}

const sanitizeDashboardDates = (originalCells: Case[] | OrderWithCase[]) => {
  const cells = [...originalCells]

  cells.forEach((cell: Case | OrderWithCase) => {
    convertObjectOfDates(dashboardDates.table, cell, isoStringToDate)

    if ('case' in cell) {
      cell.case?.services?.forEach((service: Service) =>
        convertObjectOfDates(caseDates.service, service, isoStringToDate),
      )
    } else {
      ;(cell as Case).services?.forEach((service: Service) =>
        convertObjectOfDates(caseDates.service, service, isoStringToDate),
      )
    }
  })

  return cells
}

export default sanitizeDashboardDates
