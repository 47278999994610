import { Tabs } from 'components'
import { Contact, Instructions, Webinars, Videos } from './'

export default ({
  activeTab: [activeTab, setActiveTab] = [null, () => {}],
  ...props
}) => (
  <Tabs
    tabs={[
      {
        title: 'Contact',
        content: <Contact />,
        url: '/support/contact',
      },
      {
        title: 'Videos',
        content: <Videos />,
        url: '/support/videos',
      },
      {
        title: 'Instructions',
        content: <Instructions />,
        url: '/support/instructions',
      },
      {
        title: 'Webinars',
        content: <Webinars />,
        url: '/support/webinars',
      },
    ]}
    selected={[activeTab, setActiveTab]}
    {...props}
  />
)
