import * as S from './ProofWatermark.styled'

export default () => {
  const count = 20
  const watermarks = Array.from({ length: count }, (_, i) => (
    <S.ProofText key={i} index={i}>
      PROOF
    </S.ProofText>
  ))
  return <S.Wrapper>{watermarks}</S.Wrapper>
}
