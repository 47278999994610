import { Flex } from 'components/priority/Flex/Flex.styled'
import styled, { css } from 'styled-components'
import colors from 'utils/styling/colors'

export const Indicator = styled(Flex)(
  ({ active }) => css`
    background: ${colors(active ? 'brand' : 'gray4')};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  `,
)
