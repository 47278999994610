import type { PropsWithChildren } from 'react'
import { useMemo } from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { themeAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import getThemeColors from 'utils/styling/getThemeColors'
import browserStats from 'utils/global/browserStats'
import type { ColorValue } from 'types/style'

const Theme = ({ children }: PropsWithChildren<{}>) => {
  const theme = useRecoilValue(themeAtom) as keyof ColorValue
  const stats = useMemo(() => browserStats(), [])
  const masterTheme: DefaultTheme = {
    ...getThemeColors(theme),
    ...stats,
    palette: theme,
  }
  return <ThemeProvider theme={masterTheme}>{children}</ThemeProvider>
}
export default Theme
