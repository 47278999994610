import styled, { css } from 'styled-components'
import flex from 'styles/flex'
import sizing from 'styles/sizing'
import spacing from 'styles/spacing'
import positioning from 'styles/positioning'
import parseBackgroundPosition from 'utils/global/parseBackgroundPosition'
import zLayers from 'utils/styling/zLayers'

export const Page = styled.div(
  ({
    zoom = 100,
    pageExceedsCanvas,
    padding,
    whiteBorder,
    theme,
    pageLayout,
    themeLayout,
    preprinted,
    backgroundTabActive,
    isPMRegisterBook,
    isEven,
    pageTwo,
    backgroundImageUrl,
  }) => css`
    ${flex}
    ${sizing}
    ${spacing}
    ${positioning}
    left: 0%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    transform: scale(${zoom / 100});
    transform-origin: ${pageExceedsCanvas ? 'left top' : 'top center'};
    transition: 0.15s;
    background-image: ${pageLayout?.hide_background ||
    backgroundTabActive ||
    preprinted
      ? ''
      : `url(${backgroundImageUrl})`};
    background-size: ${pageLayout?.background_fill
      ? pageLayout.background_fill
      : 'cover'};
    background-position: ${parseBackgroundPosition(
        pageLayout?.background_left,
      )}px
      ${parseBackgroundPosition(pageLayout?.background_top)}px;
    background-repeat: no-repeat;
    border: ${whiteBorder && `${whiteBorder}px solid white`};
    padding-top: ${padding?.top && `${padding.top}px`};
    padding-right: ${padding?.right && `${padding.right}px`};
    padding-bottom: ${padding?.bottom && `${padding.bottom}px`};
    padding-left: ${padding?.left && `${padding.left}px`};
    position: relative;
    background-color: white;
    &:after {
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      z-index: ${zLayers.background};
      background: white;
      opacity: ${pageLayout?.background_opacity || 0};
    }
  `,
)

export const RBDiv = styled.div(
  ({ product, isEven }) => css`
    position: absolute;
    z-index: ${zLayers.background};
    height: ${product?.productSize?.height}px;
    width: ${!isEven ? product?.padding.left : product?.padding?.right}px;
    background-color: #fff;
    ${isEven && `right: 0px;`}
    ${!isEven && `left: 0px;`}
    top: 0px;
  `,
)
