import type { PropsWithChildren } from 'react'
import { useApplyTheme } from 'hooks'
import GlobalStyles from './GlobalStyles'
import { ToastContainer } from 'components'

const Initiation = ({ children }: PropsWithChildren<{}>) => {
  useApplyTheme()

  return (
    <>
      <GlobalStyles />
      <ToastContainer />
      {children}
    </>
  )
}

export default Initiation
