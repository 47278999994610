import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { InsertImageGrid, LoadingInsertImages } from 'components'
import { useRecoilValue } from 'recoil'
import { editorSearchBarLoading, editorSearchBarResults } from 'atoms'

export default ({ setShow }) => {
  const loading = useRecoilValue(editorSearchBarLoading)
  const items = useRecoilValue(editorSearchBarResults)
  return loading ? (
    <LoadingInsertImages />
  ) : (
    <Flex full center>
      {items === 'No Matching Results' ? (
        <Text>{items}</Text>
      ) : (
        <InsertImageGrid
          items={items}
          srcPrefix={process.env.REACT_APP_S3BUCKET_UPLOADS}
          imageKey="image"
          nameKey="name"
          setShow={setShow}
        />
      )}
    </Flex>
  )
}
