import { useRef, useEffect } from 'react'
import { debugLog } from 'utils/debug/log'

type DebugOptions = {
  componentName: string
  componentFunction: string
  [key: string]: any
}

type Options = {
  signal?: AbortSignal
  debug?: DebugOptions
  [key: string]: any
}

const useAbortController = (componentName: string = 'UnknownComponent') => {
  const isMounted = useRef(true)
  const controller = useRef<AbortController>(new AbortController())

  useEffect(() => {
    debugLog(`${componentName} mounted`)

    if (!controller?.current || controller?.current?.signal?.aborted) {
      controller.current = new AbortController()
    }

    isMounted.current = true

    return () => {
      debugLog(`${componentName} unmounted`)
      isMounted.current = false
      controller?.current?.abort()
    }
  }, [componentName])

  const checkMounted = (functionName: string = 'UnknownFunction') => {
    if (!isMounted.current) {
      const genericMessage = `${componentName} is unmounted during ${functionName}() call`
      controller?.current?.abort(genericMessage)
      debugLog(`${componentName} is unmounted during ${functionName}() call`)
      return false
    }
    return true
  }

  const handleSignal = (
    componentFunction: string | undefined,
    options: Options = {},
  ) => ({
    ...options,
    signal: controller?.current?.signal,
    debug: {
      ...options?.debug,
      componentName,
      componentFunction,
    },
  })

  return {
    checkMounted,
    handleSignal,
  }
}

export default useAbortController
