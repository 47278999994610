import { useState, useRef, useEffect } from 'react'
import {
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
  constSelector,
  useRecoilState,
} from 'recoil'
import { useFillCaseImages, useNudgeItem } from 'hooks'
import { useEditorOutsideClick } from 'hooks/editor/useEditorOutsideClick'
import {
  selectionRangeAtom,
  pageZoomAtom,
  itemSelector,
  selectedItemAtom,
  caseAtom,
  alignmentPointsAtom,
  alignmentVisibilityAtom,
  selectedPageAtom,
  objectKeySelector,
  editorPagesAtom,
  controlledXAtom,
  controlledYAtom,
  rightMenuAtom,
  snapToggleAtom,
  selectedItemsAtom,
  draggingSelectionAtom,
  itemsLockedAtom,
} from 'atoms'
import Draggable from 'react-draggable'
import { ResizeControls } from 'components'
import { withinBoundingBox, calculateAlignmentSnap } from 'utils'
import * as Items from './'
import * as S from './Item.styled'
import { roleAtom } from 'atoms'
import transformToPascalCase from 'utils/global/transformToPascalCase'

export default ({
  type,
  id,
  determineSelectedItems,
  hasMultiSelected,
  ...props
}) => {
  const thisItem = useRecoilValue(itemSelector({ atomFamily: type, id: id }))
  const userRole = useRecoilValue(roleAtom)
  const _case = useRecoilValue(caseAtom)
  const activeMenu = useRecoilValue(rightMenuAtom)
  const snapToggle = useRecoilValue(snapToggleAtom)
  const itemsLocked = useRecoilValue(itemsLockedAtom)

  const [selected, setSelected] = useState(false)

  const setThisItemX = useSetRecoilState(itemSelector({ id: id, path: 'x' }))
  const setThisItemY = useSetRecoilState(itemSelector({ id: id, path: 'y' }))
  const setThisItemWidth = useSetRecoilState(
    itemSelector({ atomFamily: type, id: id, path: 'width' }),
  )
  const setThisItemHeight = useSetRecoilState(
    itemSelector({ atomFamily: type, id: id, path: 'height' }),
  )

  const { x, y, width, height, z, assetLocked, maintainAspectRatio } = thisItem

  const zoom = useRecoilValue(pageZoomAtom)
  const selectedPage = useRecoilValue(selectedPageAtom)
  const selectedRange = useRecoilValue(selectionRangeAtom)
  const [selectedItem, setSelectedItem] = useRecoilState(selectedItemAtom)
  const resetSelectedItem = useResetRecoilState(selectedItemAtom)
  const alignmentPoints = useRecoilValue(alignmentPointsAtom)
  const setAlignmentVisibility = useSetRecoilState(alignmentVisibilityAtom)
  const [selectedItems, setSelectedItems] = useRecoilState(selectedItemsAtom)
  const draggingSelection = useRecoilValue(draggingSelectionAtom)

  const padding = useRecoilValue(
    objectKeySelector({
      atom: editorPagesAtom,
      property: `[${selectedPage - 1}].product.padding`,
    }),
  )
  const [dragDisabled, setDragDisabled] = useState(false)

  const convertedWidth = typeof width === 'string' ? parseInt(width) : width
  const convertedHeight = typeof height === 'string' ? parseInt(height) : height

  const [internalWidth, setInternalWidth] = useState(convertedWidth)
  const [internalHeight, setInternalHeight] = useState(convertedHeight)

  const isItemSelectable =
    withinBoundingBox(
      x,
      y,
      internalWidth,
      internalHeight,
      selectedRange,
      padding,
    ) && !dragDisabled

  const itemSelected =
    (isItemSelectable && selectedItems.includes(thisItem.id)) || selected

  const isItemSelected = isItemSelectable || selected

  const globalControlledX = useRecoilValue(
    isItemSelected ? controlledXAtom : constSelector(null),
  )
  const globalControlledY = useRecoilValue(
    isItemSelected ? controlledYAtom : constSelector(null),
  )
  const setGlobalControlledX = useSetRecoilState(controlledXAtom)
  const setGlobalControlledY = useSetRecoilState(controlledYAtom)

  const [controlledX, setControlledX] = useState(x)
  const [controlledY, setControlledY] = useState(y)

  const item = useRef(null)
  const nodeRef = useRef(null)

  const fillCaseImages = useFillCaseImages()

  const [isEditing, setIsEditing] = useState(false)
  const [itemExists, setItemExists] = useState(false)

  useEffect(() => {
    ;(convertedHeight > 0 || convertedWidth > 0) && setItemExists(true)
    !convertedHeight && !convertedWidth && setItemExists(false)
  }, [convertedHeight, convertedWidth])

  useEffect(() => {
    setInternalWidth(convertedWidth)
    setInternalHeight(convertedHeight)
  }, [convertedHeight, convertedWidth])

  useEffect(() => {
    if (!isItemSelected) setSelectedItems([])
    //eslint-disable-next-line
  }, [isItemSelected])

  useEffect(() => {
    if (isItemSelectable && draggingSelection) {
      if (selectedItems.includes(thisItem.id)) return
      isItemSelected && setSelectedItems([...selectedItems, thisItem.id])
    } else if (selected && !isItemSelectable && !draggingSelection) {
      selected && setSelectedItem(thisItem.id)
    }
    //eslint-disable-next-line
  }, [selected, itemSelected, isItemSelectable, isItemSelected])

  useEffect(() => {
    if (determineSelectedItems) {
      selected || isItemSelected
        ? determineSelectedItems(true)
        : determineSelectedItems(false)
    }
    //eslint-disable-next-line
  }, [selected, isItemSelected, determineSelectedItems])

  useEffect(() => {
    !_case?.filledImages && fillCaseImages()
    //eslint-disable-next-line
  }, [_case?.personal_images])

  useEffect(() => {
    //we use the active menu to determine which items are selectable
    //if looking at Elements then dragDisabled is true for watermarks
    //if looking at Watermarks then dragDisabled is true for NOT watermarks
    //if looking at Background nothing is interactive
    if (activeMenu === 'Elements') {
      thisItem.type !== 'watermark'
        ? setDragDisabled(false)
        : setDragDisabled(true)
    } else if (activeMenu === 'Watermarks') {
      thisItem.type === 'watermark'
        ? setDragDisabled(false)
        : setDragDisabled(true)
    } else if (activeMenu === 'Background') {
      setDragDisabled(true)
    }
  }, [thisItem, activeMenu])

  useEffect(() => {
    //when ever the active menu changes unselect the elements
    setSelected(false)
    resetSelectedItem()
    //eslint-disable-next-line
  }, [activeMenu])

  useEffect(() => {
    selectedItem === thisItem.id ? setSelected(true) : setSelected(false)
    //eslint-disable-next-line
  }, [selectedItem])
  //this outside click function handles the unique needs of the editor where we want to reset selected if no element is selected
  useEditorOutsideClick(
    item,
    nodeRef,
    isItemSelected,
    () => {
      setSelected(false)
    },
    resetSelectedItem,
  )

  const Item =
    Items[`Page${transformToPascalCase(thisItem.type)}`] || Items.PageShape

  const handleStop = () => {
    if (nodeRef.current) nodeRef.current.isDragging = false

    setThisItemX(controlledX)
    setThisItemY(controlledY)
    setAlignmentVisibility({
      verticalArea: 0,
      verticalSnap: 0,
      horizontalSnap: 0,
      horizontalArea: 0,
    })
  }

  const dragTogether = () => {
    setControlledX((controlledX) => controlledX + globalControlledX)
    setControlledY((controlledY) => controlledY + globalControlledY)
  }

  useEffect(() => {
    setGlobalControlledX(null)
    setGlobalControlledY(null)
    //eslint-disable-next-line
  }, [selected])

  useEffect(() => {
    itemSelected && dragTogether()
    //eslint-disable-next-line
  }, [globalControlledX, globalControlledY])

  const handleDrag = (_, dragElement) => {
    if (nodeRef.current) nodeRef.current.isDragging = true

    //removed snapHorizontal from destructure see below note
    const { snapVertical, snapInfo } = calculateAlignmentSnap(
      thisItem,
      dragElement,
      alignmentPoints,
      padding,
    )
    if (!hasMultiSelected && snapVertical && snapToggle) {
      setControlledX(snapVertical)
    } else {
      setGlobalControlledX(dragElement.deltaX)
    }
    //NOTE: turn off horizontal snap per Bri on Trello (https://trello.com/c/uOYYsjcN/1686-snap-to-center-guides) 01/14/2022
    // if (!hasMultiSelected && snapHorizontal && snapToggle) {
    //   setControlledY(snapHorizontal)
    // } else {}
    setGlobalControlledY(dragElement.deltaY)

    setAlignmentVisibility(snapInfo)
  }

  const handleMouseDown = () => {
    if (nodeRef.current) nodeRef.current.isDragging = false

    if (!dragDisabled) {
      setSelected(true)
      setSelectedItem(thisItem.id)
    }
  }

  const isAdmin = userRole === 'messenger-user'
  const isAssetLocked = !!assetLocked && !isAdmin

  useEffect(() => {
    if (userRole === 'loading') return
    setDragDisabled(!!assetLocked && !isAdmin)
  }, [assetLocked, userRole, isAdmin])

  useNudgeItem({
    isItemSelected,
    dragDisabled,
    isEditing,
    controlledX,
    controlledY,
    setControlledX,
    setControlledY,
    setThisItemX,
    setThisItemY,
  })

  return !itemExists ? null : (
    <Draggable
      nodeRef={nodeRef}
      axis="none"
      cancel=".no-drag"
      scale={zoom * 0.01}
      onDrag={handleDrag}
      onStop={handleStop}
      disabled={dragDisabled || isEditing || itemsLocked}
      onMouseDown={handleMouseDown}
      position={{ x: controlledX, y: controlledY }}
    >
      <S.ItemContainer
        ref={nodeRef}
        z={dragDisabled && !assetLocked ? -1 : z}
        isWatermark={thisItem.type === 'watermark'}
        disabled={dragDisabled || isAssetLocked}
      >
        <ResizeControls
          z={z}
          rotate={thisItem?.edits?.rotate}
          selected={itemSelected}
          setWidth={setThisItemWidth}
          setHeight={setThisItemHeight}
          internalWidth={[internalWidth, setInternalWidth]}
          internalHeight={[internalHeight, setInternalHeight]}
          disabled={dragDisabled}
          isEditing={isEditing}
          {...thisItem}
        >
          <Item
            full
            itemRef={item}
            selected={itemSelected}
            images={_case?.filledImages}
            internalWidth={[internalWidth, setInternalWidth]}
            internalHeight={[internalHeight, setInternalHeight]}
            dragDisabled={[isEditing, setIsEditing]}
            type={type}
            maintainAspectRatio={maintainAspectRatio}
            assetLocked={isAssetLocked}
            itemLocked={itemsLocked}
            {...thisItem}
            {...props}
          />
        </ResizeControls>
      </S.ItemContainer>
    </Draggable>
  )
}
