import styled, { css } from 'styled-components'
import disabled from 'styles/disabled'
import Flex from 'components/priority/Flex/Flex'
import type {
  TooltipWrapperProps,
  TooltipProps,
  TooltipStyledComponent,
  TooltipWrapperStyledComponent,
} from 'types/tooltip'

export const TooltipWrapper = styled(Flex)<TooltipWrapperProps>`
  ${disabled}
` as TooltipWrapperStyledComponent

export const Tooltip = styled(Flex)<TooltipProps>(
  ({ extraHeight }) => css`
    opacity: 0;
    bottom: 70%;
    transition: 0.15s;
    pointer-events: none;

    ${TooltipWrapper}:hover & {
      bottom: ${`${100 + extraHeight}%`};
      opacity: 1;
    }
  `,
) as TooltipStyledComponent
