type Operation = 'add' | 'subtract'

const operations = {
  add: (number: number, percent: number): number =>
    number + number * (percent * 0.01),
  subtract: (number: number, percent: number): number =>
    number - number * (percent * 0.01),
}

const calculatePercent = (
  type: Operation,
  number: number,
  percent: number,
): number => {
  if (!operations[type]) {
    console.error('Invalid operation type:', { type })
  }

  return operations[type](number, percent)
}

export default calculatePercent
