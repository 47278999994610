import { useState, useEffect, useMemo } from 'react'
import {
  LoadingInsertImages,
  SearchBar,
  AssetDrawers,
  Button,
} from 'components'
import Flex from 'components/priority/Flex/Flex'
import { getCustomVerses } from 'api/customVerses'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({ setShow, setCreatingVerse }) => {
  const handleError = useErrorHandler('InsertCustomVerseMenu')
  const [loading, setLoading] = useState(true)
  const [verses, setVerses] = useState([])
  const [search, setSearch] = useState('')
  const [reloadVerses, setReloadVerses] = useState(Date.now())

  const getVerses = async () => {
    try {
      const { data } = await getCustomVerses()
      setVerses(data.customVerses)
      setLoading(false)
    } catch (error) {
      handleError(
        error?.response?.data?.message || error.message,
        error,
        null,
        'getVerses',
      )
    }
  }

  useEffect(() => {
    getVerses()
    // eslint-disable-next-line
  }, [reloadVerses])

  const filteredVerses = useMemo(() => {
    if (search.trim() === '') {
      return verses
    } else {
      const searchRegex = new RegExp(`${search}`, 'i')
      return verses.filter((verse) => searchRegex.test(verse.reference))
    }
  }, [verses, search])

  return loading ? (
    <LoadingInsertImages type="verses" />
  ) : (
    <>
      <Flex margin="0 0 24px 0">
        <SearchBar
          placeholder="Search"
          value={[search, setSearch]}
          margin="0 12px 0 24px"
          width="400px"
        />
        <Button
          icon="plus"
          primary
          margin="0 24px 0 0"
          onClick={() => setCreatingVerse(true)}
        >
          Add a verse
        </Button>
      </Flex>
      <Flex style={{ overflow: 'auto', minHeight: 'calc(100% - 66px)' }} column>
        <Flex full center>
          <AssetDrawers
            drawers={filteredVerses}
            reload={() => setReloadVerses(Date.now())}
            categoryKey="html"
            categoryNameKey="reference"
            nameKey="html"
            setShow={setShow}
            type="customVerse"
          />
        </Flex>
      </Flex>
    </>
  )
}
