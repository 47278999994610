import { useState, useEffect } from 'react'
import Flex from 'components/priority/Flex/Flex'
import Draggable from 'react-draggable'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { pageZoomAtom, pageThemeLayoutSelector } from 'atoms'
import * as S from './BackgroundDrag.styled'
import parseBackgroundPosition from 'utils/global/parseBackgroundPosition'
import zLayers from 'utils/styling/zLayers'

export default ({
  backgroundImageUrl,
  pageLayout,
  preprinted,
  backgroundTabActive,
  pageTwo,
}) => {
  // how far the page is zoomed out
  const zoom = useRecoilValue(pageZoomAtom)

  // the positioning of the current background
  const [backgroundX, setBackgroundX] = useState(0)
  const [backgroundY, setBackgroundY] = useState(0)

  // the initial position of the background
  const pageX = parseBackgroundPosition(pageLayout?.background_left || 0)
  const pageY = parseBackgroundPosition(pageLayout?.background_top || 0)

  // variables to set the final position
  const setJSONBackgroundY = useSetRecoilState(
    pageThemeLayoutSelector({ path: 'background_top' }),
  )
  const setJSONBackgroundX = useSetRecoilState(
    pageThemeLayoutSelector({ path: 'background_left' }),
  )

  useEffect(() => {
    // set the initial position of the background
    // and update it when the pageLayout changes
    setBackgroundX(pageX)
    setBackgroundY(pageY)
    setJSONBackgroundX(pageX)
    setJSONBackgroundY(pageY)

    // eslint-disable-next-line
  }, [pageLayout])

  const handleDrag = (e, { x: dragX, y: dragY }) => {
    // set the background's position
    setBackgroundX(dragX + pageX)
    setBackgroundY(dragY + pageY)
  }

  const handleStop = (e, { x: dropX, y: dropY }) => {
    // save the drag to the JSON
    setJSONBackgroundX(Math.floor(pageX + dropX))
    setJSONBackgroundY(Math.floor(pageY + dropY))
  }

  return (
    backgroundTabActive && (
      <>
        {/* Draggable zone to manipulate the background image */}
        <Draggable
          position={{ x: 0, y: 0 }}
          scale={zoom * 0.01}
          onDrag={handleDrag}
          onStop={handleStop}
        >
          <Flex
            zindex={zLayers.modal}
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            width="100%"
            height="100%"
          />
        </Draggable>

        {/* The background image */}
        <Flex
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          width="100%"
          height="100%"
          overflow="hidden"
        >
          <div
            style={S.DraggableBackground({
              backgroundX,
              backgroundY,
              pageLayout: pageLayout,
              backgroundImageUrl: backgroundImageUrl,
              preprinted: preprinted,
              pageTwo,
            })}
          />
        </Flex>
      </>
    )
  )
}
