import { useState, useRef } from 'react'
import { Button, Input, TinyMCE } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Tooltip from 'components/global/Tooltip/Tooltip'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'
import { createCustomVerse } from 'api/customVerses'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { useRecoilValue } from 'recoil'
import { roleAtom } from 'atoms'

const CustomVerseEditor = ({
  value,
  reference: [reference, setReference],
  TinyMCERef,
  cancel,
  save,
  global: [isGlobalVerse, setIsGlobalVerse],
}) => {
  const role = useRecoilValue(roleAtom)

  const isMessengerUser = role === 'messenger-user'
  return (
    <Flex column full>
      <Flex
        border="1px solid"
        borderColor="gray4"
        radius="2px"
        minHeight="200px"
        margin="0 0 12px 0"
        full
      >
        <TinyMCE
          placeholder="Type text here"
          value={value}
          setRef={(ref) => (TinyMCERef.current = ref)}
          isVerse={true}
        />
      </Flex>
      <Input
        margin="0 0 12px 0"
        label="Reference"
        value={[reference, setReference]}
      />
      {isMessengerUser && (
        <Flex justify="flex-start" margin="16px 0">
          <Tooltip text="Make this verse global" margin="0 12px 0 0">
            <ToggleSwitch
              label="Is global verse"
              labelRight
              value={[isGlobalVerse, setIsGlobalVerse]}
              disabled={!isMessengerUser}
            />
          </Tooltip>
        </Flex>
      )}
      <Flex>
        <Button onClick={() => cancel?.()} margin="0 12px 0 0">
          Cancel
        </Button>
        <Button primary onClick={() => save?.()}>
          Save
        </Button>
      </Flex>
    </Flex>
  )
}

export default ({ setCreatingVerse }) => {
  const handleError = useErrorHandler('CustomVerseEditorMenu')
  const TinyMCERef = useRef(null)
  const [editReference, setEditReference] = useState('')
  const [isGlobalVerse, setIsGlobalVerse] = useState(false)

  const createVerse = async () => {
    try {
      const content = TinyMCERef?.current?.getContent?.()
      const newVerse = {
        html: content,
        html_for_render: content,
        reference: editReference,
        isGlobal: isGlobalVerse,
      }
      await createCustomVerse(newVerse)
      setCreatingVerse(false)
    } catch (error) {
      handleError(
        error?.response?.data?.message || error.message,
        error,
        null,
        'createVerse',
      )
    }
  }

  return (
    <Flex full padding="24px">
      <CustomVerseEditor
        TinyMCERef={TinyMCERef}
        value=""
        reference={[editReference, setEditReference]}
        cancel={() => setCreatingVerse(false)}
        save={() => createVerse()}
        global={[isGlobalVerse, setIsGlobalVerse]}
      />
    </Flex>
  )
}
