import { orders } from 'api'
import { ordersMockTable } from 'mocks'
import { Navigation, OrdersTable, DashboardTable, CasesModal } from 'components'

export default () => (
  <>
    <Navigation />
    <DashboardTable
      title="Print Packages"
      type="Order"
      api={orders}
      mockTable={ordersMockTable}
      CreateComponent={CasesModal}
      TableComponent={OrdersTable}
    />
  </>
)
