const determineLink = (link: string | null | undefined): string => {
  const isExternal = (link: string) =>
    link?.includes('http://') || link?.includes('https://')
  const isProtocol = (link: string) =>
    link?.includes('mailto:') || link?.includes('tel:')

  if (!link) {
    return 'none'
  } else if (isExternal(link)) {
    return 'external'
  } else if (isProtocol(link)) {
    return 'protocol'
  } else {
    return 'internal'
  }
}

export default determineLink
