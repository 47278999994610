import * as S from './LoadingPlaceholder.styled'

export default function LoadingPlaceholder({ ...props }) {
  return (
    <S.LoadingPlaceholder
      full
      background="gray5"
      height="100%"
      radius="2px"
      {...props}
    />
  )
}
