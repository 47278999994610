import styled, { css } from 'styled-components'
import Flex from 'components/priority/Flex/Flex'
import colors from 'utils/styling/colors'

const verticalStyling = (fill) => css`
  width: 1px;
  height: 100%;
  border-left: 1px solid ${colors(fill || 'gray1')};
`

const horizontalStyling = (fill) => css`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${colors(fill || 'gray1')};
`

export const Divider = styled(Flex)(({ fill, vertical }) =>
  vertical ? verticalStyling(fill) : horizontalStyling(fill),
)
