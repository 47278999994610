import { Input, DateInput, Check } from 'components'
import { useRecoilState } from 'recoil'
import { editCaseFieldSelector } from 'atoms'

export default ({ name, label, width, index, object_key }) => {
  const path = `value[${index}].${object_key.field || object_key}`
  const [value, setValue] = useRecoilState(
    editCaseFieldSelector({
      name: name,
      path: path,
    }),
  )

  label = label
    .split('_')
    .map((w) => w[0].toUpperCase() + w.substr(1))
    .join(' ')

  if (object_key.type === 'date') {
    return (
      <DateInput
        label={label}
        width={width}
        margin="0 0 24px 0"
        date={[value, setValue]}
        right
      />
    )
  } else if (object_key.type === 'check') {
    return (
      <Check
        margin="0 0 10px 0"
        width={width}
        checked={value || false}
        onClick={() => setValue(!value)}
        align="center"
        text={label}
      />
    )
  } else {
    return (
      <Input
        width={width}
        margin="0 0 24px 0"
        label={label}
        value={[value, setValue]}
      />
    )
  }
}
