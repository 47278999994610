import Flex from 'components/priority/Flex/Flex'
import { SettingsInputSelector } from '.'
import settingsForm from 'utils/forms/settingsForm'

export default ({ printOffsetTB, printOffsetLR, formSection }) => {
  return (
    <Flex wrap="wrap" width="650px">
      {settingsForm[formSection].map((formField, index) => {
        if (
          formSection === 'printSettings' &&
          (formField.label === printOffsetTB ||
            formField.label === printOffsetLR)
        ) {
          return (
            <SettingsInputSelector
              key={`${formField}_${index}`}
              formField={formField}
            />
          )
        } else if (formSection !== 'printSettings') {
          return (
            <SettingsInputSelector
              key={`${formField}_${index}`}
              formField={formField}
            />
          )
        }
        return null
      })}
    </Flex>
  )
}
