import { Input } from 'components'
import { useRecoilState } from 'recoil'
import { editCaseFieldSelector } from 'atoms'

export default ({ name, label, width, index, elementRight, margin }) => {
  const [value, setValue] = useRecoilState(
    editCaseFieldSelector({ name: name, path: `value[${index}]` }),
  )
  return (
    <Input
      width={width}
      margin={margin || '0 24px 24px 0'}
      padding={'12px 35px 12px 16px'}
      label={label}
      value={[value, setValue]}
      elementRight={elementRight}
    />
  )
}
