import { useState, useEffect } from 'react'
import * as S from './PageWatermark.styled'
import { getImageDimensions } from 'utils'

export default ({
  itemRef,
  setItemWidth,
  setItemHeight,
  image,
  id,
  width,
  height,
  opacity,
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState(null)

  const setImage = () => {
    const url = `${process.env.REACT_APP_S3BUCKET_THEME_IMAGES}${image}`

    setImgSrc(url)
  }

  const getDimensions = async (width, src) => {
    try {
      const d = await getImageDimensions(src)
      setItemWidth?.(width)
      setItemHeight?.(width * (d.height / d.width))
    } catch (err) {
      console.log(src, err)
    }
  }

  useEffect(() => {
    image && setImage()
    //eslint-disable-next-line
  }, [image])

  useEffect(() => {
    imgSrc && getDimensions(width, imgSrc)
    //eslint-disable-next-line
  }, [imgSrc])

  return (
    <S.Image
      ref={itemRef}
      imgSrc={imgSrc}
      width={width}
      height={height}
      opacity={opacity}
      {...props}
    />
  )
}
