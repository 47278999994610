import { Button, Input } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'

export default ({ setShowExample }) => (
  <Flex column full padding="32px">
    <Text size="24px" weight="600" margin="0 0 20px 0">
      New staff member
    </Text>
    <Input
      full
      label="Staff member name"
      placeholder="Full Name"
      margin="0 0 24px 0"
    />
    <Input full label="Email" />
    <Flex justify="flex-end" margin="24px 0 0 0">
      <Button margin="0 16px 0 0" onClick={setShowExample}>
        Cancel
      </Button>
      <Button primary onClick={setShowExample}>
        Send invitation
      </Button>
    </Flex>
  </Flex>
)
