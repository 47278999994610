import { useState, useEffect } from 'react'
import { Button, AnimatedHeight } from 'components'
import Flex from 'components/priority/Flex/Flex'
import MemorialCard from './MemorialCard'
import MemorialForm from './MemorialForm'
import { useFamilyUpload } from 'hooks'

export default () => {
  const [formOpen, setFormOpen] = useState(false)
  const {
    token,
    memorial,
    memorialError,
    setMemorial,
    memorials,
    getMemorials,
    createCaseMemorialAsGuest,
    submitter,
    submitterError,
    setSubmitter,
  } = useFamilyUpload(window.location.pathname)

  useEffect(() => {
    token && getMemorials()
    //eslint-disable-next-line
  }, [token, formOpen])

  return (
    <Flex column margin="56px 0 0 0" center width="100%">
      <AnimatedHeight
        height={!formOpen ? 'auto' : 0}
        width="fit-content"
        duration={250}
      >
        <Button onClick={() => setFormOpen(true)} primary icon="plus">
          Share a memorial
        </Button>
      </AnimatedHeight>
      <AnimatedHeight height={formOpen ? 'auto' : 0} width="54%" duration={250}>
        <MemorialForm
          memorial={memorial}
          memorialError={memorialError}
          setMemorial={setMemorial}
          submitter={submitter}
          setSubmitter={setSubmitter}
          submitterError={submitterError}
          setFormOpen={setFormOpen}
          createCaseMemorial={createCaseMemorialAsGuest}
        />
      </AnimatedHeight>
      <Flex center column margin="28px 0 80px 0" width="100%">
        {memorials?.map(({ memorial, submitter_name, archived }, i) =>
          archived ? null : (
            <MemorialCard key={i} memorial={memorial} from={submitter_name} />
          ),
        )}
      </Flex>
    </Flex>
  )
}
