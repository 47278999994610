import type { Case, Station } from 'types/case'
import caseDates from 'mocks/dates/caseDates'
import convertObjectOfDates from 'utils/dates/convertObjectOfDates'
import isoStringToDate from 'utils/dates/isoStringToDate'

const sanitizeCaseStations = (stations: Case['us_stations_where_served']) => {
  if (!stations) return []
  if (stations?.length > 0) {
    return stations.map((station) => {
      const parsedStation = JSON.parse(station) as Station
      convertObjectOfDates(caseDates.station, parsedStation, isoStringToDate)
      return parsedStation
    })
  }
  return []
}

export default sanitizeCaseStations
