import { Navigation, CustomThemesDashboard } from 'components'

export default () => {
  return (
    <>
      <Navigation />
      <CustomThemesDashboard />
    </>
  )
}
