const isLocalHost = (): boolean => {
  try {
    const hostname = window?.location?.hostname
    if (!hostname) {
      throw new Error('Could not determine hostname')
    }

    return hostname === 'localhost'
  } catch (error) {
    console.error('Error determining localhost status:', error)
    return false
  }
}

export default isLocalHost()
