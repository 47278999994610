type TableRow = {
  original: {
    sortable_date_of_death?: string
    sortable_created_at?: string
    sortable_service_date?: {
      date?: string
    }
  }
}

type SortableField =
  | 'sortable_date_of_death'
  | 'sortable_created_at'
  | 'sortable_service_date.date'

const sortTableDates = (
  rowA: TableRow,
  rowB: TableRow,
  type: SortableField,
): number => {
  try {
    const getValue = (row: TableRow, type: SortableField): string => {
      try {
        if (type === 'sortable_service_date.date') {
          return row.original.sortable_service_date?.date || ''
        }
        return (
          (row.original[type as keyof TableRow['original']] as string) || ''
        )
      } catch (error) {
        console.error('Error getting nested table date:', { row, type, error })
        return ''
      }
    }

    const valueA = getValue(rowA, type)
    const valueB = getValue(rowB, type)

    if (valueA > valueB) return -1
    if (valueB > valueA) return 1
    return 0
  } catch (error) {
    console.error('Error sorting table dates:', { rowA, rowB, type, error })
    return 0
  }
}

export default sortTableDates
