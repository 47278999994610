import styled from 'styled-components'
import sizing from 'styles/sizing'
import spacing from 'styles/spacing'
import colors from 'utils/styling/colors'

export const Wrapper = styled.div`
  ${sizing}
  ${spacing}

  background-color: rgba(${colors('pinturaBackground')});

  .pintura-editor {
    --color-background: ${({ theme }) => theme.pinturaBackground};
    --color-foreground: ${({ theme }) => theme.pinturaForeground};
    --color-primary: ${colors('secondary')};
  }
`
