import { useState, useEffect } from 'react'
import {
  Button,
  CaseDetails,
  FullscreenModal,
  Link,
  OrderDashboardButton,
} from 'components'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import Text from 'components/priority/Text/Text'
import { caseAtom, hasNewCaseAtom } from 'atoms'
import { useRecoilValue } from 'recoil'

export default ({ title, caseInfo, ...props }) => {
  const [showCaseDetailsModal, setShowCaseDetailsModal] = useState(false)
  const _case = useRecoilValue(caseAtom)
  const hasNewCase = useRecoilValue(hasNewCaseAtom)

  useEffect(() => {
    hasNewCase && setShowCaseDetailsModal(true)
  }, [hasNewCase])

  const openCaseDetailsModal = async () => {
    _case?.id && setShowCaseDetailsModal(true)
  }

  return (
    <Flex
      width="100%"
      padding="24px"
      justify="space-between"
      align="center"
      background="lockedAlmostBlack"
      borderBottom="1px solid"
      borderColor="gray4"
      borderOpacity="25"
      {...props}
    >
      <Flex align="center" gap="24px">
        <Text size="32px" weight="bold" color="lockedWhite">
          {title}
        </Text>
        <Link href="mailto:dignitysupport@messengerllc.com">
          <Button primary>
            <Flex gap="6px" center>
              <Icon
                icon="support"
                fill="lockedWhite"
                width="18px"
                height="18px"
              />
              <Text color="lockedWhite">Support</Text>
            </Flex>
          </Button>
        </Link>
        <OrderDashboardButton />
      </Flex>
      <Flex align="center">
        <Text size="14px" margin="0 16px 0 0" color="lockedWhite">
          {caseInfo}
        </Text>
        <Button primary disabled={!_case?.id} onClick={openCaseDetailsModal}>
          Edit Case
        </Button>
      </Flex>

      {showCaseDetailsModal && (
        <FullscreenModal show={[showCaseDetailsModal, setShowCaseDetailsModal]}>
          <CaseDetails />
        </FullscreenModal>
      )}
    </Flex>
  )
}
