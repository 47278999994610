import axios from 'api/axios.config'

export const getPages = async (pageset_id, options = {}) => {
  try {
    const response = await axios.get(`pages?page_set_id=${pageset_id}`, {
      ...options,
      signal: options?.signal,
      debug: {
        ...options?.debug,
        clientAPIFunction: 'getPages',
        clientAPIDirectory: 'pages',
      },
    })
    return response
  } catch (error) {
    throw error
  }
}

export const createPage = async (payload, options = {}) => {
  try {
    const response = await axios.post(`createPage`, payload, {
      ...options,
      signal: options?.signal,
      debug: {
        ...options?.debug,
        clientAPIFunction: 'createPage',
        clientAPIDirectory: 'pages',
      },
    })
    return response
  } catch (error) {
    throw error
  }
}

export const updatePage = async (page_id, payload, options = {}) => {
  try {
    const response = await axios.patch(`pages/${page_id}`, payload, {
      ...options,
      signal: options?.signal,
      debug: {
        ...options?.debug,
        clientAPIFunction: 'updatePage',
        clientAPIDirectory: 'pages',
      },
    })
    return response
  } catch (error) {
    throw error
  }
}

// This endpoint is not used
export const archivePage = async (page_id) => {
  try {
    const response = await axios.patch(`archivePage/${page_id}`)
    return response
  } catch (error) {
    throw error
  }
}
