import { useTinyMCESettings } from '.'
import { Editor } from '@tinymce/tinymce-react'

export default ({
  value = '',
  init: additionalInit,
  inline,
  setRef,
  caseDetails,
  isVerse = false,
  setToast,
  ...props
}) => {
  const init = useTinyMCESettings(
    additionalInit,
    inline,
    caseDetails,
    isVerse,
    setToast,
  )
  return (
    <Editor
      onInit={(evt, editor) => setRef?.(editor)}
      tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js"
      initialValue={value}
      init={init}
      {...props}
    />
  )
}
