import { Button } from 'components'
import { usePerformLogout } from 'providers/Auth0'
import { useRecoilValue } from 'recoil'
import { roleAtom } from 'atoms'

export default ({ showToAllUsers }) => {
  const logout = usePerformLogout()
  const userRole = useRecoilValue(roleAtom)

  const isMessengerUser = userRole === 'messenger-user'
  const show = showToAllUsers || !isMessengerUser
  if (!userRole || !show) return null

  return (
    <Button onClick={() => logout({ returnTo: window.location.origin })}>
      Logout
    </Button>
  )
}
