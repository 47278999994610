import { Grid, LoadingInsertImages } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import Text from 'components/priority/Text/Text'
import * as S from '../SlideoutMenu/SlideoutMenu.styled'
import * as N from '../Items/InsertItems.styled'
import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { caseAtom, itemSelector, selectedItemAtom } from 'atoms'

export default ({ show, setShow, ...props }) => {
  const _case = useRecoilValue(caseAtom)
  const selectedItem = useRecoilValue(selectedItemAtom)
  const imageItem = useRecoilValue(itemSelector({ id: selectedItem }))
  const setImageUrl = useSetRecoilState(
    itemSelector({ id: selectedItem, path: 'image.url' }),
  )
  const setImageEditedUrl = useSetRecoilState(
    itemSelector({ id: selectedItem, path: 'image.editedUrl' }),
  )
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])

  const hasEditedUrl = !!imageItem?.image?.editedUrl

  const sanitize = (data) => {
    return data.map((image) => ({
      name: '',
      image: image,
    }))
  }

  const getItems = async () => {
    setLoading(true)
    setItems(sanitize(_case.personal_images))
    setLoading(false)
  }

  const swapImage = (imageUrl) => {
    if (!imageUrl) return

    if (hasEditedUrl) setImageEditedUrl(imageUrl)

    setImageUrl(imageUrl)
    setShow(false)
  }

  useEffect(() => {
    _case.personal_images && getItems()
    // eslint-disable-next-line
  }, [_case])

  return (
    <S.ModalSlideout
      bottom="0"
      right="250px"
      left="250px"
      position="absolute"
      height={show ? '300px' : 0}
      transition="transform 0.25s ease-in-out, opacity 0.25s"
      show={show}
      background="white"
      opacity={show ? 1 : 0}
      translateY={show ? '0px' : '20px'}
      borderTop="1px solid"
      borderColor="gray4"
      {...props}
    >
      <Flex column width="100%" height="300px">
        <Flex justify="space-between" align="center" padding="12px 12px">
          <Text weight="600" size="16px">
            Choose an image
          </Text>
          <Icon
            clickable
            onClick={() => setShow(false)}
            icon="exitModal"
            fill="gray1"
            hoverfill="gray2"
          />
        </Flex>
        <S.Content column flex="1" overflowY="hidden" overflowX="auto">
          {loading ? (
            <LoadingInsertImages />
          ) : (
            <Flex full center>
              <Grid columns={items.length} minWidth="154px" {...props}>
                {items?.map((item, index) => (
                  <N.ImageItem
                    key={index}
                    align="center"
                    justify="space-between"
                    column
                    clickable
                    width="154px"
                    height="176px"
                    margin="8px"
                    radius="4px"
                    padding="16px"
                    border="1px solid"
                    borderColor="gray4"
                    background={'white'}
                    transition=".25s"
                    position="relative"
                    onClick={() => swapImage(item?.image)}
                    {...props}
                  >
                    <N.Image full flex="1" src={item?.image} />
                  </N.ImageItem>
                ))}
              </Grid>
            </Flex>
          )}
        </S.Content>
      </Flex>
    </S.ModalSlideout>
  )
}
