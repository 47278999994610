import { css } from 'styled-components'
import type { StyledFunction } from 'types/style'
import zLayers from 'utils/styling/zLayers'

export type PositioningProps = {
  position?: string
  top?: string
  right?: string
  bottom?: string
  left?: string
  zindex?: string
}

const positioning: StyledFunction<PositioningProps> = ({
  position,
  top,
  right,
  bottom,
  left,
  zindex,
}) => css`
  position: ${position};
  top: ${top};
  right: ${right};
  bottom: ${bottom};
  left: ${left};
  z-index: ${zLayers.getZ(zindex)};
`

export default positioning
