import { Button } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'

export default () => (
  <Flex width="100%" height="100vh" center>
    <Flex width="80%" height="80%" background="gray5" radius="4px" center>
      <Flex center column maxWidth="600px">
        <Text size="32px" margin="0 0 24px 0">
          Welcome!
        </Text>
        <Text margin="0 0 16px 0">
          {`\t`}Welcome to Version 2 of Directors Print Suite. We listened to
          you and tackled all the major issues our old software had. We have
          been working all year on this new version and we would love for you to
          try out this new version at your leisure. We have made big progress
          this year but there are few items that are still in development. Our
          goal is to softly move everyone over to this new version after all the
          bugs are worked out if it. You can continue to use the old software
          until this new version is complete.
        </Text>
        <Text margin="0 0 16px 0">
          {`\t`}Here is a list that did not make Public Test version but will be
          available soon. As these missing functions come available everyone
          will be emailed updates on the progress.{' '}
        </Text>
        <Flex justify="flex-start" full column margin="0 0 16px 0">
          <Text margin="0 0 4px 0">• Saved Layouts</Text>
          <Text margin="0 0 4px 0">• Custom Verse Library</Text>
          <Text margin="0 0 4px 0">• Background Watermarks</Text>
          <Text margin="0 0 4px 0">• Edit Custom backgrounds</Text>
          <Text margin="0 0 4px 0">• Custom Case Detail Fields</Text>
          <Text margin="0 0 4px 0">
            • New updated admin layouts/with multiple versions
          </Text>
          <Text margin="0 0 4px 0">• Memorial Labels</Text>
          <Text margin="0 0 4px 0">• Remembrance Registry integration</Text>
          <Text margin="0 0 4px 0">• Floral Pix integration</Text>
        </Flex>
        <Text weight="500" margin="0 0 16px 0">
          Things to remember when trying this site
        </Text>
        <Flex justify="flex-start" full column margin="0 0 16px 0">
          <Text margin="0 0 4px 0">
            • You will see all your case details and print packages pull over,
            but anything made in v1 will not be able to print nor edit
          </Text>
          <Text margin="0 0 4px 12px">
            • You can however use cases in new print packages in v2 from v1 to
            make new packages.
          </Text>
          <Text margin="0 0 4px 0">
            • Feedback, if you come upon an issue, please use the feedback
            button to let us know
          </Text>
          <Text margin="0 0 4px 0">
            • If you have changed your password in the past 2 months you may
            have to say forgot password to register access to the new site.
          </Text>
        </Flex>
        <Text margin="0 0 16px 0" full>
          If you have any issues accessing the new site, please contact us at
          <a
            href="mailto:support@bass-mollett.com"
            style={{ display: 'inline-block' }}
          >
            <Text decoration="underline" display="inline-block">
              support@bass-mollett.com
            </Text>
          </a>{' '}
          or{' '}
          <a href="tel:18552770436" style={{ display: 'inline-block' }}>
            <Text decoration="underline" display="inline-block">
              1-855-277-0436
            </Text>
          </a>
        </Text>
        <a href="/sign-up">
          <Button>Begin!</Button>
        </a>
      </Flex>
    </Flex>
  </Flex>
)
