import styled, { css } from 'styled-components'
import colors from 'utils/styling/colors'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'

export const UploadText = styled(Text)``

export const UploadSpan = styled.span`
  color: ${colors('brand')};
  cursor: ${'pointer'};
`

export const FileDropzone = styled(Flex)(
  ({ isDragActive, disabled }) => css`
    opacity: ${disabled ? '.5' : '1'};
    pointer-events: ${disabled && 'none'};
    border: 1px dashed ${colors(isDragActive ? 'secondary' : 'gray4')};
    &:active {
      border: 1px dashed ${colors('secondary')};
    }
    &:active ${UploadText} {
      color: ${colors('secondary')};
    }
  `,
)

export const HiddenInput = styled.input`
  outline: none;
`
