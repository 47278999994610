import styled, { css } from 'styled-components'
import bgBlur from 'styles/bgBlur'
import colors from 'utils/styling/colors'
import zLayers from 'utils/styling/zLayers'

const backdrop = ({ backdrop, background = 'almostBlack', opacity = '25' }) =>
  backdrop &&
  css`
    background: ${colors(background, opacity)};
    ${bgBlur}
  `

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${zLayers.controls};

  ${backdrop};
`
