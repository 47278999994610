import { useState } from 'react'
import { Button, DropIcon, Menu, HiddenMenu } from 'components'
import Icon from 'components/priority/Icon/Icon'

export default ({ text, icon, items, left, margin, children, ...props }) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <HiddenMenu
      show={[showMenu, setShowMenu]}
      left={left}
      margin={margin}
      parentElement={() => (
        <Button {...props} onClick={() => setShowMenu(!showMenu)}>
          {text}
          {icon ? (
            <Icon icon={icon} margin="0 0 0 8px" />
          ) : (
            <DropIcon margin="0 0 0 8px" up={showMenu} />
          )}
        </Button>
      )}
      hiddenElement={() => (
        <Menu items={items} show={[showMenu, setShowMenu]}>
          {children}
        </Menu>
      )}
    />
  )
}
