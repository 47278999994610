import { useState, useEffect } from 'react'
import { Expandable } from 'components'
import Flex from 'components/priority/Flex/Flex'
import * as S from './Agreement.styled'

export default ({ expand }) => {
  const [expanded, setExpanded] = useState(true)
  const [expandedColors, setExpandedColors] = useState(true)
  const [expandedIndemnification, setExpandedIndemnification] = useState(true)

  useEffect(() => {
    if (expand === 'Colors') {
      setExpanded(false)
      setExpandedColors(true)
      setExpandedIndemnification(false)
    } else if (expand === 'Indemnification') {
      setExpanded(false)
      setExpandedColors(false)
      setExpandedIndemnification(true)
    }
  }, [expand])

  return (
    <Flex column full padding="0 48px 0 0">
      <Flex column align="flex-start">
        <Expandable
          margin="0 0 16px 0"
          title="Digital Print Suite"
          show={[expanded, setExpanded]}
          full
        >
          <>
            <S.SpacedText>
              Bass-Mollett Publishers, Inc. claims no ownership rights to the
              photos, photo files, projects, captions or prints, (collectively
              defined as "Photos"), that you place in your Bass-Mollett Digital
              Print Suite Account. However, by uploading Photos into your
              Bass-Mollett Digital Print Suite Account, you agree to waive all
              moral rights to such images. In addition, you grant to
              Bass-Mollett a nonexclusive, worldwide, royalty-free license,
              which grants Bass-Mollett the right to download, upload, copy,
              print, display, reproduce, modify, publish, post, transmit and
              distribute the Photos included in your Digital Print Suite Account
              for the purpose of displaying those Photos to the users you
              select.
            </S.SpacedText>
            <S.SpacedText>
              We will not use or modify your Photos for marketing purposes or
              any other purposes without first obtaining your express written
              permission. You are responsible for monitoring and creating backup
              copies of the Photos stored in your Digital Print Suite Account.
              Bass-Mollett Digital Print Suite is not under warranty.
              Bass-Mollett will not be liable for any damage to your Photos or
              uploads, including but not limited to loss, deletion, alteration
              or accidental distribution or publication.
            </S.SpacedText>
            <S.SpacedText>
              Bass-Mollett may terminate your service and delete any and all
              information, including but not limited to your Photos, without
              notice, for any reason including, but not limited to (i) your
              violation of these Terms of Use or other policies set by
              Bass-Mollett elsewhere on the site; (ii) online conduct that
              Bass-Mollett reasonably believes is harmful to other customers,
              the business of Bass-Mollett, or other third-party information
              providers; and (iii) failure to access your account for one (1)
              year.
            </S.SpacedText>
            <S.SpacedText>
              Bass-Mollett reserves the right to disclose any Photos posted by
              you or any other customers as necessary to satisfy any law,
              regulation or governmental request, or to edit, refuse to post or
              to remove any Photos, in whole or in part, that in Bass-Mollett’s
              sole discretion and judgment is unsuitable or in violation of
              these Terms of Use or any other policy set by Bass-Mollett
              elsewhere on the site. Bass-Mollett may delete, move and edit
              Photos for any reason, at any time, without notice.
            </S.SpacedText>
            <S.SpacedText>
              By submitting Photos, you warrant that you are the sole author and
              owner of the Photos, or that you have obtained all required
              releases or permissions to permit copying, printing and licensing
              of the Photos and your use of the Bass-Mollett Digital Print
              Suite, as set forth in these Terms.
            </S.SpacedText>
            <S.SpacedText>
              Bass-Mollett reserves the right to require that you obtain a
              signed Copyright Release form from the photographer or studio for
              photographs that are signed, stamped or otherwise identified by
              any photographer or studio as copyrighted material, or any
              photograph that appears to have been taken by a professional
              photographer or studio, even if it is not marked with any sort of
              copyright.
            </S.SpacedText>
            <S.SpacedText>
              Bass-Mollett will not assist in the copying of any state or
              federal document, including but not limited to, driver's licenses,
              passports and social security cards. Any such images uploaded to
              your Digital Print Suite Account may be removed or deleted without
              notice.{' '}
            </S.SpacedText>
            <S.SpacedText>
              As a convenience to Bass-Mollett customers, Bass-Mollett may make
              third-party software available through the Bass-Mollett Digital
              Print Suite either through physical distribution or available by
              download. To use such software, you represent that you agree to
              the terms and conditions imposed by the third party provider.
              Unless otherwise stated in any specific agreement, the agreement
              to use such software will be solely between you and the third
              party provider.
            </S.SpacedText>
            <S.SpacedText>
              Bass-Mollett uses commercially reasonable efforts to maintain the
              availability of the Bass-Mollett Digital Print Suite 24 hours a
              day, 7 days a week. However, Bass-Mollett will not be liable to
              you for any unexpected outage or disruption of service.{' '}
            </S.SpacedText>
            <S.SpacedText>
              Submitting any Photos to Bass-Mollett for processing, printing,
              storage, transmission or other handling constitutes an agreement
              by you that any damage to your Photos or computer system, or any
              loss of data, by Bass-Mollett, its subsidiaries, employees, or
              agents, even if caused by negligence or other fault, will only
              entitle you to replacement with a like amount of storage and
              processing. Except for replacement, the handling of any Photos is
              not under warranty or liability, and recovery for any incidental
              or consequential damages is excluded. In all events, Bass-Mollett
              will not be liable to you for any loss of or damage to any Photos.{' '}
            </S.SpacedText>
            <S.SpacedText>
              You understand and agree that any material, including but not
              limited to Photos and software, downloaded or otherwise obtained
              through the use of the Bass-Mollett Digital Print Suite is done at
              your own discretion and risk and that you will be solely
              responsible for any damages to your computer system or loss of
              data that may result in the download or upload of such material.
              Your use of the Bass-Mollett Digital Print Suite may expose you to
              Photos you may feel objectionable. You take sole responsibility
              for such exposure.
            </S.SpacedText>
          </>
        </Expandable>
        <Expandable
          margin="0 0 16px 0"
          title="Colors"
          show={[expandedColors, setExpandedColors]}
          full
        >
          <S.SpacedText>
            We have made significant efforts to accurately display the colors of
            products that appear on the Bass-Mollett Sites. However, as the
            actual colors you see will depend on your monitor, we cannot
            guarantee that your monitor's display of any color will accurately
            reflect actual product color or finish.
          </S.SpacedText>
        </Expandable>
        <Expandable
          margin="0 0 16px 0"
          title="Indemnification"
          show={[expandedIndemnification, setExpandedIndemnification]}
          full
        >
          <S.SpacedText>
            You agree to defend, indemnify, and hold harmless Bass-Mollett and
            its affiliates from and against any and all claims, damages, costs,
            and expenses, including attorneys' fees, arising from or related to
            your use of the Bass-Mollett Sites or any breach by you of this
            Agreement, or resulting, directly or indirectly, from any materials
            or Photos you uploaded or provided to your Digital Print Suite
            Account.
          </S.SpacedText>
        </Expandable>
      </Flex>
    </Flex>
  )
}
