import type { Case, Service } from 'types/case'
import caseDates from 'mocks/dates/caseDates'
import convertObjectOfDates from 'utils/dates/convertObjectOfDates'
import calculateCaseAge from 'utils/sanitizers/calculateCaseAge'
import isoStringToDate from 'utils/dates/isoStringToDate'

const sanitizeCaseDates = (originalCase: Case): Case => {
  const _case = JSON.parse(JSON.stringify(originalCase))

  // calculate the ages, and add it to the case object
  calculateCaseAge(_case as Case)

  // convert all dates in the case object
  convertObjectOfDates(caseDates.case, _case as Case, isoStringToDate)

  // convert all dates in the case's service array
  _case?.services?.forEach((service: Service) =>
    convertObjectOfDates(caseDates.service, service, isoStringToDate),
  )

  return _case
}

export default sanitizeCaseDates
