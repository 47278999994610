import { Button } from 'components'
import Flex from 'components/priority/Flex/Flex'
import { StyledFlex } from './SideBarMenuSaveSection.styled'

export default ({ saveCase }) => {
  return (
    <>
      <StyledFlex full center column position="absolute" bottom="0">
        <Flex full center column>
          <Flex
            justify="flex-end"
            align="center"
            borderTop="1px solid"
            borderColor="gray4"
            width="100%"
            background="fill"
          ></Flex>
        </Flex>
        <Flex full center padding="8px 8px" gap="8px" column>
          <Flex
            justify="flex-end"
            align="center"
            padding="16px 24px"
            gap="8px"
            width="100%"
          >
            <Button
              primary
              onClick={() => {
                saveCase()
              }}
            >
              Save changes
            </Button>
          </Flex>
        </Flex>
      </StyledFlex>
    </>
  )
}
