import { detect } from 'detect-browser'
import type {
  BrowserInfo,
  SearchBotDeviceInfo,
  BotInfo,
  NodeInfo,
  ReactNativeInfo,
} from 'detect-browser'
import type { BrowserStats } from 'types/utils'

type DetectInfo =
  | BrowserInfo
  | SearchBotDeviceInfo
  | BotInfo
  | NodeInfo
  | ReactNativeInfo
  | null

const browserStats = (): BrowserStats => {
  let info: DetectInfo
  try {
    info = detect()
    return {
      browser: info?.name ?? undefined,
      version: info?.version ?? null,
      os: info?.os ?? null,
      type: info?.type ?? null,
    }
  } catch (error) {
    console.error('Error detecting browser stats', error)
    return {
      browser: undefined,
      version: null,
      os: null,
      type: null,
    }
  }
}

export default browserStats
