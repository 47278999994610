import * as S from './Check.styled'
import Icon from 'components/priority/Icon/Icon'
import Text from 'components/priority/Text/Text'

export default ({ checked, text, color, icon, ...props }) => (
  <S.Check clickable {...props}>
    <S.Box checked={checked} text={text} center>
      {checked && <Icon fill="white" icon={icon || 'check'} />}
    </S.Box>
    <Text color={color}>{text}</Text>
  </S.Check>
)
