import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'

export default ({
  activeTab: [active, setActive] = [null, () => {}],
  tabs = ['Elements', 'Background', 'Settings'],
  ...props
}) => {
  return (
    <Flex
      full
      align="center"
      justify="space-around"
      height="54px"
      borderBottom="1px solid"
      borderColor="gray4"
      {...props}
    >
      {tabs.map((tab) => (
        <Flex
          center
          height="100%"
          clickable
          onClick={() => setActive(tab)}
          key={tab}
        >
          <Text
            size="12px"
            weight={active === tab ? '500' : '400'}
            color={active === tab ? 'almostBlack' : 'gray2'}
          >
            {tab}
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}
