import { css, type FlattenSimpleInterpolation } from 'styled-components'
import zLayers from '../utils/styling/zLayers'

const tinyMCEStyles: FlattenSimpleInterpolation = css`
  .mce-content-body {
    font-family: 'Times New Roman', 'Times';
    font-size: 14px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    width: 100%;
    overflow-x: visible;
    margin: 0px;
    line-height: 1;
    padding: 0 2px;
  }
  /* offsets following cursor difficulty and having the wrong size outline on the text box */
  .mce-content-body:focus {
    outline: none;
  }
  .mce-edit-focus {
    outline: none;
  }

  .mce-content-body p {
    margin: 0;
  }

  .tox-tinymce-inline {
    z-index: ${zLayers.interface};
  }

  #obituary .mce-content-body {
    padding: 24px;
    line-height: 18px;
  }

  .mce-case-upper {
    text-transform: uppercase;
  }

  .mce-case-lower {
    text-transform: lowercase;
  }

  .mce-case-capitalize {
    text-transform: capitalize;
  }
`

export default tinyMCEStyles
