import styled, { css } from 'styled-components'
import editorItem from 'styles/editorItem'
import zLayers from 'utils/styling/zLayers'

export const Image = styled.div(({ imgSrc, opacity, z }) => {
  return css`
    ${editorItem}
    background: url(${imgSrc});
    background-size: cover;
    background-position: center;
    z-index: ${zLayers.getZ(z)};
    opacity: ${opacity};
  `
})
