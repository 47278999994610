import { useState } from 'react'
import { Expandable } from 'components'
import Flex from 'components/priority/Flex/Flex'
import { CaseFormSection } from '..'

export default ({ saveCase, setActiveTab }) => {
  const [expandedMemorialContribution, setExpandedMemorialContribution] =
    useState(true)

  const saveAndContinue = () => {
    setActiveTab('Military/Veteran honors')
    saveCase()
  }

  return (
    <Flex column full padding="0 48px 0 0">
      <Flex column align="flex-start">
        <Expandable
          margin="0 0 32px 0"
          title="Memorial contributions"
          show={[expandedMemorialContribution, setExpandedMemorialContribution]}
          full
        >
          <CaseFormSection
            formSection="memorialContributions"
            saveAndContinue={saveAndContinue}
          />
        </Expandable>
      </Flex>
    </Flex>
  )
}
