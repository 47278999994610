import * as icons from 'assets/icons'
import { Grid } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import Text from 'components/priority/Text/Text'

export default () => (
  <Grid columns="4">
    {Object.keys(icons).map((icon, i) => (
      <Flex
        key={`${icon}${i}`}
        column
        center
        margin="12px"
        width="100px"
        radius="2px"
      >
        <Icon icon={icon} fill="gray1" margin="0 0 8px 0" />
        <Text size="12px">{icon}</Text>
      </Flex>
    ))}
  </Grid>
)
