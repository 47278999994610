import { css } from 'styled-components'
import type { CSS } from 'types/style'
import colors from 'utils/styling/colors'

const floatingMenu: CSS = css`
  background: ${colors('FloatingMenu')};
  box-shadow: 0px 2px 4px 0px #00000040;
  border-radius: 2px;
`

export default floatingMenu
