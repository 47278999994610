import styled from 'styled-components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import disabled from 'styles/disabled'
import elements from 'styles/elements'

export const DropdownWrapper = styled(Flex)`
  ${disabled}
`

export const Dropdown = styled(Flex)`
  ${elements.default}
`

export const DropdownText = styled(Text)`
  overflow: hidden;
  mask-image: linear-gradient(
    to left,
    transparent 0%,
    black 10%,
    black 100%,
    transparent 100%
  );
`
