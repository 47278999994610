import * as S from './ThemeCard.styled'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import Icon from 'components/priority/Icon/Icon'
import zLayers from 'utils/styling/zLayers'

export default ({ theme, deleteTheme, editTheme, noHover }) => (
  <Flex column align="center" margin="0 0 32px 16px">
    <S.Theme
      height="205px"
      width="328px"
      margin="0 0 8px 0"
      theme={theme}
      noHover={noHover}
    >
      <Flex
        justify="flex-end"
        width="328px"
        zindex={zLayers.base}
        position="absolute"
      >
        <S.ActionButton
          width="32px"
          height="32px"
          margin="16px 8px"
          justify="center"
          align="center"
          onClick={() => editTheme(theme)}
        >
          <Icon icon="pencil" fill="brand" />
        </S.ActionButton>
        <S.ActionButton
          width="32px"
          height="32px"
          margin="16px 8px"
          justify="center"
          align="center"
          onClick={() => deleteTheme(theme)}
        >
          <Icon icon="archive" fill="brand" />
        </S.ActionButton>
      </Flex>
      <S.ThemeOverlay
        opacity="0.5"
        width="328px"
        height="205px"
        position="relative"
        top="0"
        left="0"
        borderRadius="50%"
      />
    </S.Theme>
    <Text>{theme.name}</Text>
  </Flex>
)
