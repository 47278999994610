import { DateInput } from 'components'
import { useRecoilState } from 'recoil'
import { editCaseFieldSelector } from 'atoms'

export default ({ name, label, margin, width }) => {
  const [value, setValue] = useRecoilState(
    editCaseFieldSelector({ name: name, path: `value` }),
  )

  return (
    <DateInput
      width={width || '100%'}
      label={label}
      margin={margin || '0 24px 0 0'}
      date={[value, setValue]}
      left
    />
  )
}
