import * as S from '../PropertiesMenu.styled'
import * as Menus from '.'

export default ({ activeTab, ...props }) => {
  const ActiveMenu = Menus[`${activeTab}Menu`] || Menus.ElementsMenu

  return (
    <S.PropertiesContent
      column
      height="100%"
      maxHeight="calc(100vh - 172px)"
      {...props}
    >
      <ActiveMenu />
    </S.PropertiesContent>
  )
}
