import axios from 'axios'
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import api from 'utils/global/api'
import { logAPI } from 'utils/debug/log'

type DebugInfo = {
  componentName: string
  componentFunction: string
  clientAPIFunction: string
  clientAPIDirectory: string
}

type CustomAxiosRequestConfig = AxiosRequestConfig & {
  debug?: DebugInfo
}

type CustomAxiosResponse<T = any> = AxiosResponse<T> & {
  config: CustomAxiosRequestConfig
}

type CustomAxiosError = AxiosError & {
  config: CustomAxiosRequestConfig
}

/**
 * Axios instance for API requests
 *
 * @description
 * - Uses base URL from global api object
 * - Includes request/response interceptors
 * - Logs API calls when DEBUG_API_CALLS is enabled
 * - Handles authentication headers automatically
 *
 * @example
 * // Component
 * const MyComponent = () => {
 *   const { handleSignal } = useAbortController('MyComponent')
 *
 *   await archive(selectedLayout.id, handleSignal('archiveLayout'))
 * }
 *
 * // API function
 * export const archive = async (pagesetId: string, options = {}) => {
 *   const response = await axios.patch(
 *     `archivePageSet/${pagesetId}`,
 *     {},
 *     {
 *       ...options,
 *       signal: options?.signal,
 *       debug: {
 *         ...options?.debug,
 *         clientAPIFunction: 'archive',
 *         clientAPIDirectory: 'pageSets'
 *       }
 *     }
 *   )
 *   return response
 * }
 */
const instance: AxiosInstance = axios.create({ baseURL: api })

instance.interceptors.request.use(
  (config: CustomAxiosRequestConfig) => {
    const messengerOrderIdToken = localStorage.getItem('messengerOrderIdToken')
    if (messengerOrderIdToken) {
      config.headers = config.headers || {}
      config.headers['x-order'] = messengerOrderIdToken
    }

    logAPI.request({
      componentName: config?.debug?.componentName,
      componentFunction: config?.debug?.componentFunction,
      clientAPIFunction: config?.debug?.clientAPIFunction,
      clientAPIDirectory: config?.debug?.clientAPIDirectory,
      APIConfig: config,
    })

    return config
  },
  (error: CustomAxiosError) => {
    logAPI.error({
      componentName: error?.config?.debug?.componentName,
      componentFunction: error?.config?.debug?.componentFunction,
      clientAPIFunction: error?.config?.debug?.clientAPIFunction,
      clientAPIDirectory: error?.config?.debug?.clientAPIDirectory,
      APIError: error,
      isAPIRequest: true,
    })
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response: CustomAxiosResponse) => {
    logAPI.response({
      componentName: response?.config?.debug?.componentName,
      componentFunction: response?.config?.debug?.componentFunction,
      clientAPIFunction: response?.config?.debug?.clientAPIFunction,
      clientAPIDirectory: response?.config?.debug?.clientAPIDirectory,
      APIResponse: response,
    })
    return response
  },
  (error: CustomAxiosError) => {
    logAPI.error({
      componentName: error?.config?.debug?.componentName,
      componentFunction: error?.config?.debug?.componentFunction,
      clientAPIFunction: error?.config?.debug?.clientAPIFunction,
      clientAPIDirectory: error?.config?.debug?.clientAPIDirectory,
      APIError: error,
      isAPIRequest: false,
    })
    return Promise.reject(error)
  },
)

instance.defaults.withCredentials = false

export default instance
