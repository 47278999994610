import axios from 'api/axios.config'

export const create = async (payload, options = {}) => {
  try {
    const response = await axios.post(`createPageSet`, payload, {
      ...options,
      signal: options?.signal,
      debug: {
        ...options?.debug,
        clientAPIFunction: 'create',
        clientAPIDirectory: 'pageSets',
      },
    })
    return response
  } catch (error) {
    throw error
  }
}

export const update = async (pageset_id, payload, options = {}) => {
  try {
    const response = await axios.patch(`pageSets/${pageset_id}`, payload, {
      ...options,
      signal: options?.signal,
      debug: {
        ...options?.debug,
        clientAPIFunction: 'update',
        clientAPIDirectory: 'pageSets',
      },
    })
    return response
  } catch (error) {
    throw error
  }
}

export const archive = async (pageset_id, options = {}) => {
  try {
    const response = await axios.patch(
      `archivePageSet/${pageset_id}`,
      {},
      {
        ...options,
        signal: options?.signal,
        debug: {
          ...options?.debug,
          clientAPIFunction: 'archive',
          clientAPIDirectory: 'pageSets',
        },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const getByLayoutId = async (layout_id) => {
  try {
    const response = await axios.get(`pageSets?layout_id=${layout_id}`)
    return response
  } catch (error) {
    throw error
  }
}

export const getCustomPageSets = async (layout_id, theme_id, options = {}) => {
  try {
    const response = await axios.get(
      `pageSets?layout_id=${layout_id}&themeId=${theme_id}&custom_pages=true`,
      {
        ...options,
        signal: options?.signal,
        debug: {
          ...options?.debug,
          clientAPIFunction: 'getCustomPageSets',
          clientAPIDirectory: 'pageSets',
        },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

export const updatePagesByPageSetsId = async (
  pageset_id,
  payload,
  options = {},
) => {
  try {
    const response = await axios.patch(`updatePages/${pageset_id}`, payload, {
      ...options,
      signal: options?.signal,
      debug: {
        ...options?.debug,
        clientAPIFunction: 'updatePagesByPageSetsId',
        clientAPIDirectory: 'pageSets',
      },
    })
    return response
  } catch (error) {
    throw error
  }
}
