import { css } from 'styled-components'
import type { StyledFunction } from 'types/style'
import zLayers from 'utils/styling/zLayers'

type EditorItemProps = {
  full?: boolean
  width?: string | number
  height?: string | number
  x?: string | number
  y?: string | number
  z?: string | number
  edits?: {
    rotate?: string | number
  }
  print?: boolean
}

/**
 * Generates CSS styling for positioned editor items with print-mode support
 * @param full - Makes element full width/height
 * @param width - Element width in pixels
 * @param height - Element height in pixels
 * @param x - X position (applied in print mode)
 * @param y - Y position (applied in print mode)
 * @param z - Z-index for stacking
 * @param edits - Transform properties
 * @param edits.rotate - Rotation in degrees
 * @param print - Enables print-mode positioning
 * @returns Styled-components CSS
 */
const editorItem: StyledFunction<EditorItemProps> = ({
  full,
  width,
  height,
  x,
  y,
  z,
  edits,
  print,
}) => css`
  position: absolute;
  width: ${full ? '100%' : width && `${width}px`};
  height: ${full ? '100%' : height && `${height}px`};
  z-index: ${z && `${zLayers.getZ(z)}`};
  top: ${print && y && `${y}px`};
  left: ${print && x && `${x}px`};
  ${print && edits?.rotate ? `transform: rotate(${edits.rotate}deg);` : ''}
`

export default editorItem
