import styled, { css } from 'styled-components'
import colors from 'utils/styling/colors'
import zLayers from 'utils/styling/zLayers'

export const SortableItem = styled.li(
  ({ isDragged, isSelected, currentPage }) => css`
    padding: 16px;
    height: 125px;
    margin: 16px 16px;
    list-style-type: none;
    z-index: ${zLayers.menu};
    cursor: ${isDragged && 'grabbing'};
    border: 2px solid ${currentPage ? colors('brand') : colors('gray4')};
    background-color: ${isDragged || isSelected
      ? colors('palePrimary')
      : colors('white')};
    &:hover {
      border: 2px solid ${colors('gray3')};
    }
  `,
)

export const SortableList = styled.ul(
  ({ isDragged }) => css`
    padding: 0;
    cursor: ${isDragged ? 'grabbing' : undefined};
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  `,
)
