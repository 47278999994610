import styled, { css } from 'styled-components'
import propsToCSS from 'utils/styling/propsToCSS'
import Flex from 'components/priority/Flex/Flex'
import elements from 'styles/elements'
import disabled from 'styles/disabled'

export const Check = styled(Flex)`
  ${disabled}
`

const boxPropStyles = {
  default: elements.default,
  checked: elements.secondary,
}

export const Box = styled(Flex)(
  ({ text, ...props }) => css`
    width: 20px;
    height: 20px;
    margin-right: ${text && '8px'};

    ${propsToCSS(props, boxPropStyles)}
  `,
)
