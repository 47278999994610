import styled from 'styled-components'
import Flex from 'components/priority/Flex/Flex'
import colors from 'utils/styling/colors'
import disabled from 'styles/disabled'

export const MenuItem = styled(Flex)`
  ${disabled}
  &:hover {
    background: ${colors('gray5')};
  }
`
