import { Metric } from 'web-vitals'

const reportWebVitals = (onPerfEntry: (metric: Metric) => void) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    try {
      import('web-vitals').then(
        ({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
          getCLS(onPerfEntry)
          getFID(onPerfEntry)
          getFCP(onPerfEntry)
          getLCP(onPerfEntry)
          getTTFB(onPerfEntry)
        },
      )
    } catch (error) {
      console.error('Error importing web-vitals:', error)
    }
  }
}

export default reportWebVitals
