import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import * as S from './ToggleSwitch.styled'

export const ToggleSwitch = ({
  label,
  labelRight,
  labelLeft,
  value: [value, setValue] = [false, null],
  color,
  spaceBetween,
  ...props
}) => (
  <S.ToggleSwitch
    clickable
    {...props}
    column={!labelRight && !labelLeft}
    labelRight={labelRight}
    justify={spaceBetween ? 'space-between' : 'start'}
    align="center"
    onClick={() => setValue(!value)}
  >
    {label && (
      <Text
        align={!spaceBetween ? 'center' : undefined}
        color={color}
        margin={
          labelRight ? '0 0 0 8px' : labelLeft ? '0 8px 0 0' : '0 0 8px 0'
        }
      >
        {label}
      </Text>
    )}

    <Flex
      width="44px"
      minWidth="44px"
      height="28px"
      radius="44px"
      align="center"
      justify="flex-start"
      position="relative"
      background={value ? 'secondary' : 'gray3'}
      transition="0.25s ease"
      switchOn={value}
      labelRight={labelRight}
      labelLeft={labelLeft}
    >
      <Flex
        switchOn={value}
        position="absolute"
        left={value ? '18px' : '2px'}
        background="white"
        width="24px"
        height="24px"
        radius="50%"
        transition="0.25s ease"
      />
    </Flex>
  </S.ToggleSwitch>
)
