import { EditorProps } from 'types/tinyMCE'

export default (editorProps: EditorProps) => {
  const { editor } = editorProps

  editor.on('SkinLoaded', () => {
    requestAnimationFrame(() => {
      editor.ui.hide()
      editor.getBody().blur()
    })
  })
}
