import styled, { css } from 'styled-components'
import InlineSVG from 'react-inlinesvg'
import spacing from 'styles/spacing'
import decorations from 'styles/decorations'
import positioning from 'styles/positioning'
import colors from 'utils/styling/colors'
import type { SVGStyledProps } from 'types/svg'
import { ComponentType } from 'react'

export const SVG = styled(InlineSVG as ComponentType<any>)(
  ({ fill, hoverfill, opacity, ...props }: SVGStyledProps) => css`
    & path {
      fill: ${colors(fill)};
      opacity: ${opacity};
      transition: fill 0.25s;
    }
    &:hover {
      & path {
        fill: ${colors(hoverfill)};
      }
    }
    ${spacing(props)}
    ${decorations(props)}
    ${positioning(props)}
  `,
)
