import { useDropzone } from 'react-dropzone'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import * as S from './FileDropzone.styled'
import LoadingSpinner from 'components/global/LoadingSpinner/LoadingSpinner'

export default ({
  onDrop = () => {},
  text = 'Drag and drop files here',
  allowMultiple = false,
  fileTypes = [],
  disabled = false,
  isLoading = false,
  ...props
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <S.FileDropzone
      padding="80px"
      radius="2px"
      center
      {...getRootProps({ refKey: 'innerRef' })}
      disabled={disabled || isLoading}
      isDragActive={isDragActive}
      {...props}
    >
      <S.HiddenInput
        {...getInputProps()}
        multiple={allowMultiple}
        accept={fileTypes?.join(', ')}
      />
      <Flex column center>
        <Flex
          center
          margin="0 0 20px"
          background={isLoading ? 'transparent' : 'gray5'}
          height="100px"
          width="100px"
          radius="100px"
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Icon width="72px" height="72px" icon="upload" color="secondary" />
          )}
        </Flex>
        <S.UploadText weight="500">Drop images here</S.UploadText>
        <S.UploadText weight="500">
          or <S.UploadSpan>choose files</S.UploadSpan>
        </S.UploadText>
      </Flex>
    </S.FileDropzone>
  )
}
