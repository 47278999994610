import { Input } from 'components'
import { useState } from 'react'
import * as S from './EditorSearchBar.styled'
import { getAssetsByName } from 'api/assets'
import { useSetRecoilState } from 'recoil'
import { editorSearchBarLoading, editorSearchBarResults } from 'atoms'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({
  selected,
  children,
  setSelectedMenu,
  setShowSlideout,
  menu,
  ...props
}) => {
  const handleError = useErrorHandler('EditorSearchBar')
  const [value, setValue] = useState('')
  const setEditorSearchBarResults = useSetRecoilState(editorSearchBarResults)
  const setEditorSearchBarLoading = useSetRecoilState(editorSearchBarLoading)

  const getAssets = async () => {
    const trimmedValue = value.trim()
    if (trimmedValue === '') return

    try {
      setEditorSearchBarLoading(true)
      setSelectedMenu(menu)
      setShowSlideout(menu)
      const { data } = await getAssetsByName(value)
      data.length === 0
        ? setEditorSearchBarResults('No Matching Results')
        : setEditorSearchBarResults(data)
      setEditorSearchBarLoading(false)
    } catch (error) {
      handleError(
        error?.response?.data?.message || error.message,
        error,
        null,
        'getAssets',
      )
    }
  }

  const handleKeyDown = (key) => {
    if (key === 'Enter') getAssets()
    else if (key === 'Escape') setShowSlideout(false)
  }
  return (
    <S.EditorSearchBar
      align="center"
      justify="space-between"
      height="54px"
      borderBottom="1px solid"
      borderColor="gray4"
      clickable
      {...props}
    >
      <Input
        value={[value, setValue]}
        width="77%"
        margin="0 0 0 12px"
        {...props}
        onKeyDown={({ key }) => handleKeyDown(key)}
      />
      <S.SearchIcon
        selected={selected}
        icon="search"
        fill="gray2"
        margin="0 12px 0 0"
        onClick={() => getAssets()}
      />
    </S.EditorSearchBar>
  )
}
