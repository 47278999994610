import axios from 'api/axios.config'

export const getCustomVerses = async () => {
  try {
    const response = await axios.get(`customVerses`)
    return response
  } catch (error) {
    throw error
  }
}

export const createCustomVerse = async (payload) => {
  try {
    const response = await axios.post(`createCustomVerse`, payload)
    return response

    // expected payload:
    // {
    //   html: <String> (REQUIRED)
    //   html_for_render: <String> (REQUIRED),
    //   reference: <String> (REQUIRED),
    // }
  } catch (error) {
    throw error
  }
}

export const updateCustomVerse = async (id, payload) => {
  try {
    const response = await axios.patch(`customVerses/${id}`, payload)
    return response

    // expected payload:
    // {
    //   html: <String> (REQUIRED)
    //   html_for_render: <String> (REQUIRED),
    //   reference: <String> (REQUIRED),
    // }
  } catch (error) {
    throw error
  }
}

export const deleteCustomVerse = async (id) => {
  try {
    const response = await axios.delete(`customVerses/${id}`)
    return response
  } catch (error) {
    throw error
  }
}
