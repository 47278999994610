import { Button } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import Text from 'components/priority/Text/Text'
import { CaseArrayWrapper } from './'
import { useRecoilState } from 'recoil'
import { caseValuesAtom } from 'atoms'

export default ({ type, shortType, title, width, margin }) => {
  const [fieldValue, setFieldValue] = useRecoilState(caseValuesAtom(type))
  if (!fieldValue) return

  const addArrayInput = () => {
    let updatedArray = fieldValue.value ? [...fieldValue.value] : []

    updatedArray.push('')
    setFieldValue({
      ...fieldValue,
      value: updatedArray,
    })
  }

  //splice out the index of the item to be removed from an array
  const removeArrayInput = (index) => {
    let newArr = [...fieldValue.value]
    newArr.splice(index, 1)
    setFieldValue({
      ...fieldValue,
      value: newArr,
    })
  }
  return (
    <Flex full column margin={margin || '0'}>
      <Flex full justify="space-between" align="center">
        <Text as="h3" transform="capitalize">
          {title || type}
        </Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" margin="0 0 10px 0">
        <Flex wrap="wrap" width={width || '280px'}>
          {fieldValue?.value?.map((item, index) => (
            <Flex key={index} width={width || '280px'} margin="0 0 10px 0">
              <CaseArrayWrapper
                label={`${shortType} ${index + 1}`}
                name={`${type}`}
                index={index}
                margin="0"
                width={width || '280px'}
                elementRight={() => (
                  <Icon
                    icon="deleteOutline"
                    fill="gray2"
                    hoverfill="gray1"
                    onClick={() => removeArrayInput(index)}
                  />
                )}
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Button
        margin="0 0 24px"
        width={width || '280px'}
        icon="plus"
        onClick={() => addArrayInput()}
      >
        Add {shortType}
      </Button>
    </Flex>
  )
}
