import styled, { css } from 'styled-components'
import editorItem from 'styles/editorItem'

export const Text = styled.div(
  ({ editing, edits }) => css`
    ${editorItem}
    cursor: ${editing && 'text'};
    height: auto;
    border-radius: ${edits?.borderRadius || 0}px;
    background-color: ${edits?.backgroundColor};
    box-shadow: ${edits?.dropShadow
      ? `${edits?.dropShadow?.xOffset}px ${edits?.dropShadow?.yOffset}px
      ${edits?.dropShadow?.blur}px ${edits?.dropShadow?.spread}px
      ${edits?.dropShadow?.color}`
      : `${edits?.boxShadow}`};
    border: ${edits?.borderThickness}px ${edits?.borderThickness && 'solid'}
      ${edits?.borderColor};
    border-radius: ${edits?.borderRadius};
    opacity: ${edits?.opacity};
    transform: ${edits?.transform};
    ${edits?.textShadow
      ? `text-shadow: ${edits?.textShadow?.xOffset}px ${edits?.textShadow?.yOffset}px ${edits?.textShadow?.blur}px ${edits?.textShadow?.color};`
      : ''}
  `,
)

export const TextContainer = styled.div(
  ({ editing, cantHighlight }) => css`
    user-select: ${cantHighlight ? 'none' : 'auto'};
    pointer-events: ${!editing && 'none'};
  `,
)
