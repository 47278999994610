import styled from 'styled-components'
import zLayers from 'utils/styling/zLayers'
import flex from 'styles/flex'
import sizing from 'styles/sizing'

export const Canvas = styled.div`
  ${flex}
  ${sizing}
  z-index: ${zLayers.default};
  overflow: auto;
`
