import type { TableDate, ServiceDate } from '../../types/dates'

type DashboardDates = {
  table: TableDate[]
  service: ServiceDate[]
}

/**
 * Note: dashboardDates mock expects optional fields to be included in the nested arrays.
 */
const dashboardDates: DashboardDates = {
  table: [
    'created_at',
    'date_of_death',
    'sortable_date_of_death',
    'sortable_created_at',
  ],
  service: [
    'location',
    'time',
    'date',
    'type',
    'default',
    'city',
    'state',
    'address',
    'clergyPlace',
    'country',
    'endTime',
    'postalCode',
    'roomChapel',
    'startTime',
  ],
}

export default dashboardDates
