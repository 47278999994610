import styled, { css } from 'styled-components'
import Flex from 'components/priority/Flex/Flex'
import colors from 'utils/styling/colors'

const activeBorder = css`
  border-bottom: 2px solid ${colors('secondary')};
`

export const NavItem = styled(Flex)(
  ({ selected }) => css`
    border-bottom: 2px solid transparent;
    transition: 0.15s;

    ${selected && activeBorder}
    &:hover {
      ${activeBorder}
    }
  `,
)
