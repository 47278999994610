const customToolbarButtons = (editorProps: { editor: Editor }) => {
  const { editor } = editorProps

  return customButtons.map((button) => {
    return editor.ui.registry.addButton(
      button.identifier,
      button.config(editor),
    )
  })
}

const customButtons = [
  {
    identifier: 'selectall',
    config: (editor: Editor) => ({
      text: 'Select All',
      tooltip: 'Select All',
      icon: 'select-all',
      onAction: () => editor.execCommand('SelectAll'),
    }),
  },
]

export default customToolbarButtons
