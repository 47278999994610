import { css } from 'styled-components'
import svgFill from 'styles/svgFill'
import disabled from 'styles/disabled'
import colors from 'utils/styling/colors'
import type { CSS, ElementStyles, StyledFunction } from 'types/style'
import type { DisabledProps } from 'styles/disabled'

const common: CSS = css<DisabledProps>`
  border-radius: 2px;
  ${disabled as StyledFunction<DisabledProps>}
`

const elements: ElementStyles = {
  default: css`
    ${common}
    ${svgFill(colors('gray1'))}
    background: ${colors('white')};
    color: ${colors('gray1')};
    border: 1px solid ${colors('gray4')};
    &:hover {
      background: ${colors('gray5')};
    }
  `,

  primary: css`
    ${common}
    ${svgFill(colors('lockedWhite'))}
    background: ${colors('brand')};
    color: ${colors('lockedWhite')};
    border: 1px solid ${colors('brand')};
    &:hover {
      background: ${colors('brand', 90)};
    }
  `,

  secondary: css`
    ${common}
    ${svgFill(colors('lockedWhite'))}
    background: ${colors('secondary')};
    color: ${colors('lockedWhite')};
    border: 1px solid ${colors('secondary')};
    &:hover {
      background: ${colors('secondary', 90)};
    }
  `,

  outline: css`
    ${common}
    ${svgFill(colors('lockedWhite'))}
    background: transparent;
    color: ${colors('lockedWhite')};
    border: 1px solid ${colors('lockedGray2')};
    &:hover {
      background: ${colors('lockedWhite', 10)};
    }
  `,

  destructive: css`
    ${common}
    ${svgFill(colors('lockedWhite'))}
    background: ${colors('error')};
    color: ${colors('lockedWhite')};
    border: 1px solid ${colors('error')};
    &:hover {
      background: ${colors('error', 90)};
    }
  `,
}

export default elements
