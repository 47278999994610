import styled from 'styled-components'
import Flex from 'components/priority/Flex/Flex'
import disabled from 'styles/disabled'

export const Check = styled(Flex)`
  ${disabled}
`

export const Fill = styled(Flex)`
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1);
    }
  }

  animation: bounce-in 0.15s ease;
`
