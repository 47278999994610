import { AnimatedHeight } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import * as S from './Tabs.styled'
import findIndex from 'lodash/findIndex'

export default ({
  tabs,
  width,
  noOverflow,
  selected: [selected, setSelected] = ['', () => {}],
}) => {
  const selectedIndex = findIndex(tabs, ({ title }) => title === selected)

  return (
    <Flex full overflow="hidden">
      <Flex column margin="0 42px" width={width}>
        {tabs?.map(({ title }, i) => (
          <S.Tab
            clickable
            key={title}
            padding="16px"
            radius="2px"
            selected={selectedIndex === i}
            onClick={() => setSelected(title)}
          >
            <Text>{title}</Text>
          </S.Tab>
        ))}
      </Flex>
      <Flex
        align="flex-start"
        column
        flex="1"
        overflow={!noOverflow ? 'auto' : 'inherit'}
        height="80vh"
      >
        {tabs?.map(({ title, content }, i) => (
          <AnimatedHeight
            key={title}
            height={selectedIndex === i ? 'auto' : 0}
            duration={250}
          >
            {content}
          </AnimatedHeight>
        ))}
      </Flex>
    </Flex>
  )
}
