const zLayers = {
  // Negative layers
  behindBackground: -2,
  background: -1,

  // Base layers (0-10)
  default: 0,
  base: 1,
  contentBase: 2,
  controls: 3,
  toast: 4,

  // Interface layers (11-49)
  interface: 10,
  menu: 11,
  content: 20,

  // Overlay layers (50+)
  overlayBase: 50,
  overlayTop: 51,
  modal: 100,

  // Helper function for dynamic z-index
  getItemZ: (z: number | string | undefined) => (z ? Number(z!) + 1 : 1),
  addOne: (z: number | string | undefined) => Number(z!) + 1,
  getZ: (z: number | string | undefined) => Number(z!),
} as const

export default zLayers
