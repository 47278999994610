import { tableLiteMock } from 'mocks'
import { useNavigate } from 'hooks'
import { TableLite } from 'components'
import Flex from 'components/priority/Flex/Flex'

export default ({ ...props }) => {
  const navigate = useNavigate()

  return (
    <Flex width="90vw">
      <TableLite
        height="300px"
        rowClick={(row) => navigate(`/case/${row?.id}`)}
        data={tableLiteMock}
        loaded={true}
        structure={[
          { title: 'Case', key: 'id' },
          { title: 'Deceased name', key: 'name' },
        ]}
        {...props}
      />
    </Flex>
  )
}
