import {
  pageZoomAtom,
  canvasRefAtom,
  pageRefAtom,
  pageExceedsCanvasAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import calculatePercent from 'utils/global/calculatePercent'

export default (zoomPercent = 10) => {
  const [zoom, setZoom] = useRecoilState(pageZoomAtom)
  const setPageExceedsCanvas = useSetRecoilState(pageExceedsCanvasAtom)
  const pageRef = useRecoilValue(pageRefAtom)
  const canvasRef = useRecoilValue(canvasRefAtom)

  return (type) => {
    const maxReached = type === 'add' && zoom >= 400
    const minReached = type === 'subtract' && zoom <= 10
    const conditions = !minReached && !maxReached

    if (conditions) {
      // gets the canvas and page's widths
      const canvasWidth = canvasRef.getBoundingClientRect().width
      const pageWidth = pageRef.getBoundingClientRect().width

      // calculates the pages width and adds or subtracts it with the zoomPercent
      const newPageWidth = calculatePercent(type, pageWidth, zoomPercent)

      // calculates the new zoom percentage
      const zoomType = {
        add: zoom + zoomPercent,
        subtract: zoom - zoomPercent,
      }

      // sets a flag to determine whether the page is larger than the canvas
      setPageExceedsCanvas(newPageWidth > canvasWidth)
      // sets the page's new dimensions
      setZoom(zoomType[type])
    }
  }
}
