import styled, { css } from 'styled-components'
import colors from 'utils/styling/colors'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'

export const SelectableCard = styled(Flex)(
  ({ maxWidth, maxHeight, cardPadding, isSelected }) => css`
    ${maxWidth && `max-width: ${maxWidth};`}
    ${maxHeight && `max-height: ${maxHeight};`}
    padding: ${cardPadding || '0px'};
    outline: ${isSelected ? '3px solid' : '1px solid'};
    outline-color: ${isSelected ? colors('brand') : colors('gray4')};
    border-radius: 4px;
    cursor: pointer;
  `,
)

//the user base is old, increase the size of clickable icons this is up from 14px
export const CardIcon = styled(Icon)`
  height: 18px;
  width: 18px;
  position: absolute;
  top: 8px;
  left: 8px;
`
