import { useState, useEffect } from 'react'
import { Button, Input } from 'components'
import Flex from 'components/priority/Flex/Flex'
import { NestedObjectWrapper } from './'
import { useRecoilValue } from 'recoil'
import { caseValuesAtom } from 'atoms'

export default ({ formField, width, margin }) => {
  const fieldValue = useRecoilValue(caseValuesAtom(formField.key))
  const [newType, setNewType] = useState(null)
  const [fieldValueKeys, setFieldValueKeys] = useState([])
  const isAdditionalFamily = fieldValue.id === 'additional_family'

  useEffect(() => {
    let keys =
      fieldValue?.value?.length > 0
        ? [...new Set(fieldValue.value.map((item) => item.type))]
        : []
    setFieldValueKeys(keys)
  }, [fieldValue])

  const addNewType = () => {
    if (isAdditionalFamily && !newType) return
    const updatedTypes = fieldValueKeys ? [...fieldValueKeys] : []
    updatedTypes.push(newType)
    setFieldValueKeys(updatedTypes)
    setNewType(null)
  }
  return (
    <Flex width={width} wrap="wrap" margin={margin || '0'}>
      {fieldValueKeys.length > 0 &&
        fieldValueKeys.map((type, index) => (
          <NestedObjectWrapper
            key={`${type}_${index}`}
            type={formField.key}
            shortType={type}
            title={type}
            formField={formField}
            width={width}
          />
        ))}
      <Flex
        margin="0 0 24px 0"
        justify="space-between"
        align="flex-end"
        width={width}
      >
        <Input
          label={formField.addLabel}
          placeholder={formField.addPlaceholder}
          value={[newType, setNewType]}
          margin="0 12px 0 0"
        />
        <Button onClick={() => addNewType()}>Add</Button>
      </Flex>
    </Flex>
  )
}
