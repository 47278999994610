import { Flex } from 'components/priority/Flex/Flex.styled'
import styled, { css } from 'styled-components'
import colors from 'utils/styling/colors'
import zLayers from 'utils/styling/zLayers'

const rulerHeight = 25
const rulerWidth = 25
const inch = 96

export const Ruler = styled(Flex)`
  background: ${colors('gray1', 50)};
  height: ${rulerHeight}px;
  width: ${rulerWidth}px;
  position: absolute;
  overflow: hidden;
  z-index: ${zLayers.overlayTop};
`

export const HorizontalRuler = styled(Ruler)(
  ({ width, rulerInset }) => css`
    top: ${rulerInset ? `0` : `-${rulerHeight}px`};
    left: 0;
    width: ${width}px;
  `,
)

export const HorizontalInch = styled(Flex)(
  ({ rulerInset }) => css`
    height: 100%;
    width: ${inch}px;
    color: ${colors(rulerInset ? 'white' : 'gray1')};
    border-left: 1px solid ${colors(rulerInset ? 'white' : 'gray1')};
  `,
)

export const HorizontalQuarterInch = styled(Flex)(
  ({ rulerInset }) => css`
    width: ${inch * 0.25}px;
    height: 25%;
    border-right: 1px solid ${colors(rulerInset ? 'white' : 'gray1')};
  `,
)

export const HorizontalHalfInch = styled(Flex)(
  ({ rulerInset }) => css`
    width: ${inch * 0.25}px;
    height: 50%;
    border-right: 1px solid ${colors(rulerInset ? 'white' : 'gray1')};
  `,
)

export const VerticalRuler = styled(Ruler)(
  ({ height, rulerInset }) => css`
    top: 0;
    left: ${rulerInset ? `0` : `-${rulerWidth}px`};
    height: ${height}px;
    flex-direction: column;
  `,
)

export const VerticalInch = styled(Flex)(
  ({ rulerInset }) => css`
    width: 100%;
    height: ${inch}px;
    color: ${colors(rulerInset ? 'white' : 'gray1')};
    border-top: 1px solid ${colors(rulerInset ? 'white' : 'gray1')};
    flex-direction: column;
  `,
)

export const VerticalQuarterInch = styled(Flex)(
  ({ rulerInset }) => css`
    height: ${inch * 0.25}px;
    width: 25%;
    border-bottom: 1px solid ${colors(rulerInset ? 'white' : 'gray1')};
  `,
)

export const VerticalHalfInch = styled(Flex)(
  ({ rulerInset }) => css`
    height: ${inch * 0.25}px;
    width: 50%;
    border-bottom: 1px solid ${colors(rulerInset ? 'white' : 'gray1')};
  `,
)

export const RulerCorner = styled(Ruler)(
  ({ rulerInset }) => css`
    display: ${rulerInset ? 'none' : 'flex'};
    top: -${rulerHeight}px;
    left: -${rulerWidth}px;
    width: ${rulerWidth}px;
    height: ${rulerHeight}px;
  `,
)

export const HorizontalCursorLine = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  background: ${colors('error')};
  z-index: ${zLayers.modal};
  pointer-events: none;
`

export const VerticalCursorLine = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: ${colors('error')};
  z-index: ${zLayers.modal};
  pointer-events: none;
`
