import axios from 'axios'
import { useCallback } from 'react'
import { useSetToast } from 'hooks'
import { debugLog, errorLog } from 'utils/debug/log'

type CustomError = Error & {
  code?: number
}

type ToastMessage = string | null | undefined

export const useErrorHandler = (componentName: string = 'Unknown') => {
  const setToast = useSetToast()

  const handleError = useCallback(
    (
      text?: ToastMessage,
      error?: CustomError,
      icon: string | null = 'info',
      functionName: string = 'Unknown',
    ) => {
      const msg = `in component [${componentName}] within ${functionName}():`
      if (axios.isCancel(error)) {
        debugLog(`🚫 Request cancelled ${msg}`)
        return
      }
      if (
        error?.name === 'AbortError' ||
        error?.name === 'CanceledError' ||
        error?.message === 'canceled' ||
        error?.code === 20
      ) {
        debugLog(`📴 Operation aborted ${msg}`)
        return
      }
      setToast({
        text:
          text || 'An unexpected error has occurred. Please try again later.',
        icon,
      })
      errorLog(`❌ An error occured ${msg}`, error)
    },
    [setToast, componentName],
  )

  return handleError
}
