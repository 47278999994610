import { Input } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'

export default ({
  value: [value, setValue] = ['', null],
  onEnter,
  margin,
  width,
  flex,
  ...props
}) => {
  return (
    <Flex center position="relative" margin={margin} width={width} flex={flex}>
      <Input
        value={[value, setValue]}
        padding="12px 32px"
        elementRight={() =>
          value && (
            <Icon
              icon="deleteOutline"
              fill="gray2"
              hoverfill="gray1"
              onClick={() => setValue('')}
            />
          )
        }
        elementLeft={() => <Icon icon="search" fill="gray2" />}
        {...props}
      />
    </Flex>
  )
}
