import { Button } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import Icon from 'components/priority/Icon/Icon'
import * as S from './Support.styled'

export default () => (
  <Flex column>
    <Text size="24px" weight="600" margin="0 0 8px">
      Get in touch
    </Text>
    <Text margin="0 0 40px">
      Connect with us for general assistance, technical issues or to report
      problems
    </Text>
    <Flex align="center" margin="0 0 40px">
      <Flex width="32px" height="32px" position="relative" margin="0 24px 0 0">
        <S.PhoneIcon icon="phone" fill="secondary" />
        <S.SpeechBubbleIcon icon="speechBubble" fill="gray3" />
      </Flex>
      <Flex column>
        <Text margin="0 0 12px" weight="500">
          Please call toll free
        </Text>
        <Text>1-855-277-0436</Text>
      </Flex>
    </Flex>
    <Flex align="center">
      <Flex width="32px" height="32px" position="relative" margin="0 24px 0 0">
        <Icon icon="clock" />
      </Flex>
      <Flex column>
        <Text margin="0 0 12px" weight="500">
          Customer Service
        </Text>
        <Text>We offer 24/7 tech support</Text>
      </Flex>
    </Flex>
    <Flex>
      <Button
        secondary
        margin="20px 0px"
        onClick={() => window.open('https://www.fastsupport.com', '_blank')}
      >
        Support
      </Button>
    </Flex>
  </Flex>
)
