import axios from 'api/axios.config'

export const create = async (payload) => {
  try {
    const response = await axios.post(`createOrderPageSet`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const update = async (order_pageset_id, payload, options = {}) => {
  try {
    const response = await axios.patch(
      `orderPageSets/${order_pageset_id}`,
      payload,
      {
        ...options,
        signal: options?.signal,
        debug: {
          ...options?.debug,
          clientAPIFunction: 'update',
          clientAPIDirectory: 'orderPageSets',
        },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

//no archive listed on dps-FE
// export const archive = async (pageset_id, payload) =>
//   axios.patch(`archivePageSet/${pageset_id}`, payload);

// NOTE: This endpoint is not used
export const getByOrderLayoutId = async (order_layout_id) => {
  try {
    const response = await axios.post(
      `orderPageSets?order_layout_id=${order_layout_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

export const getOrderPageSets = async (order_layout_id) => {
  try {
    const response = await axios.get(
      `orderPageSets?order_layout_id=${order_layout_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

export const getHomeLayoutPageSets = async (layout_id, theme_id = null) => {
  try {
    let endpoint = `pageSets?layout_id=${layout_id}&custom_pages=true`
    if (theme_id) endpoint += `&theme_id=${theme_id}`

    const response = await axios.get(endpoint)
    return response
  } catch (error) {
    throw error
  }
}
