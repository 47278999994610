import Icon from 'components/priority/Icon/Icon'
import Flex from 'components/priority/Flex/Flex'
import * as S from './Modal.styled'
import zLayers from 'utils/styling/zLayers'

export default ({
  children,
  show: [show, setShow] = [false, () => {}],
  hideClose,
  ...props
}) => (
  <S.Modal minWidth="364px" show={show} {...props}>
    <Flex
      clickable
      position="absolute"
      top="24px"
      right="24px"
      onClick={() => setShow(false)}
    >
      {!hideClose && (
        <Icon
          icon="exitModal"
          fill="gray1"
          hoverfill="gray2"
          zindex={zLayers.base}
        />
      )}
    </Flex>
    {children}
  </S.Modal>
)
