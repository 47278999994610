import styled, { css } from 'styled-components'
import Flex from 'components/priority/Flex/Flex'
import colors from 'utils/styling/colors'

export const Theme = styled(Flex)(
  ({ theme, noHover }) => css`
    border-radius: 4px;
    pointer-events: ${noHover ? 'none' : 'inherit'};
    background-image: url('${process.env
      .REACT_APP_S3BUCKET_THEME_IMAGES}${theme.image}');
    background-size: cover;
  `,
)

export const ThemeOverlay = styled(Flex)(
  () => css`
    background-color: ${colors('gray1')};
    visibility: hidden;
    border-radius: 4px;
    ${Theme}:hover & {
      visibility: visible;
    }
  `,
)

export const ActionButton = styled(Flex)(
  () => css`
    visibility: hidden;
    border-radius: 50%;
    background-color: ${colors('white')};
    ${Theme}:hover & {
      visibility: visible;
    }
    &:hover {
      background-color: ${colors('gray4')};
    }
  `,
)
