import Text from 'components/priority/Text/Text'
import * as S from './Tooltip.styled'
import type { TooltipComponentProps } from 'types/tooltip'

const Tooltip = ({
  text,
  children,
  extraHeight = 0,
  ...props
}: TooltipComponentProps) => {
  return (
    <S.TooltipWrapper center column position="relative" {...props}>
      <S.Tooltip
        padding="6px"
        margin="0 0 8px 0"
        radius="2px"
        background="gray1"
        position="absolute"
        extraHeight={extraHeight}
      >
        <Text color="white" size="12px" weight="500" whiteSpace="nowrap">
          {text}
        </Text>
      </S.Tooltip>
      {children}
    </S.TooltipWrapper>
  )
}

export default Tooltip
