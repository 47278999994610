import startCase from 'lodash/startCase'
import { replaceInitialValues } from 'utils/tinyMCE/replaceInitialValues'

export default (editor, caseDetails, key, templates, dateFormat) => {
  const groupedByType = Object.values(
    caseDetails[key].reduce((acc, obj) => {
      const key = obj.type
      if (!acc[key]) {
        acc[key] = { type: key, items: [] }
      }
      acc[key].items.push(obj)
      return acc
    }, {}),
  )

  groupedByType.forEach((group) => {
    let content = ''

    group.items.forEach((item) => {
      content += `
        <p>
          ${item.name},
          ${item.relation},
          ${item.city},
          ${item.state},
          ${item.country},
        </p><br/>
      `
    })

    templates.push({
      type: 'menuitem',
      text: `${startCase(group.type)}`,
      onAction: () =>
        editor.execCommand(
          'mceInsertTemplate',
          false,
          replaceInitialValues(content, caseDetails),
        ),
    })
  })
}
