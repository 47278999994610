import { css, type DefaultTheme } from 'styled-components'
import type { StyledFunction } from 'types/style'
import zLayers from 'utils/styling/zLayers'

export type TextProps = {
  color?: keyof DefaultTheme | string
  font?: string
  size?: string
  weight?: string | number
  fontStyle?: string
  align?: string
  transform?: string
  decoration?: string
  line?: string | number
  spacing?: string
  zIndex?: number
  whiteSpace?: string
}

const text: StyledFunction<TextProps> = ({
  color,
  font,
  size,
  weight,
  fontStyle,
  align,
  transform,
  decoration,
  line,
  spacing,
  zIndex = zLayers.default,
  whiteSpace = 'pre-wrap',
}) => css`
  color: ${({ theme }) =>
    theme?.[color as keyof DefaultTheme] || color || theme?.gray1};
  font-family: ${font};
  font-size: ${size};
  font-weight: ${weight};
  font-style: ${fontStyle};
  text-align: ${align};
  text-transform: ${transform};
  text-decoration: ${decoration};
  line-height: ${line};
  letter-spacing: ${spacing};
  white-space: ${whiteSpace};
  user-select: none;
  z-index: ${zLayers.getZ(zIndex)};
`

export default text
