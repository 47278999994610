import styled, { css } from 'styled-components'
import Flex from 'components/priority/Flex/Flex'

export const Theme = styled(Flex)(
  ({ theme, layout, main }) =>
    css`
      background-image: url('${process.env
        .REACT_APP_S3BUCKET_THEME_IMAGES}${layout?.replacement_image
        ? layout.replacement_image
        : theme.image}');
      background-size: ${main ? 'cover' : layout.background_fill};
      background-position: ${main
        ? 'center'
        : `${layout.background_top} ${layout.background_left}`};
    `,
)
