import * as S from './Link.styled'
import determineLink from 'utils/global/determineLink'
import { useLocation } from 'react-router-dom'

export default ({ href, target, children, disabled, ...props }) => {
  const { pathname: location } = useLocation()
  let LinkComp
  const linkType = href && determineLink(href)

  switch (linkType) {
    case 'external':
      LinkComp = 'ExternalLink'
      break
    case 'protocol':
      LinkComp = 'ProtocolLink'
      break
    default:
      LinkComp = 'InternalLink'
  }

  const linkProps = {
    internal: {
      to: disabled ? location : href,
    },

    external: {
      href: disabled ? location : href,
      target: target || '_blank',
      rel: 'noopener noreferrer',
    },

    protocol: {
      href: disabled ? location : href,
    },
  }

  const Link = S[LinkComp]

  return href ? (
    <Link {...linkProps[linkType]} {...props}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
}
