import { createPage, updatePage } from 'api/pages'
import { default as _omit } from 'lodash/omit'
import { warningLog } from 'utils/debug/log'

const createPageSetPages = async (props, handleSignal, handleError) => {
  try {
    const { basePages, editorPages, pageSet } = props
    // Validate inputs
    const validationErrors = [
      !Array.isArray(basePages) && 'basePages not array',
      !pageSet?.id && 'missing pageSet.id',
      !basePages?.length && 'empty basePages',
      !editorPages?.length && 'empty editorPages',
    ].filter(Boolean)

    if (validationErrors.length) {
      warningLog(
        `⚠️ createPageSetPages() issues: ${validationErrors.join(', ')}`,
      )
    }

    const sortedBase = basePages.sort((a, b) => a.page_number - b.page_number)

    for (const [index, page] of sortedBase.entries()) {
      const json = editorPages.find(
        (jsonPage) => jsonPage.product.orderPageId === page.id,
      )
      if (!json) {
        continue
      }
      let items = [...(json?.product?.items || [])]

      items = items.map((item) => {
        const newItem = { ...item }
        if (
          newItem?.type === 'image' &&
          newItem?.properties?.subtype === 'caseImage'
        ) {
          newItem.image = {
            url: 'https://via.placeholder.com/163x163',
            edits: {},
          }
          newItem.image = _omit(newItem.image, ['editedUrl'])
        }
        return newItem
      })

      const newJson = _omit(json, [
        'pageProperties.pageSet',
        'pageProperties.pageSetId',
        'product.orderPageId',
        'product.pageId',
        'product.watermarks',
        'product.items',
      ])

      newJson.product.items = items
      newJson.pageProperties.pageSet = pageSet.name
      newJson.pageProperties.pageSetId = pageSet.id
      const newPage = _omit(page, [
        'order_page_set_id',
        'page_set_id',
        'id',
        'json_template',
        'archived',
      ])
      newPage.page_set_id = pageSet.id
      newPage.json_template = JSON.stringify(newJson)
      const { data } = await createPage(
        newPage,
        handleSignal(`createPageSetPages.createPage.${index}`),
      )
      if (data.id) {
        data.json_template.product.pageId = data.id
        const updated = { json_template: JSON.stringify(data.json_template) }
        await updatePage(
          data.id,
          updated,
          handleSignal(`createPageSetPages.updatePage.${index}`),
        )
      }
    }
  } catch (error) {
    handleError(
      error?.response?.data?.message || error.message,
      error,
      null,
      'createPageSetPages',
    )
  }
}

export default createPageSetPages
