import { Link } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import Text from 'components/priority/Text/Text'
import * as S from './Support.styled'

export default () => (
  <Flex full column>
    <Text size="24px" weight="600" margin="0 0 32px">
      Webinars
    </Text>
    <S.WebinarSection column margin="0 0 32px">
      <Flex align="center" margin="0 0 32px">
        <Icon height="16px" width="16px" icon="videoCall" fill="gray1" />
        <Text margin="0 0 0 8px">
          Please click on the link below to schedule a training session with a
          member of our support team.
        </Text>
      </Flex>
      <Text margin="0 0 8px 0">Director's Print Suite Session:</Text>
      <Link href="https://calendly.com/directorsprintsuitetraining/director-s-print-suite-webinar?month=2021-11">
        <Text color="brand" decoration="underline" padding="0 24px 16px 0">
          https://calendly.com/directorsprintsuitetraining/director-s-print-suite-webinar
        </Text>
      </Link>
      <Text margin="0 0 8px 0">Remembrance Registry System Session:</Text>
      <Link href="https://calendly.com/directorsprintsuitetraining/remembrance-registry-system?month=2021-11">
        <Text color="brand" decoration="underline" padding="0 24px 16px 0">
          https://calendly.com/directorsprintsuitetraining/remembrance-registry-system
        </Text>
      </Link>
      <Text weight="bold" color="gray2">
        We can't wait to show you what our software has to offer!
      </Text>
    </S.WebinarSection>
  </Flex>
)
