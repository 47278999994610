import { useState, useEffect } from 'react'
import { Button, Input, AnimatedHeight } from 'components'
import { HexColorPicker } from 'react-colorful'
import * as S from './ColorPicker.styled'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'

export default ({
  color: [color, setColor] = [null, () => {}],
  left,
  children,
  label,
  ...props
}) => {
  const [show, setShow] = useState(false)
  const [internalColor, setInternalColor] = useState('rgba(255, 255, 255, .1)')

  useEffect(() => {
    color && setInternalColor(color)
    !color && setInternalColor('rgba(255, 255, 255, .1)')
  }, [color])

  const selectColor = (selectedcolor) => {
    setShow(false)
    setColor(selectedcolor)
  }

  const cancel = () => {
    setShow(false)
    setInternalColor(color)
  }

  const reset = () => {
    setColor()
    setInternalColor()
  }

  return (
    <Flex full column {...props}>
      {label && (
        <Text size="12px" margin="0 0 4px 0" weight="500">
          {label}
        </Text>
      )}
      <Flex>
        <div
          style={S.ColorPicker(internalColor)}
          onClick={() => setShow(!show)}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          icon="reset"
          onClick={() => reset()}
        />
      </Flex>
      <AnimatedHeight duration={250} height={show ? 'auto' : 0}>
        <Flex column full margin="24px 0 0 0">
          <Flex column center>
            <HexColorPicker color={internalColor} onChange={setInternalColor} />
            <Input
              full
              margin="16px 0 0 0"
              value={[internalColor, setInternalColor]}
            />
          </Flex>
          <Flex margin="16px 0 0 0">
            <Button margin="0 16px 0 0" onClick={() => cancel()}>
              Cancel
            </Button>
            <Button primary onClick={() => selectColor(internalColor)}>
              Save
            </Button>
          </Flex>
        </Flex>
      </AnimatedHeight>
    </Flex>
  )
}
