import * as S from './ResizeControls.styled'

export const ResizeHandleElement = ({
  x,
  y,
  z,
  height,
  width,
  position,
  selected,
  innerRef,
  itemLocked,
  onMouseDown,
}) => {
  return (
    <S.ResizeHandle
      className="no-drag"
      ref={innerRef}
      x={x}
      y={y}
      z={z}
      height={height || 0}
      width={width || 0}
      position={position}
      selected={selected}
      onMouseDown={onMouseDown}
      itemLocked={itemLocked}
    />
  )
}

ResizeHandleElement.displayName = 'ResizeHandleElement'
