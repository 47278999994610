import styled from 'styled-components'
import flex from 'styles/flex'
import sizing from 'styles/sizing'
import spacing from 'styles/spacing'
import positioning from 'styles/positioning'
import borders from 'styles/borders'
import decorations from 'styles/decorations'
import gap from 'styles/gap'
import type { FlexStyledComponent, FlexStyledProps } from 'types/layout'

export const Flex: FlexStyledComponent = styled.div<FlexStyledProps>`
  ${flex}
  ${sizing}
  ${spacing}
  ${positioning}
  ${borders}
  ${decorations}
  ${gap}
`
