import { useState, useRef, useEffect } from 'react'
import {
  itemSelector,
  caseSelector,
  dateFormatAtom,
  rightMenuAtom,
  funeralHomeAtom,
  itemsLockedAtom,
} from 'atoms'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { TinyMCE } from 'components'
import { updateDynamicText } from 'utils'
import * as S from './PageText.styled'
import { useSetToast } from 'hooks'

export default ({
  text: rawText,
  selected,
  dragDisabled: [dragDisabled, setDragDisabled] = [null, () => {}],
  internalHeight: [internalHeight, setInternalHeight] = [0, () => {}],
  setItemHeight,
  itemRef,
  id,
  assetLocked,
  ...props
}) => {
  const [text, setText] = useState('')
  const [editing, setEditing] = useState(false)
  const TinyMCERef = useRef(null)
  const caseDetails = useRecoilValue(caseSelector)
  const funeralHomeDetails = useRecoilValue(funeralHomeAtom)
  const dateFormat = useRecoilValue(dateFormatAtom)
  const itemLocked = useRecoilValue(itemsLockedAtom)
  const setThisItemTextValue = useSetRecoilState(
    itemSelector({ id: id, path: 'text.value' }),
  )
  const activeMenu = useRecoilValue(rightMenuAtom)
  const setToast = useSetToast()

  useEffect(() => {
    const initialHtml = rawText.value
    const dynamicTextProps = {
      initialHtml,
      caseDetails,
      funeralHomeDetails,
      dateFormat,
    }

    const updatedText = updateDynamicText(dynamicTextProps)
    setText(updatedText)
    // eslint-disable-next-line
  }, [caseDetails, funeralHomeDetails, dateFormat])

  useEffect(() => {
    setDragDisabled(editing)
    //eslint-disable-next-line
  }, [editing])

  const updateHeight = () => {
    const updatedHeight = TinyMCERef?.current?.bodyElement?.offsetHeight
    setItemHeight?.(updatedHeight)
    setInternalHeight?.(updatedHeight)
  }

  useEffect(() => {
    TinyMCERef?.current && updateHeight()
    // eslint-disable-next-line
  }, [TinyMCERef?.current])

  useEffect(() => {
    !selected && setEditing(false)
  }, [selected])

  const handleBlur = () => {
    if (TinyMCERef.current) {
      const content = TinyMCERef.current.getContent()
      setThisItemTextValue(content)
      updateHeight()
    }
  }

  const handleDoubleClick = () => {
    if (assetLocked || itemLocked) return
    !editing && TinyMCERef?.current?.editorCommands?.commands?.exec?.selectall()
    setEditing(true)
  }

  return (
    <S.Text
      ref={itemRef}
      selected={selected}
      editing={editing}
      onDoubleClick={() => handleDoubleClick()}
      onBlur={handleBlur}
      {...props}
    >
      <S.TextContainer
        editing={editing}
        cantHighlight={activeMenu !== 'Elements'}
      >
        {text && (
          <TinyMCE
            setToast={setToast}
            setRef={(ref) => (TinyMCERef.current = ref)}
            value={text}
            // TODO: the case doesn't render the details drop down when I pass the converted case?
            caseDetails={caseDetails}
            onKeyUp={updateHeight}
            onExecCommand={updateHeight}
          />
        )}
      </S.TextContainer>
    </S.Text>
  )
}
