import { Check } from 'components'
import { useRecoilState } from 'recoil'
import { caseValuesAtom } from 'atoms'

export default ({ type, label, width, margin }) => {
  const [fieldValue, setFieldValue] = useRecoilState(caseValuesAtom(type))
  if (!fieldValue.id) return null
  return (
    <Check
      margin={margin || '0 0 10px 0'}
      width={width || '100%'}
      align="center"
      checked={fieldValue.value || false}
      onClick={() => setFieldValue({ ...fieldValue, value: !fieldValue.value })}
      text={label}
    />
  )
}
