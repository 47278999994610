import { load } from 'cheerio'
import preserveStyling from 'utils/tinyMCE/updateDynamicText/preserveStyling'
import {
  additionalFamilyTemplate,
  firstLetterTemplate,
  memorialContributionTemplate,
  organizationTemplate,
  serviceTemplate,
  yearTemplate,
} from 'utils/tinyMCE/templates'
import preserveItalicsAndBolds from 'utils/tinyMCE/updateDynamicText/preserveItalicsAndBolds'

export default (dynamicTextProps) => {
  const { textTypeProps, elementProps, editorProps } = dynamicTextProps

  const { element, properties } = elementProps
  const { key } = textTypeProps

  const templateProps = {
    editorProps,
    templateProps: properties,
  }

  const templateHtml = templateLegend[key](templateProps)
  const templateElement = load(templateHtml)

  preserveStyling({ initialElement: element, templateElement })

  preserveItalicsAndBolds({
    initialElement: element,
    targetElement: templateElement,
    targetTag: 'p',
  })

  element.replaceWith(templateElement.html())
}

const templateLegend = {
  additional_family: additionalFamilyTemplate,
  memorial_contributions: memorialContributionTemplate,
  organizations: organizationTemplate,
  service: serviceTemplate,
  date_of_birth_year: yearTemplate,
  date_of_death_year: yearTemplate,
  first_dec_first_letter: firstLetterTemplate,
  middle_dec_first_letter: firstLetterTemplate,
  lastdec_first_letter: firstLetterTemplate,
}
