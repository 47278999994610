type APIDebugContext = {
  componentName?: string
  componentFunction?: string
  clientAPIFunction?: string
  clientAPIDirectory?: string
  APIConfig?: any
  APIResponse?: any
  APIError?: any
  isAPIRequest?: boolean
}

type DebugFlags = {
  debug: boolean
  warning: boolean
  mounting: boolean
  apiRequests: boolean
  apiResponses: boolean
  apiErrors: boolean
}

export const DEBUG_FLAGS: DebugFlags = {
  debug: !!process.env.REACT_APP_DEBUG_LOGGING,
  warning: !!process.env.REACT_APP_DEBUG_WARNINGS,
  mounting: !!process.env.REACT_APP_DEBUG_MOUNT_UNMOUNT,
  apiRequests: !!process.env.REACT_APP_DEBUG_REQUEST_API_CALLS,
  apiResponses: !!process.env.REACT_APP_DEBUG_RESPONSE_API_CALLS,
  apiErrors: !!process.env.REACT_APP_DEBUG_ERROR_API_CALLS,
} as const

const usingDebugFlags = Object.values(DEBUG_FLAGS).some((flag) => flag)

usingDebugFlags &&
  console.log(
    '\n' +
      `🛠️  DEBUG FLAGS ENABLED 🛠️\n` +
      '============================\n' +
      `🔍 Debug Logging:         ${DEBUG_FLAGS.debug ? '✅' : '❌'}\n` +
      `🔔 Warning Logging:       ${DEBUG_FLAGS.warning ? '✅' : '❌'}\n` +
      `🚨 Error Logging:          ✅\n` + // should always be on
      `🌐 API Request Logging:   ${DEBUG_FLAGS.apiRequests ? '✅' : '❌'}\n` +
      `🌐 API Response Logging:  ${DEBUG_FLAGS.apiResponses ? '✅' : '❌'}\n` +
      `🌐 API Error Logging:     ${DEBUG_FLAGS.apiErrors ? '✅' : '❌'}\n` +
      `⚡️ Mount/Unmount Debug:    ${DEBUG_FLAGS.mounting ? '✅' : '❌'}\n` +
      '============================\n',
  )
/**
 * If DEBUG_API_CALLS env var is true passed context will be logged
 * @link axios.config - axios request/response/error logging
 * @param context - APIDebugContext
 * @returns void
 */
export const logAPI = {
  // e.g. axios.config
  request: (context: APIDebugContext) => {
    if (!DEBUG_FLAGS.apiRequests) return
    console.debug(
      `🔍 API Request\n` +
        `========================\n` +
        `📦 Component: [${context?.componentName || '⚠️ Unknown'}]\n` +
        `⚡️ Component Function: ${
          context?.componentFunction || '⚠️ Unknown'
        }\n` +
        `📝 API Function: ${context?.clientAPIFunction || '⚠️ Unknown'}\n` +
        `📁 API Directory: ${context?.clientAPIDirectory || '⚠️ Unknown'}\n` +
        `🔗 Endpoint: ${context?.APIConfig?.method?.toUpperCase()} ${
          context?.APIConfig?.url
        }\n` +
        `⏱️ Timestamp: ${new Date().toISOString()}\n` +
        `⚙️ Entire Config click to expand: 👇`,
    )
    console.dir(context?.APIConfig)
  },
  // e.g. axios.config
  response: (context: APIDebugContext) => {
    if (!DEBUG_FLAGS.apiResponses) return
    console.debug(
      `✅ API Response\n` +
        `==================\n` +
        `📦 Component: [${context?.componentName || '⚠️ Unknown'}]\n` +
        `⚡️ Component Function: ${
          context?.componentFunction || '⚠️ Unknown'
        }\n` +
        `📝 API Function: ${context?.clientAPIFunction || '⚠️ Unknown'}\n` +
        `📁 API Directory: ${context?.clientAPIDirectory || '⚠️ Unknown'}\n` +
        `🔗 Endpoint: ${context?.APIResponse?.config?.method?.toUpperCase()} ${
          context?.APIResponse?.config?.url
        }\n` +
        `📊 Status: ${context?.APIResponse?.status} ${context?.APIResponse?.statusText}\n` +
        `⏱️ Timestamp: ${new Date().toISOString()}\n` +
        `⚙️ Entire Response click to expand: 👇`,
    )
    console.dir(context?.APIResponse)
  },
  // e.g. axios.config
  error: (context: APIDebugContext) => {
    if (!DEBUG_FLAGS.apiErrors) return
    console.error(
      `❌ API ${context?.isAPIRequest ? 'Request' : 'Response'} Error\n` +
        `==================\n` +
        `📦 Component: [${context?.componentName || '⚠️ Unknown'}]\n` +
        `⚡️ Component Function: ${
          context?.componentFunction || '⚠️ Unknown'
        }\n` +
        `📝 API Function: ${context?.clientAPIFunction || '⚠️ Unknown'}\n` +
        `📁 API Directory: ${context?.clientAPIDirectory || '⚠️ Unknown'}\n` +
        `🔗 Endpoint: ${context?.APIError?.config?.method?.toUpperCase()} ${
          context?.APIError?.config?.url
        }\n` +
        `❗️ Status: ${context?.APIError?.response?.status} ${context?.APIError?.response?.statusText}\n` +
        `💬 Message: ${context?.APIError?.message}\n` +
        `⏱️ Timestamp: ${new Date().toISOString()}\n` +
        `⚙️ Entire Error click to expand: 👇`,
    )
    console.dir(context?.APIError)
  },
}

export const debugLog = (debugText: string) => {
  debugText && DEBUG_FLAGS.debug && console.debug(debugText)
}

export const warningLog = (warningText: string) => {
  warningText && DEBUG_FLAGS.warning && console.warn(warningText)
}

export const errorLog = (errorText: string, error: any) => {
  errorText && console.error(errorText, error)
}
