import { Slider } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { useRecoilValue, useRecoilState } from 'recoil'
import { selectedItemAtom, itemSelector } from 'atoms'

export default () => {
  //TODO write an exception in the getter and setter for multiply/divide 100
  const selectedItem = useRecoilValue(selectedItemAtom)
  const [opacity, setOpacity] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.opacity' }),
  )

  return (
    <Flex column padding="0 12px">
      <Text>Opacity</Text>
      <Slider
        sliderState={[opacity, setOpacity]}
        min="0"
        max="100"
        margin="0 0 20px"
        initialValue={100}
      />
    </Flex>
  )
}
