import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil'
import { selectedItemAtom, itemSelector, itemAtomFamily } from 'atoms'
import { Button, ColorPicker, Input, Clipboard } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { useDuplicateItem } from 'hooks'
import { LockAsset } from 'components/editor/PropertiesMenu/LockAsset'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const duplicateItem = useDuplicateItem()

  const [width, setWidth] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'width' }),
  )
  const [height, setHeight] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'height' }),
  )
  const [color, setColor] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.backgroundColor' }),
  )

  const deleteItem = useResetRecoilState(itemAtomFamily(selectedItem))

  return (
    <Flex column padding="0 12px">
      <Button center margin="0 0 16px" onClick={duplicateItem}>
        Duplicate Line
      </Button>
      <Button center margin="0 0 16px" onClick={deleteItem}>
        Delete Line
      </Button>
      <Clipboard />
      <LockAsset selectedItem={selectedItem} />
      <Text margin="0 0 16px">Size</Text>
      <Flex margin="0 0 24px">
        <Input
          value={[width, setWidth]}
          label="Width (px)"
          margin="0 16px 0 0"
          placeholder="100"
          type="number"
        />
        <Input
          value={[height, setHeight]}
          label="Height (px)"
          placeholder="100"
          type="number"
        />
      </Flex>
      <Text margin="0 0 16px">Line fill color</Text>
      <ColorPicker
        label="Color"
        margin="0 0 20px"
        color={[color, setColor]}
        resetButton
      />
    </Flex>
  )
}
