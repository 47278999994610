import { replaceInitialValues } from 'utils/tinyMCE/replaceInitialValues'
import { caseDetailSpan } from '.'

export default (editor, caseDetails, key, templates, dateFormat) => {
  let content = ''
  caseDetails[key].forEach((_, i) => {
    content += `
      <p>
        ${caseDetailSpan(`${key}[${i}].name`)}, 
        ${caseDetailSpan(`${key}[${i}].sequence`)}, 
        ${caseDetailSpan(`${key}[${i}].address1`)}, 
        ${caseDetailSpan(`${key}[${i}].address2`)}, 
        ${caseDetailSpan(`${key}[${i}].city`)}, 
        ${caseDetailSpan(`${key}[${i}].state`)}, 
        ${caseDetailSpan(`${key}[${i}].zip_code`)}, 
        ${caseDetailSpan(`${key}[${i}].country`)}, 
        ${caseDetailSpan(`${key}[${i}].website_url`)}
      </p><br/>
      `
  })

  templates.push({
    type: 'menuitem',
    text: 'Organizations',
    onAction: () =>
      editor.execCommand(
        'mceInsertTemplate',
        false,
        replaceInitialValues(content, caseDetails),
      ),
  })
}
