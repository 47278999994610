import styled, { css } from 'styled-components'
import Flex from 'components/priority/Flex/Flex'
import colors from 'utils/styling/colors'

export const Tab = styled(Flex)(
  ({ selected }) => css`
    background-color: ${selected && colors('paleSecondary')};
    &:hover {
      background-color: ${colors('paleSecondary')};
    }
  `,
)
