import { BrowserRouter as Router } from 'react-router-dom'
import Triggers from './Triggers'
import { Route } from './RouteTypes'
import {
  Admin,
  Analytics,
  Assets,
  Cases,
  Dashboard,
  Editor,
  FamilyUpload,
  Landing,
  Login,
  Package,
  AdminProducts,
  Settings,
  Showcase,
  StatusMessage,
  Support,
  Themes,
  PdfRender,
  AlignmentSheet,
  UnderConstructionPage,
  CustomThemes,
  CustomThemeEditorWrapper,
  SignUp,
  Launch,
} from 'components'

import Preview from 'components/package/Preview'

/**
 * Application routing configuration component.
 * Defines all available routes in the application using react-router-dom.
 *
 * Routes are organized into several sections:
 * - Public routes (landing, login, sign-up)
 * - Dashboard routes (main user interface)
 * - Package and Preview routes
 * - Editor routes
 * - Custom Theme routes
 * - Admin routes (protected administrative functions)
 * - PDF generation routes
 * - Utility routes (under construction, family upload)
 * - and Triggers component for global event listeners
 *
 * @component
 * @returns Router component with all application routes
 */
const Routes = () => (
  <Router>
    {/* landing */}
    <Route exact path="/" component={Landing} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/sign-up" component={SignUp} />

    {/* dashboard */}
    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/cases" component={Cases} />
    <Route exact path="/settings" component={Settings} />
    <Route exact path="/support/:tab" component={Support} />

    {/* package */}
    <Route path="/package" component={Package} />

    <Route path="/preview/:caseId" component={Preview} />

    {/* editor */}
    <Route exact path="/editor/:id" component={Editor} />

    {/* Custom Themes */}
    <Route exact path="/customThemes" component={CustomThemes} />
    <Route
      exact
      path="/customThemes/:id"
      component={CustomThemeEditorWrapper}
    />

    {/* dev */}
    <Route exact path="/showcase" component={Showcase} />

    {/* admin */}
    <Route exact path="/admin" component={Admin} />
    <Route exact path="/admin/analytics" component={Analytics} />
    <Route exact path="/admin/assets" component={Assets} />
    <Route exact path="/admin/products" component={AdminProducts} />
    <Route exact path="/admin/status-message" component={StatusMessage} />
    <Route exact path="/admin/themes" component={Themes} />
    <Route exact path="/admin/launch" component={Launch} />

    {/* pdf */}
    <Route exact path="/pdfProduct" component={PdfRender} />
    <Route exact path="/pdfAlign" component={AlignmentSheet} />

    {/* Under Construction */}
    <Route exact path="/under-construction" component={UnderConstructionPage} />

    {/* Family Upload */}
    <Route exact path="/familyUpload/:token/:caseId" component={FamilyUpload} />

    {/* always present */}
    <Triggers />
  </Router>
)

export default Routes
