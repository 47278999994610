import { Button, Input, Clipboard } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import {
  useRecoilValue,
  useRecoilState,
  useSetRecoilState,
  useResetRecoilState,
} from 'recoil'
import {
  selectedItemAtom,
  showPinturaAtom,
  itemSelector,
  itemAtomFamily,
} from 'atoms'
import { useDuplicateItem } from 'hooks'
import { LockAsset } from 'components/editor/PropertiesMenu/LockAsset'
import { MaintainAspectRatio } from 'components/editor/PropertiesMenu/MaintainAspectRatio'
import { isEmpty } from 'lodash'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const duplicateItem = useDuplicateItem()

  const [width, setWidth] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'width' }),
  )
  const [height, setHeight] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'height' }),
  )
  const [circle, setCircle] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.circle' }),
  )
  const [imageEdits, setImageEdits] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'image.edits' }),
  )
  const deleteItem = useResetRecoilState(itemAtomFamily(selectedItem))
  const setShowPintura = useSetRecoilState(showPinturaAtom)

  const toggleShape = (shape) => setCircle(shape === 'circle')
  const showPinturaModal = () => setShowPintura(true)

  const setImageEditsState = (value, type) => {
    if (isEmpty(imageEdits)) return
    const imageEditsTmp = { ...imageEdits }
    const updatedWidth = {
      width: type === 'width' ? value : imageEditsTmp.targetSize.width,
      height: type === 'height' ? value : imageEditsTmp.targetSize.height,
    }
    imageEditsTmp.targetSize = updatedWidth
    setImageEdits(imageEditsTmp)
  }

  const setWidthSize = (value) => {
    if (value) {
      setWidth(value)
      setImageEditsState(value, 'width')
    }
  }

  const setHeightSize = (value) => {
    if (value) {
      setHeight(value)
      setImageEditsState(value, 'height')
    }
  }

  const roundedWidth = Math.round(width)
  const roundedHeight = Math.round(height)

  return (
    <Flex column padding="0 12px">
      <Button center margin="0 0 16px" onClick={duplicateItem}>
        Duplicate Image
      </Button>
      <Button center margin="0 0 16px" onClick={deleteItem}>
        Delete Image
      </Button>
      <Button center margin="0 0 16px" onClick={showPinturaModal}>
        Adjust Image
      </Button>
      <Clipboard />
      <LockAsset selectedItem={selectedItem} />
      <MaintainAspectRatio selectedItem={selectedItem} />
      <Text margin="0 0 16px">Size</Text>
      <Flex margin="0 0 24px">
        <Input
          value={[roundedWidth, setWidthSize]}
          label="Width (px)"
          margin="0 16px 0 0"
          type="number"
        />
        <Input
          value={[roundedHeight, setHeightSize]}
          label="Height (px)"
          type="number"
        />
      </Flex>
      <Flex column margin="0 0 16px">
        <Text margin="0 0 16px">Shape</Text>
        <Flex>
          <Button
            width="42px"
            height="42px"
            padding="0 0 0 4px"
            margin="4px"
            icon="editorSquareImage"
            disabled={!circle}
            onClick={() => toggleShape('square')}
          />
          <Button
            width="42px"
            height="42px"
            padding="0 0 0 4px"
            margin="4px"
            icon="editorOvalImage"
            disabled={circle}
            onClick={() => toggleShape('circle')}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
