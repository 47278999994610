import axios from 'api/axios.config'
import sanitizeCaseDates from 'utils/sanitizers/sanitizeCaseDates'

export const getOrderPageDetails = async (order_pageset_id) => {
  try {
    const response = await axios.get(
      `orderPageDetails?order_page_set_id=${order_pageset_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

export const getEditorOrderPageDetails = async (order_pageset_id) => {
  try {
    const response = await axios.get(
      `editorOrderPageDetails?order_page_set_id=${order_pageset_id}`,
    )
    response.data.case = sanitizeCaseDates(response.data.case)
    return response
  } catch (error) {
    throw error
  }
}

export const createOrderPage = async (payload, options = {}) => {
  try {
    const response = await axios.post(`createOrderPageDetails`, payload, {
      ...options,
      signal: options?.signal,
      debug: {
        ...options?.debug,
        clientAPIFunction: 'createOrderPage',
        clientAPIDirectory: 'orderPages',
      },
    })
    return response
  } catch (error) {
    throw error
  }
}

export const updateOrderPage = async (order_page_id, payload) => {
  try {
    const response = await axios.patch(
      `orderPageDetails/${order_page_id}`,
      payload,
    )
    return response
  } catch (error) {
    throw error
  }
}

export const archiveOrderPage = async (
  order_page_id,
  archived = false,
  options = {},
) => {
  try {
    const response = await axios.patch(
      `archiveOrderPageDetails/${order_page_id}`,
      { archived },
      {
        ...options,
        signal: options?.signal,
        debug: {
          ...options?.debug,
          clientAPIFunction: 'archiveOrderPage',
          clientAPIDirectory: 'orderPages',
        },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}
