import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import find from 'lodash/find'
import type { NestedObject, NestedValue } from 'types/global'

type Dropdown = {
  selectText: string
  items: { text: string; onClick: () => void }[]
}

const determineDropdown = (
  items: NestedValue,
  valueKey: string,
  textKey: string,
  useKeyAsValue: boolean,
  selected: string,
  setSelected: (value: string) => void,
  customLayout: boolean,
) => {
  let dropdown: Dropdown = {
    selectText: '',
    items: [],
  }

  const itemsAreArray = isArray(items)
  const itemsAreObject = isObject(items) && !itemsAreArray

  const selectItem = (text: string, onClick: string) => ({
    text,
    onClick: () => setSelected(onClick),
  })

  // items are an object and keys are being used as the value
  if (itemsAreObject && useKeyAsValue) {
    dropdown = {
      selectText: String((items as NestedObject)[selected]),
      items:
        items &&
        Object.entries(items)?.map(([key, value]) => selectItem(value, key)),
    }

    // items are an object and the value is being used as the value
  } else if (itemsAreObject) {
    dropdown = {
      selectText: selected,
      items:
        items && Object.values(items)?.map((item) => selectItem(item, item)),
    }

    // items are an array of objects, and there are keys for both the text and the values
  } else if (itemsAreArray && customLayout) {
    dropdown = {
      selectText: find(items as Record<string, any>[], {
        [valueKey]: selected,
      })?.[textKey],
      items: (items as Record<string, any>[])?.map((item) =>
        selectItem(
          `${item?.[textKey]}${item?.default ? ' - default' : ''}`,
          item?.[valueKey],
        ),
      ),
    }
  } else if (itemsAreArray && valueKey && textKey) {
    dropdown = {
      selectText: find(items as Record<string, any>[], {
        [valueKey]: selected,
      })?.[textKey],
      items: (items as Record<string, any>[])?.map((item) =>
        selectItem(item?.[textKey], item?.[valueKey]),
      ),
    }
    // items are an array of objects, and there is a single key for the values
  } else if (itemsAreArray && valueKey) {
    dropdown = {
      selectText: find(items as Record<string, any>[], {
        [valueKey]: selected,
      })?.[valueKey],
      items: (items as Record<string, any>[])?.map((item) =>
        selectItem(item?.[valueKey], item?.[valueKey]),
      ),
    }

    // items are an array of objects, but the user has only put in the text key
  } else if (itemsAreArray && textKey && !valueKey) {
    dropdown = {
      selectText: find(items as Record<string, any>[], {
        [textKey]: selected,
      })?.[textKey],
      items: (items as Record<string, any>[])?.map((item) =>
        selectItem(item?.[textKey], item?.[textKey]),
      ),
    }

    // items are an array of strings
  } else {
    dropdown = {
      selectText: selected,
      items: (items as string[])?.map((item) => selectItem(item, item)),
    }
  }

  return dropdown
}

export default determineDropdown
