import { registerTextCasingPlugin, registerTextOutlinePlugin } from 'utils'
import highlightDynamicTextPlugin from 'utils/tinyMCE/plugins/highlightDynamicTextPlugin'
import type { EditorProps } from 'types/tinyMCE'

const loadLocalPlugins = (editorProps: EditorProps) => {
  const { editor } = editorProps

  editor.editorManager.PluginManager.add(
    'casedetails',
    function (editor: Editor) {
      const pluginProps = { ...editorProps, editor }
      registerTextCasingPlugin(pluginProps)
      registerTextOutlinePlugin(pluginProps)
      highlightDynamicTextPlugin(pluginProps)
    },
  )
}

export default loadLocalPlugins
