import styled from 'styled-components'
import colors from 'utils/styling/colors'

export const Slider = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: calc(100% - 80px);
  height: 2px;
  background: ${colors('gray4')};
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: ${colors('brand')};
    cursor: pointer;
  }
  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: ${colors('brand')};
    cursor: pointer;
  }
`
