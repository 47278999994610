import { TableCell } from 'components'
import * as S from './Table.styled'
import Icon from 'components/priority/Icon/Icon'
import Tooltip from 'components/global/Tooltip/Tooltip'

export default ({ loaded, actions }) => (
  <TableCell loaded={loaded}>
    <S.Actions>
      {actions.map(({ text, icon, onClick, disabled = false }, i) => (
        <Tooltip text={text} key={i}>
          <Icon
            icon={icon}
            onClick={() => onClick?.()}
            fill="brand"
            margin="8px"
            clickable
            disabled={disabled}
          />
        </Tooltip>
      ))}
    </S.Actions>
    <S.Ellipses>
      <Icon icon="ellipses" fill="gray3" margin="8px" />
    </S.Ellipses>
  </TableCell>
)
