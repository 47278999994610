import { default as _get } from 'lodash/get'
import type { HTMLString, NestedObject, NestedValue } from 'types/global'
import formatDate from 'utils/dates/formatDate'

// this utility replaces any values between squiggly brackets: {$VALUE}
// with its corresponding value determined by the "keys" object

// finds all values with squiggly brackets and a dollar sign: {$VALUE}
const itemRegEx = /{\$([^}]*)}/g as RegExp
// extracts the value between the squiggly brackets
const keySubstring = (key: string): string => key.substring(2, key.length - 1)

// replaces the regEx with keys from an object
const replaceItems = (html: HTMLString, keys: NestedObject): string =>
  html?.replace(itemRegEx, (key) => {
    const value: NestedValue = _get(keys, keySubstring(key))
    if (!value) return ''
    if (typeof value === 'string') return value
    return String(value)
  })

const replaceItemsWithDates = (
  html: HTMLString,
  keys: NestedObject,
  dateFormat: string,
): string => {
  const dates = [
    'date_of_birth',
    'date_of_death',
    'date_of_marriage',
    'interment_date',
    'date_entered_service',
    'date_discharged_from_service',
  ]

  return html?.replace(itemRegEx, (key) => {
    let value
    if (dates.includes(keySubstring(key))) {
      const dateValue = _get(keys, keySubstring(key)) || ''
      value =
        dateValue instanceof Date || typeof dateValue === 'string'
          ? formatDate(dateValue, dateFormat, false)
          : dateValue || ''
    } else {
      value = _get(keys, keySubstring(key))
    }
    if (!value) return ''
    if (typeof value === 'string') return value
    return String(value)
  })
}

export const replaceInitialValues = (
  content: HTMLString,
  keys: NestedObject,
): string => {
  try {
    return replaceItems(content, keys)
  } catch (error) {
    console.error('Error replaceInitialValues:', error)
    return ''
  }
}

export const replaceInitialValuesAndDates = (
  content: HTMLString,
  keys: NestedObject,
  dateFormat: string,
): string => {
  try {
    return replaceItemsWithDates(content, keys, dateFormat)
  } catch (error) {
    console.error('Error replaceInitialValuesAndDates:', error)
    return ''
  }
}
