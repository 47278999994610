import * as S from './Radio.styled'
import Text from 'components/priority/Text/Text'
import Flex from 'components/priority/Flex/Flex'

export default ({
  label,
  value: [value, setValue] = ['', null],
  options,
  color,
  ...props
}) => (
  <S.Check clickable {...props}>
    {label && (
      <Text align="center" weight="bold" margin="0 0 24px">
        {label}
      </Text>
    )}

    {options.map((option) => (
      <Flex onClick={() => setValue(option)} key={option} margin="0 0 24px">
        <Flex
          center
          width="20px"
          height="20px"
          radius="50%"
          background="transparent"
          border="1px solid"
          borderColor="gray4"
          text={option}
        >
          {value === option && (
            <S.Fill width="8px" height="8px" radius="50%" background="gray1" />
          )}
        </Flex>
        <Text color={color} margin="0 0 0 8px">
          {option}
        </Text>
      </Flex>
    ))}
  </S.Check>
)
