import { HiddenMenu, Button, Modal } from 'components'
import Icon from 'components/priority/Icon/Icon'

export default ({
  text,
  icon,
  items,
  left,
  margin,
  children,
  outsideClickType = 'mousedown',
  show: [show, setShow] = [false, () => {}],
  ...props
}) => (
  <HiddenMenu
    show={[show, setShow]}
    left={left}
    margin={margin}
    outsideClickType={outsideClickType}
    parentElement={() => (
      <Button {...props} onClick={() => setShow(!show)}>
        {text}
        {icon && <Icon icon={icon} margin="0 0 0 8px" />}
      </Button>
    )}
    hiddenElement={() => (
      <Modal items={items} show={[show, setShow]}>
        {children}
      </Modal>
    )}
  />
)
