import { DayPicker } from 'react-day-picker'
import * as S from './Calendar.styled'
import { CalendarNavigation } from './CalendarNavigation'
import zLayers from 'utils/styling/zLayers'

export default ({
  date: [date, setDate] = [null, () => {}],
  show: [, setShow] = [null, () => {}],
  disabledDays,
}) => {
  const handleSelection = (day) => {
    if (!day) {
      setShow(false)
      return
    }
    if (!date || day?.getTime() !== date?.getTime()) {
      setDate(day)
      setShow(false)
    }
  }
  return (
    <S.Calendar zindex={zLayers.base}>
      <DayPicker
        mode="single"
        selectedDays={date}
        month={date}
        onMonthChange={setDate}
        selected={date}
        onSelect={handleSelection}
        disabledDays={disabledDays}
        components={{
          Nav: CalendarNavigation,
        }}
      />
    </S.Calendar>
  )
}
