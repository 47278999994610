export default (editorProps) => {
  const { editor, setToast } = editorProps

  if (!setToast) return

  const hasAttribute = (element, attr) =>
    element.hasAttribute?.(attr) ||
    element.querySelector?.(`[${attr}]`) ||
    element.closest?.(`[${attr}]`)
  editor.on('click', (e) => {
    const { target } = e
    const attributes = [
      'data-case-detail',
      'data-case-detail-table',
      'data-template',
      'data-template-item',
    ]
    if (attributes.some((attr) => hasAttribute(target, attr))) {
      setToast({
        icon: 'info',
        text: 'Edit templated text in the case tab sidebar.',
      })
    }
  })
}
