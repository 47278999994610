import Flex from 'components/priority/Flex/Flex'
import styled from 'styled-components'
import colors from 'utils/styling/colors'

export const Tag = styled(Flex)`
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  background: ${colors('secondary')};
  color: ${colors('white')};
  border-radius: 3px;
  padding: 0.2rem 0.5rem;
`
