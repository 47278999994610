import { forwardRef } from 'react'
import * as S from './Flex.styled'
import type { FlexComponentProps } from 'types/layout'

const Flex = forwardRef<HTMLDivElement, FlexComponentProps>(
  ({ children, ...props }, ref) => (
    <S.Flex ref={ref} {...props}>
      {children}
    </S.Flex>
  ),
)

export default Flex
