import axios from 'api/axios.config'

export const getProductsByCollection = async (collection_id) => {
  try {
    const response = await axios.get(`products?collection_id=${collection_id}`)
    return response
  } catch (error) {
    throw error
  }
}
