import { useEffect, useMemo } from 'react'
import {
  useFlexLayout,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
  usePagination,
} from 'react-table'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { TableControls, TableCheck } from './'
import * as S from './Table.styled'

export default ({
  columns,
  data,
  getHeaderProps = () => ({}),
  getColumnProps = () => ({}),
  getRowProps = () => ({}),
  getCellProps = () => ({}),
  searchPlaceholder,
  setSelectedRows,
  showCheckboxes,
  showingArchived,
  toggleArchived,
  defaultClick,
}) => {
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    [],
  )

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    preGlobalFilteredRows,
    prepareRow,
    page,
    setGlobalFilter,
    selectedFlatRows,
    state,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
      },
    },
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,

    // Checkbox column
    (hooks) => {
      const columnWidth = showCheckboxes ? 32 : 0
      hooks.allColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          disableResizing: true,
          minWidth: columnWidth,
          width: columnWidth,
          maxWidth: columnWidth,

          // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps: checkAllRows }) => (
            <TableCheck show={showCheckboxes} {...checkAllRows()} />
          ),

          // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
          Cell: ({ row: { getToggleRowSelectedProps: checkSingleRow } }) => (
            <TableCheck show={showCheckboxes} {...checkSingleRow()} />
          ),
        },
        ...columns,
      ])
    },
  )

  useEffect(() => {
    setSelectedRows?.(selectedFlatRows)
    // eslint-disable-next-line
  }, [selectedFlatRows])

  // Render the UI for your table
  return (
    <>
      <S.Table defaultClick={defaultClick}>
        <TableControls
          getTableProps={getTableProps}
          nextPage={nextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          preGlobalFilteredRows={preGlobalFilteredRows}
          previousPage={previousPage}
          searchPlaceholder={searchPlaceholder}
          setGlobalFilter={setGlobalFilter}
          showingArchived={showingArchived}
          state={state}
          toggleArchived={toggleArchived}
        />

        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => {
              const headerGroupProps = headerGroup.getHeaderGroupProps()
              const { key: headerGroupKey, ...restHeaderGroupProps } =
                headerGroupProps

              return (
                <tr key={headerGroupKey} {...restHeaderGroupProps}>
                  {headerGroup.headers.map((column) => {
                    const headerProps = column.getHeaderProps([
                      getColumnProps(column),
                      getHeaderProps(column),
                      { style: column.style },
                    ])
                    const { key: headerKey, ...restHeaderProps } = headerProps

                    return (
                      <th key={headerKey} {...restHeaderProps}>
                        <Flex {...column.getSortByToggleProps()} center>
                          <Text>{column.render('Header')}</Text>
                          {column.canSort && (
                            <S.HeaderArrow
                              up={column.isSortedDesc}
                              icon="arrowDown"
                              margin="0 0 0 8px"
                              fill={column.isSorted ? 'gray2' : 'gray3'}
                              sorted={column.isSorted ? 1 : 0}
                            />
                          )}
                        </Flex>
                      </th>
                    )
                  })}
                </tr>
              )
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              const rowProps = row.getRowProps(getRowProps(row))
              const { key: rowKey, ...restRowProps } = rowProps
              return (
                <tr key={rowKey} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const nonClickableCell =
                      cell.column.id !== 'Actions' &&
                      cell.column.id !== 'selection' &&
                      defaultClick
                    const cellProps = cell.getCellProps([
                      {
                        style: {
                          ...cell.column.style,
                          cursor: nonClickableCell && 'pointer',
                        },
                        onClick: () =>
                          nonClickableCell && defaultClick?.(row, cell),
                      },
                      getColumnProps(cell.column),
                      getCellProps(cell),
                    ])
                    const { key: cellKey, ...restCellProps } = cellProps
                    return (
                      <td key={cellKey} {...restCellProps}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </S.Table>
    </>
  )
}
