import { useRef } from 'react'
import { useOutsideClick } from 'hooks'
import { Modal } from 'components'
import * as S from './FullscreenModal.styled'

export default ({
  children,
  show: [show, setShow] = [false, () => {}],
  hideClose,
  ...props
}) => {
  const modal = useRef(null)
  useOutsideClick(modal, show, () => !hideClose && setShow(false), 'mousedown')

  return (
    <S.Wrapper show={show} backdrop {...props}>
      <S.RefWrapper ref={modal} show={show}>
        <Modal
          position="relative"
          bottom={show ? '0' : '12px'}
          show={[show, setShow]}
          hideClose={hideClose}
        >
          {children}
        </Modal>
      </S.RefWrapper>
    </S.Wrapper>
  )
}
