import Icon from 'components/priority/Icon/Icon'

type CalendarNavigationProps = {
  onNextClick?: (e: React.MouseEvent) => void
  onPreviousClick?: (e: React.MouseEvent) => void
}

export const CalendarNavigation = ({
  onNextClick,
  onPreviousClick,
}: CalendarNavigationProps) => (
  <nav className="rdp-nav" aria-label="Calendar month navigation">
    <button
      className="rdp-button_previous"
      aria-label="Go to the previous month"
      onClick={(e) => {
        e.stopPropagation()
        onPreviousClick?.(e)
      }}
    >
      <Icon icon="chevronLeft" fill="gray2" hoverfill="gray1" />
    </button>
    <button
      className="rdp-button_next"
      aria-label="Go to the next month"
      onClick={(e) => {
        e.stopPropagation()
        onNextClick?.(e)
      }}
    >
      <Icon icon="chevronRight" fill="gray2" hoverfill="gray1" />
    </button>
  </nav>
)
