import axios from 'api/axios.config'
import sanitizeDashboardDates from 'utils/sanitizers/sanitizeDashboardDates'
import sanitizeCaseDates from 'utils/sanitizers/sanitizeCaseDates'

// let current_case = {};
// const case_custom_fields = {}

export const getFuneralHomeCases = async (archived) => {
  try {
    const response = await axios.get(`/funeralHomeCases?archived=${archived}`)
    response.data.cases = sanitizeDashboardDates(response.data.cases)
    return response
  } catch (error) {
    throw error
  }
}

//we used to convert dates on the fetch, but this messes with the dateInput, convert dates elsewhere?
export const getCase = async (case_id) => {
  try {
    const response = await axios.get(`cases/${case_id}`)
    response.data = sanitizeCaseDates(response.data)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const getCaseAsGuest = async (token) => {
  try {
    const response = await axios.get(`caseAsGuest/${token}`)
    response.data = sanitizeCaseDates(response.data)
    return response
  } catch (error) {
    throw error
  }
}

// see case payload details in readme file
export const createCase = async (payload) => {
  try {
    const response = await axios.post(`createCase`, payload)
    response.data = sanitizeCaseDates(response.data)
    return response
  } catch (error) {
    throw error
  }
}

export const updateCase = async (case_id, payload) => {
  try {
    const response = await axios.patch(`cases/${case_id}`, payload)
    response.data = sanitizeCaseDates(response.data)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const archiveCase = async (case_id) => {
  try {
    const response = await axios.patch(`archiveCase/${case_id}`)
    response.data = sanitizeCaseDates(response.data)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const unarchiveCase = async (case_id) => {
  try {
    const response = await axios.patch(`unarchiveCase/${case_id}`)
    response.data = sanitizeCaseDates(response.data)
    return response
  } catch (error) {
    throw error
  }
}

export const uploadCaseImage = async (case_id, payload) => {
  try {
    const response = await axios.post(`uploadCaseImage/${case_id}`, payload, {
      headers: { 'content-type': 'form-data' },
    })
    return response
  } catch (error) {
    throw error
  }
}

export const uploadOptimizedCaseImage = async (case_id, payload) => {
  try {
    const response = await axios.post(
      `uploadOptimizedCaseImage/${case_id}`,
      payload,
      {
        headers: { 'content-type': 'form-data' },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const uploadCaseImageAsGuest = async (token, payload) => {
  try {
    const response = await axios.post(
      `uploadCaseImageAsGuest/${token}`,
      payload,
      {
        headers: { 'content-type': 'form-data' },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

export const deleteCaseImage = async (case_id, payload) => {
  try {
    const response = await axios.patch(`/deleteCaseImage/${case_id}`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const uploadEditedCaseImage = async (case_id, payload) => {
  try {
    const response = await axios.post(
      `uploadEditedCaseImage/${case_id}`,
      payload,
      {
        headers: { 'content-type': 'form-data' },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const deleteEditedCaseImage = async (case_id, payload) => {
  try {
    const response = await axios.patch(
      `/deleteEditedCaseImage/${case_id}`,
      payload,
    )
    return response
  } catch (error) {
    throw error
  }
}
