import { TableCell, TableActions } from 'components'
import formatDate from 'utils/dates/formatDate'
import sortTableDates from 'utils/global/sortTableDates'

const rightAligned = { display: 'flex', justifyContent: 'flex-end' }

export default ({
  itemsLoaded,
  archivedState,
  archive,
  openCaseDetailsModal,
}) => [
  // Deceased Name
  {
    Header: 'Deceased Name',
    accessor: 'name_of_deceased',
    Cell: ({ value }) => (
      <TableCell text={value || '---'} loaded={itemsLoaded} />
    ),
  },

  // Case
  {
    Header: 'Case',
    accessor: 'editable_case_id',
    Cell: ({ value }) => <TableCell text={value} loaded={itemsLoaded} />,
  },

  // Family Contact
  {
    Header: 'Family Contact',
    accessor: 'family_contact_name',
    Cell: ({ value }) => <TableCell text={value} loaded={itemsLoaded} />,
  },

  // Date of death
  {
    Header: 'Date of Death',
    accessor: 'date_of_death',
    style: rightAligned,
    sortType: (rowA, rowB) =>
      sortTableDates(rowA, rowB, 'sortable_date_of_death'),
    Cell: ({ value }) => (
      <TableCell text={formatDate(value, 'PP')} loaded={itemsLoaded} />
    ),
  },

  // Date Created
  {
    Header: 'Date Created',
    accessor: 'created_at',
    style: rightAligned,
    sortType: (rowA, rowB) => sortTableDates(rowA, rowB, 'sortable_created_at'),
    Cell: ({ value }) => (
      <TableCell text={formatDate(value, 'PP')} loaded={itemsLoaded} />
    ),
  },

  // Service Date
  {
    Header: 'Service Date',
    accessor: 'sortable_service_date.date',
    style: rightAligned,
    sortType: (rowA, rowB) =>
      sortTableDates(rowA, rowB, 'sortable_service_date.date'),
    Cell: ({ value }) => (
      <TableCell text={formatDate(value, 'PP')} loaded={itemsLoaded} />
    ),
  },

  // Actions
  {
    Header: 'Actions',
    style: rightAligned,
    accessor: '',
    disableSortBy: true,
    Cell: (c) => (
      <TableActions
        loaded={itemsLoaded}
        actions={[
          {
            text: archivedState ? 'Unarchive' : 'Archive',
            icon: 'archive',
            onClick: () => archive(false, c.row),
          },
          {
            text: 'Edit case',
            icon: 'pencil',
            onClick: () => openCaseDetailsModal(c.row.original.id),
          },
          {
            text: 'Create package',
            icon: 'plus',
            onClick: () => console.log('Create Package'),
          },
        ]}
      />
    ),
  },
]
