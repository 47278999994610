import { Button, Check } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { useLocalStorage } from 'hooks'
import { useState } from 'react'

export default ({ setShowFinalizeModal, generatePDF }) => {
  const [disableMessage, setDisableMessage] = useState(false)
  const [, setHideFinalizeWarning] = useLocalStorage('hideFinalizeWarning')

  const close = () => {
    setShowFinalizeModal(false)
  }

  const finalize = () => {
    if (disableMessage) setHideFinalizeWarning(disableMessage)
    close()
    generatePDF(false)
  }

  return (
    <Flex
      height="250px"
      width="600px"
      column
      center
      gap="32px"
      padding="0 32px"
    >
      <Flex gap="12px" column>
        <Text weight="500" size="24px" width="100%" align="center">
          Finalize this product?
        </Text>
        <Text width="400px">
          After finalizing this product, you will be unable to edit it further.
          Are you sure you want to finalize this?
        </Text>
      </Flex>
      <Flex gap="12px">
        <Button default onClick={close}>
          Cancel
        </Button>
        <Button primary onClick={finalize}>
          Finalize
        </Button>
      </Flex>
      <Check
        checked={disableMessage}
        onClick={() => setDisableMessage(!disableMessage)}
        text="Don't show this warning again"
        width="100%"
      />
    </Flex>
  )
}
