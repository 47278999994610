import styled from 'styled-components'
import colors from 'utils/styling/colors'
import Flex from 'components/priority/Flex/Flex'

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: none;
  position: absolute;
  top: -12px;
  right: -12px;
  background: ${colors('white')};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
`

export const Card = styled(Flex)`
  &:hover {
    background: ${colors('gray5')};

    ${IconWrapper} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
