import { useState } from 'react'
import { Button } from 'components'
import { useSetToast } from 'hooks'
import { migrateThemes } from 'api/admin'

export default () => {
  const [loading, setLoading] = useState(false)
  const setToast = useSetToast()

  const launchThemes = async () => {
    setLoading(true)

    const payload = { fromDate: '2024-03-15' }

    // Create a timeout promise that rejects after 60 seconds
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), 60000),
    )

    try {
      await Promise.race([migrateThemes(payload), timeoutPromise])

      setToast({
        icon: 'check',
        text: 'Themes transferred successfully',
      })
    } catch (error) {
      setToast({
        text:
          error.message === 'Request timed out'
            ? 'Operation timed out. Please try again later.'
            : 'Failed to transfer themes',
      })
    } finally {
      setLoading(false)
    }
  }

  const buttonText = loading
    ? '🎨 Transferring themes...'
    : '🎨 Transfer over themes from the admin app'

  return (
    <Button primary onClick={launchThemes} disabled={loading}>
      {buttonText}
    </Button>
  )
}
