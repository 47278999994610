import styled, { css } from 'styled-components'
import typeArea from 'styles/typeArea'
import sizing from 'styles/sizing'
import spacing from 'styles/spacing'
import disabled from 'styles/disabled'
import Flex from 'components/priority/Flex/Flex'

export const InputWrapper = styled(Flex)`
  ${disabled}
`

export const Input = styled.input(
  ({ ...props }) => css`
    padding: 12px 16px;
    height: 42px;
    width: 100%;

    ${typeArea}
    ${sizing(props)}
    ${spacing(props)}
  `,
)
