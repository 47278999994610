import { useState } from 'react'
import { Button, Image, FileDropzone } from 'components'
import Flex from 'components/priority/Flex/Flex'

export default ({ logo, sizeName, replaceLogo, uploadLogo }) => {
  const [replace, setReplace] = useState(false)

  return (
    <Flex
      column
      border="1px solid"
      borderColor="gray4"
      radius="4px"
      margin="0 0 24px 0"
      align="center"
    >
      <Flex full justify="space-between">
        {logo?.[sizeName] && !replace && (
          <Image
            margin="20px 20px"
            src={`${process.env.REACT_APP_S3BUCKET_FH_LOGOS}${logo[sizeName]}`}
            maxHeight={
              sizeName === 'small'
                ? '100px'
                : sizeName === 'medium'
                ? '200px'
                : '500px'
            }
            maxWidth={
              sizeName === 'small'
                ? '100px'
                : sizeName === 'medium'
                ? '200px'
                : '500px'
            }
          />
        )}
        {(!logo?.[sizeName] || replace) && (
          <FileDropzone
            height={sizeName === 'small' ? '100px' : '200px'}
            margin="20px 20px"
            text={`Drag and drop file here to edit`}
            fileTypes={['.png', '.jpeg', '.jpg', '.gif']}
            onDrop={(file) => {
              !logo.funeral_home_id
                ? uploadLogo(file, sizeName)
                : replaceLogo(file, sizeName)
              setReplace(false)
            }}
          />
        )}
        <Flex column>
          <Button
            margin="15px 15px 0 0"
            onClick={() => {
              setReplace(true)
            }}
          >
            Replace Logo
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
