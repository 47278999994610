import styled, { css } from 'styled-components'
import disabled from 'styles/disabled'
import Flex from 'components/priority/Flex/Flex'

export const ToggleSwitch = styled(Flex)(
  ({ labelRight }) => css`
    ${disabled}
    ${labelRight ? 'flex-direction: row-reverse;' : ''}
  `,
)
