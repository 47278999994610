import { useNavigate } from 'hooks'
import * as S from './MenuItem.styled'
import Text from 'components/priority/Text/Text'
import Icon from 'components/priority/Icon/Icon'

export default ({ text, icon, link, disabled, setShow, onClick, fontMenu }) => {
  const navigate = useNavigate()
  const action = () => {
    link ? navigate(link) : onClick?.()
    setShow?.(false)
  }

  return (
    <S.MenuItem
      clickable
      justify="space-between"
      align="center"
      padding="12px"
      radius="2px"
      disabled={disabled}
      onClick={action}
    >
      <Text font={fontMenu && text} whiteSpace="nowrap">
        {text}
      </Text>
      {icon && <Icon icon={icon} fill="gray1" margin="0 0 0 18px" />}
    </S.MenuItem>
  )
}
