// the keys are momentJS strings
// the values are the translated dateFNs strings

const momentToDateFormat: Record<string, string> = {
  LL: 'MMMM d, yyyy',
  'M/D/YY': 'M/d/yy',
  'MM/DD/YY': 'MM/dd/yy',
  'M/D/YYYY': 'M/d/yyyy',
  'MM/DD/YYYYY': 'MM/dd/yyyy',
  'YYYY MMM D': 'yyyy MMM d',
  'YYYY MMMM D': 'yyyy MMMM d',
  'YYYY MMM Do': 'yyyy MMM do',
  'YYYY MMMM Do': 'yyyy MMMM do',
  'MMM D, YYYY': 'MMM d, yyyy',
  'MMM Do, YYYY': 'MMM do, yyyy',
  'MMMM D, YYYY': 'MMMM d, yyyy',
  'MMMM Do, YYYY': 'MMMM do, yyyy',
  'dddd, MMMM D, YYYY': 'iiii, MMMM d, yyyy',
  'dddd, MMMM Do, YYYY': 'iiii, MMMM do, yyyy',
}

const momentToDateFns = (momentJSString: string): string =>
  momentToDateFormat?.[momentJSString] || momentJSString

export default momentToDateFns
