const buildUrlWithParams = (
  baseUrl: string,
  params: URLSearchParams,
): string => {
  const paramsString = params.toString()
  const hasParams = paramsString.length > 0
  return `${baseUrl}${hasParams ? `?${paramsString}` : ''}`
}

export default buildUrlWithParams
