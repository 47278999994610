import { RecoilRoot } from 'recoil'
import type { ReactNode } from 'react'

type Props = {
  children: ReactNode
  override?: boolean
}

const RecoilProvider = ({ children, ...props }: Props) => {
  return <RecoilRoot {...props}>{children}</RecoilRoot>
}

export default RecoilProvider
