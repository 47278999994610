import { Button, Input, TinyMCE } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Tooltip from 'components/global/Tooltip/Tooltip'
import { useRecoilValue } from 'recoil'
import { roleAtom } from 'atoms'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'

export default ({
  value,
  reference: [reference, setReference],
  TinyMCERef,
  cancel,
  save,
  global: [isGlobalVerse, setIsGlobalVerse],
}) => {
  const role = useRecoilValue(roleAtom)

  const isMessengerUser = role === 'messenger-user'
  return (
    <Flex column full>
      <Flex
        border="1px solid"
        borderColor="gray4"
        radius="2px"
        minHeight="200px"
        margin="0 0 12px 0"
        full
        background="lockedWhite"
      >
        <TinyMCE
          placeholder="Type text here"
          value={value}
          setRef={(ref) => (TinyMCERef.current = ref)}
        />
      </Flex>
      <Input
        margin="0 0 12px 0"
        label="Reference"
        value={[reference, setReference]}
      />
      {isMessengerUser && (
        <Flex justify="flex-start" margin="16px 0">
          <Tooltip text="Make this verse global" margin="0 12px 0 0">
            <ToggleSwitch
              label="Is global verse"
              labelRight
              value={[isGlobalVerse, setIsGlobalVerse]}
              disabled={!isMessengerUser}
            />
          </Tooltip>
        </Flex>
      )}
      <Flex>
        <Button onClick={() => cancel?.()} margin="0 12px 0 0">
          Cancel
        </Button>
        <Button primary onClick={() => save?.()}>
          Save
        </Button>
      </Flex>
    </Flex>
  )
}
