import { useRecoilValue } from 'recoil'
import { boundingBoxAtom } from 'atoms'
import { useColors } from 'hooks'
import * as S from './BoundingBox.styled'

export default () => {
  const c = useColors()
  const boundingBox = useRecoilValue(boundingBoxAtom)

  return boundingBox.height || boundingBox.width ? (
    <div style={S.boundingBoxStyling(boundingBox, c)} />
  ) : (
    <></>
  )
}
