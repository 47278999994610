import { css, type DefaultTheme } from 'styled-components'
import type { CSS, OpacityPercentage } from 'types/style'
import hexOpacity from './hexOpacity'
/**
 * Combines a color with an optional opacity value
 * @example
 * colors('brand', 50) // If brand color is '#FF0000', result is '#FF000080'
 *
 * Steps:
 * 1. Gets color: '#FF0000'
 * 2. Gets opacity hex: hexOpacity[50] = '80'
 * 3. Combines them: '#FF000080'
 */
const colors = (
  color: keyof DefaultTheme | string | undefined,
  opacity?: OpacityPercentage,
): CSS => {
  const transparentColorCSS = css`
    ${({ theme }) => {
      // e.g., '#FF0000'
      const themeColor = theme?.[color as keyof DefaultTheme]
      // e.g., '#FF000080'
      return themeColor
        ? `${themeColor}${opacity && hexOpacity[opacity]}`
        : 'transparent'
    }}
  `

  const colorCSS = css`
    ${({ theme }) => theme[color as keyof DefaultTheme] || color}
  `

  return opacity ? transparentColorCSS : colorCSS
}

export default colors
