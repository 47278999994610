import type { Palette } from 'types/style'
import type { ColorValue, ThemeColors } from 'types/style'
import palettes from './palettes'

/**
 * Generates a complete palette of color values for a specified theme mode
 * Where the palette is initialized with empty strings and then populated with the appropriate color values based on the specified theme mode.
 *
 * @example
 * // Returns dark theme colors
 * const darkColors = getThemeColors('dark')
 * // {
 * //   brand: '#1A6CAB',
 * //   secondary: '#D7B566',
 * //   white: '#1C2125',
 * //   gray1: '#F3F5F9',
 * //   ...
 * // }
 */

const getThemeColors = (colorSet: keyof ColorValue): ThemeColors => {
  const palette = Object.keys(palettes).reduce(
    (acc, key) => ({
      ...acc,
      [key]: '',
    }),
    {} as ThemeColors,
  )
  Object.entries(palettes).forEach(
    ([color, value]) =>
      (palette[color as keyof Palette] =
        value?.[colorSet as keyof ColorValue] || value?.default),
  )

  return palette
}

export default getThemeColors
