import { css } from 'styled-components'
import type { NumberRange } from 'types/utils'
import type { StyledFunction } from 'types/style'
import colors from 'utils/styling/colors'

export type BorderProps = {
  border?: string
  borderTop?: string
  borderRight?: string
  borderBottom?: string
  borderLeft?: string
  borderColor?: string
  borderOpacity?: NumberRange<0, 100>
}

const borders: StyledFunction<BorderProps> = ({
  border,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  borderColor = '',
  borderOpacity = undefined,
}) => css`
  border: ${border};
  border-top: ${borderTop};
  border-right: ${borderRight};
  border-bottom: ${borderBottom};
  border-left: ${borderLeft};
  border-color: ${colors(borderColor, borderOpacity)};
`

export default borders
