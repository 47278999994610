import styled, { css } from 'styled-components'
import Draggable from 'react-draggable'
import colors from 'utils/styling/colors'
import zLayers from 'utils/styling/zLayers'

export const Drag = styled(Draggable)``

export const ItemContainer = styled.div(
  ({ z, isWatermark, disabled }) => css`
    position: absolute;
    z-index: ${zLayers.getItemZ(z)};
    outline: ${isWatermark && !disabled && `1px solid`};
    outline-color: ${isWatermark && !disabled && colors('secondary')};
    pointer-events: ${disabled && 'none'};
  `,
)
