import { useState } from 'react'
import { Button, Input } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { createOrder } from 'api/orders'
import { createCase as createCaseApi } from 'api/cases'
import { caseAtom, orderAtom, hasNewCaseAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { useNavigate } from 'hooks'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({
  setShowButtonModal,
  setShowCaseModal,
  type,
  onSave,
  getItems,
}) => {
  const handleError = useErrorHandler('NewCaseModal')
  const [nameOfDeceased, setNameOfDeceased] = useState('')
  const setCase = useSetRecoilState(caseAtom)
  const setOrder = useSetRecoilState(orderAtom)
  const setHasNewCase = useSetRecoilState(hasNewCaseAtom)

  const navigate = useNavigate()

  const createCase = async () => {
    try {
      const payload = { name_of_deceased: nameOfDeceased }
      const { data: caseData } = await createCaseApi(payload)
      setCase(caseData)
      setShowButtonModal(false)

      if (type === 'Case') {
        setShowCaseModal(true)
        getItems(false)
      }

      if (type === 'Order') {
        const { data: orderData } = await createOrder({
          case_id: caseData.id,
        })
        setOrder(orderData)
        setHasNewCase(true)
        navigate(`/package/${orderData.id}`)
      }

      if (type === 'duplicate') {
        setShowButtonModal(false)
        onSave?.(caseData.id)
      }
    } catch (error) {
      handleError(
        error?.response?.data?.message || error.message,
        error,
        null,
        'createCase',
      )
    }
  }

  return (
    <Flex column full padding="32px">
      <Text size="24px" weight="600" margin="0 0 20px 0">
        Create New Case
      </Text>
      <Input
        full
        label="Name of Deceased"
        placeholder="Full Name"
        value={[nameOfDeceased, setNameOfDeceased]}
        margin="0 0 24px 0"
      />
      <Flex justify="flex-end" margin="24px 0 0 0">
        <Button margin="0 16px 0 0" onClick={setShowButtonModal}>
          Cancel
        </Button>

        <Button left primary margin="0 12px 0 0" onClick={() => createCase()}>
          Create New Case
        </Button>
      </Flex>
    </Flex>
  )
}
