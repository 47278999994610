import { useState, useEffect } from 'react'
import { toastsAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import Flex from 'components/priority/Flex/Flex'
import remove from 'lodash/remove'
import Toast from './Toast'
import zLayers from 'utils/styling/zLayers'

export default () => {
  const [toasts, setToasts] = useRecoilState(toastsAtom)
  const [queue, setQueue] = useState([])

  useEffect(() => {
    if (toasts.length > 0) {
      setQueue([...queue, ...toasts])
      setToasts([])
    }
    // eslint-disable-next-line
  }, [toasts])

  const timerFinished = (uuid) => {
    const newQueue = [...queue]
    remove(newQueue, (toast) => toast.uuid === uuid)
    setQueue(newQueue)
  }

  return (
    <Flex
      column
      position="fixed"
      bottom="12px"
      left="12px"
      zindex={zLayers.toast}
    >
      {queue
        ?.slice(0)
        ?.reverse()
        ?.map(({ uuid, ...props }, i) => (
          <Toast
            key={uuid}
            uuid={uuid}
            index={i}
            timerFinished={timerFinished}
            {...props}
          />
        ))}
    </Flex>
  )
}
