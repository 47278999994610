import { useState, useRef } from 'react'
import { Button, Expandable, TinyMCE } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { useRecoilState } from 'recoil'
import { editCaseFieldSelector } from 'atoms'
import { CaseInputWrapper } from 'components/dashboard/CaseDetails/CaseInputWrappers'

export default ({ saveCase, caseDetails, setActiveTab }) => {
  const [obit, setObit] = useRecoilState(
    editCaseFieldSelector({ name: 'obituary', path: 'value' }),
  )
  const editorRef = useRef(null)
  const [expandedObit, setExpandedObit] = useState(true)

  const save = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent()
      setObit(content)
      saveCase()
      setActiveTab('Service info')
    }
  }

  const handleBlur = () => {
    if (editorRef.current) {
      let content = editorRef.current.getContent()
      if (!content) content = '<p></p>'
      setObit(content)
    }
  }

  return (
    <Flex column full padding="0 40px 0 0">
      <Flex column align="flex-start">
        <Expandable
          margin="0 0 32px 0"
          title="Obituary"
          show={[expandedObit, setExpandedObit]}
          full
        >
          <CaseInputWrapper
            width="100%"
            margin="0 24px 24px 0"
            label="Website Obituary URL"
            name="website_obit_url"
          />
          <Text size="12px" margin="0 0 8px">
            Obituary
          </Text>
          <Flex
            border="1px solid"
            borderColor="gray4"
            height="500px"
            margin="0 0 25px 0"
            overflow="auto"
            padding="2px"
            onBlur={handleBlur}
            background="lockedWhite"
            id="obituary"
          >
            <TinyMCE
              setRef={(ref) => (editorRef.current = ref)}
              value={obit}
              caseDetails={caseDetails}
              init={{
                toolbar: false,
                // this removes any saved styles to display as plain text
                extended_valid_elements:
                  'span[class|type|data-case-detail|data-style],p[class|type|data-case-detail|data-style],div[style|data-style]',
              }}
            />
          </Flex>

          <Flex justify="flex-end">
            <Button primary onClick={() => save()}>
              Save and continue
            </Button>
          </Flex>
        </Expandable>
      </Flex>
    </Flex>
  )
}
