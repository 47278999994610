import styled, { css } from 'styled-components'
import Flex from 'components/priority/Flex/Flex'
import colors from 'utils/styling/colors'

export const Tab = styled(Flex)(
  ({ selected }) => css`
    background-color: ${selected && colors('paleSecondary')};
    &:hover {
      background-color: ${colors('paleSecondary')};
    }
  `,
)

export const Thumbnail = styled(Flex)(
  ({ thumbnail }) => css`
    background-image: url('${thumbnail}');
    background-size: contain;
    background-repeat: no-repeat;
  `,
)

export const TabScrollContainer = styled(Flex)`
  overflow-y: scroll;
`
