import { css } from 'styled-components'
import elements from 'styles/elements'
import type { StyledFunction } from 'types/style'
import type { StyleProps } from 'utils/styling/propsToCSS'
import colors from 'utils/styling/colors'
import propsToCSS from 'utils/styling/propsToCSS'

const propStyles = {
  error: css`
    border: 1px solid ${colors('secondary')};
  `,
}

const typeArea: StyledFunction<StyleProps> = ({ ...props }) => css`
  font-size: 14px;
  font-weight: 400;

  &::placeholder {
    color: ${colors('gray2')};
    user-select: none;
  }

  &:focus {
    background: ${colors('white')};
    border: 1px solid ${colors('gray2')};
  }

  ${propsToCSS(props, propStyles)}
  ${elements.default}
`

export default typeArea
