import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'

const convertTimeZones = {
  toLocal: (date: string): string | Date =>
    date ? zonedTimeToUtc(date, 'UTC') : date,
  toUTC: (date: string): string | Date =>
    date ? utcToZonedTime(date, 'UTC') : date,
}

export default convertTimeZones
