import styled from 'styled-components'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import colors from 'utils/styling/colors'

export const HeaderContainer = styled(Flex)`
  background: ${colors('paleSecondary')};
`

export const PhoneIcon = styled(Icon)`
  position: absolute;
  bottom: 0;
  left: 0;
`

export const SpeechBubbleIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
`

export const WebinarSection = styled(Flex)`
  width: 480px;
  border: 1px solid ${colors('gray4')};
  padding: 24px;
`
