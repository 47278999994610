import { useState } from 'react'
import { Button } from 'components'
import { useSetToast } from 'hooks'
import { revertGlobalLayouts } from 'api/admin'

export default () => {
  const [loading, setLoading] = useState(false)
  const setToast = useSetToast()

  const launchRevertGlobalLayouts = async () => {
    setLoading(true)

    try {
      await revertGlobalLayouts()
      setToast({
        icon: 'check',
        text: 'Global layouts converted to personal successfully',
      })
    } catch (error) {
      setToast({
        text: 'Failed to make global layouts personal',
      })
    } finally {
      setLoading(false)
    }
  }

  const buttonText = loading
    ? '🏠 Making all global templates personal...'
    : '🏠 Make all global templates personal'

  return (
    <Button primary onClick={launchRevertGlobalLayouts} disabled={loading}>
      {buttonText}
    </Button>
  )
}
