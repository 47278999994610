import { useState } from 'react'
import { FileDropzone, Pintura } from 'components'
import Flex from 'components/priority/Flex/Flex'

export default () => {
  const [pinturaImage, setPinturaImage] = useState()
  const [processedPinturaImage, setProcessedPinturaImge] = useState(null)

  return (
    <Flex column margin="0 0 24px 0">
      <Pintura
        setProcessedPinturaImge={setProcessedPinturaImge}
        pinturaImage={pinturaImage}
        setPinturaImage={setPinturaImage}
      />
      <FileDropzone
        text="Drag and drop file here to edit"
        fileTypes={['.png', '.jpeg', '.jpg', '.gif']}
        onDrop={(file) => {
          const uploadUrl = URL.createObjectURL(file[0])
          setPinturaImage(uploadUrl)
        }}
      />
      {processedPinturaImage && (
        <img
          src={processedPinturaImage}
          alt="preview"
          width="250"
          height="250"
        />
      )}
    </Flex>
  )
}
