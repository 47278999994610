import { parseISO, isValid } from 'date-fns'
import isDate from 'utils/dates/isDate'
import convertTimeZones from 'utils/dates/convertTimeZones'

/**
 * Converts ISO string to Date object with timezone handling
 * @param date - ISO date string or Date object
 * @returns Date object or original input if invalid
 */
const isoStringToDate = (date: string | Date): Date | string => {
  const alreadyDate = isDate(date)
  const parsedISO = parseISO(date as string)
  let parsedDate: Date = alreadyDate ? (date as Date) : parsedISO

  if (isValid(parsedISO)) {
    const converted = convertTimeZones.toUTC(parsedISO as unknown as string)
    parsedDate =
      converted instanceof Date ? converted : parseISO(converted as string)
  }

  return isValid(parsedDate) ? parsedDate : date
}

export default isoStringToDate
