import styled, { css } from 'styled-components'
import colors from 'utils/styling/colors'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'

export const RenderContainer = styled(Flex)`
  overflow: auto;
`

export const TransformWrapper = styled.div(
  () => css`
    transform: translate(-50%, -25%) scale(0.5);
    position: absolute;
    pointer-events: none;
    left: 50%;
  `,
)

export const NoProduct = styled(Flex)(
  () => css`
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    margin: 40px 40px;
    height: auto;
    width: 400px;
    background-color: ${colors('white')};
  `,
)

export const InteralLinkText = styled(Text)`
  a {
    color: ${colors('brand')};
    text-decoration: underline;
    font-weight: 600;
  }
`
