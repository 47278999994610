import { Input } from 'components'
import { useRecoilState } from 'recoil'
import { editCaseFieldSelector } from 'atoms'

export default ({ name, label, width, margin }) => {
  const [value, setValue] = useRecoilState(
    editCaseFieldSelector({ name: name, path: 'value' }),
  )
  return (
    <Input
      width={width}
      margin={margin || '0 24px 0 0'}
      label={label}
      value={[value, setValue]}
    />
  )
}
