import { useRef } from 'react'
import { TinyMCE } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { useRecoilState } from 'recoil'
import { editCaseFieldSelector } from 'atoms'
import { CaseInputWrapper } from 'components/dashboard/CaseDetails/CaseInputWrappers'
import { SidebarMenuSaveSection } from 'components/editor/InsertMenu/SidebarMenuSaveSection'

export default ({ saveCase, caseDetails }) => {
  const [obit, setObit] = useRecoilState(
    editCaseFieldSelector({ name: 'obituary', path: 'value' }),
  )
  const editorRef = useRef(null)

  const save = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent()
      setObit(content)
      saveCase()
    }
  }

  const handleBlur = () => {
    if (editorRef.current) {
      let content = editorRef.current.getContent()
      if (!content) content = '<p></p>'
      setObit(content)
    }
  }
  return (
    <>
      <Flex full center padding="8px 8px 100px 8px" gap="8px" column>
        <Flex wrap column width="100%">
          <CaseInputWrapper
            margin="0 24px 24px 24px"
            label="Website Obituary URL"
            name="website_obit_url"
            width={'auto'}
          />
          <Text size="12px" margin="0 0 8px 24px">
            Obituary
          </Text>
          <Flex
            border="1px solid"
            borderColor="gray4"
            height="500px"
            margin="0 24px 24px 24px"
            overflow="auto"
            padding="2px"
            onBlur={handleBlur}
            background="lockedWhite"
            id="obituary"
          >
            <TinyMCE
              setRef={(ref) => (editorRef.current = ref)}
              value={obit}
              caseDetails={caseDetails}
              init={{
                toolbar: false,
                // this removes any saved styles to display as plain text
                extended_valid_elements:
                  'span[class|type|data-case-detail|data-style],p[class|type|data-case-detail|data-style],div[style|data-style]',
              }}
            />
          </Flex>
        </Flex>
        <SidebarMenuSaveSection saveCase={save} />
      </Flex>
    </>
  )
}
