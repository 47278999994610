const convertObjectOfDates = (
  dateSet: string[],
  array: any,
  conversion: (date: string | Date) => Date | string,
): void => {
  dateSet.forEach((date: string) => {
    const dateExists = array?.[date]
    dateExists && (array[date] = conversion(dateExists))
  })
}

export default convertObjectOfDates
