type RegExPatterns = {
  email: RegExp
  externalLink: RegExp
  protocolLink: RegExp
  urlPath: RegExp
}

// TODO: Utilize this file for all regEx patterns in the app
const regEx: RegExPatterns = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  externalLink: /((http|https):\/[\w\\.\\/\-=?#]+)/,
  protocolLink: /^(#|mailto:|tel:)/,
  urlPath: /[^/]+$/,
}

export default regEx
