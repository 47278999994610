import { useState, useEffect } from 'react'
import { Navigation, CustomThemeEditor } from 'components'
import { getCustomThemesById } from 'api/customThemes'
import { useLocation } from 'react-router-dom'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default () => {
  const handleError = useErrorHandler('CustomThemeEditorWrapper')
  const { pathname: location } = useLocation()
  const [theme, setTheme] = useState('')

  const getTheme = async () => {
    try {
      const [, , themeId] = location.split('/')
      const { data } = await getCustomThemesById(themeId)
      setTheme(data)
    } catch (error) {
      handleError(
        'An unexpected error has occurred while getting a theme. Please try again later.',
        error,
        null,
        'getTheme',
      )
    }
  }

  useEffect(() => {
    getTheme()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <Navigation />
      <CustomThemeEditor theme={theme} />
    </>
  )
}
