import { useRef, useState } from 'react'
import { Button, Input, Loading } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { getDesignAssets, uploadDesignAsset } from 'api/designAssets'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { designAssetsAtom, pageLoadingAtom, themeLayoutAtom } from 'atoms'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { useActions } from 'hooks'
import { FileUploadHandler, useFileUpload } from './'

export default (props) => {
  const modalProps = useDeisgnAssetModal(props)
  const { error } = modalProps

  let ModalState = null
  if (error) ModalState = Error
  if (!error) ModalState = Content

  return (
    <Flex column full padding="32px">
      <Text size="20px" weight="600" margin="10px 0 10px 0">
        Design asset name
      </Text>
      <ModalState {...modalProps} />
    </Flex>
  )
}

const Content = (props) => {
  const {
    name,
    setName,
    closeModal,
    loading,
    inputFileDesignAsset,
    handleDesignAssetUpload,
    clickDesignAssetUpload,
  } = props

  return (
    <Loading isLoading={loading}>
      <Flex column gap="12px">
        <Input
          value={[name, setName]}
          placeholder="Enter design asset name"
          width="100%"
        />
        <Flex center margin="10px 0 0 0">
          <Button margin="0 16px 0 0" onClick={closeModal}>
            Cancel
          </Button>
          <Button primary margin="0 16px 0 0" onClick={clickDesignAssetUpload}>
            Upload image
          </Button>
        </Flex>
      </Flex>
      <FileUploadHandler
        inputRef={inputFileDesignAsset}
        onChange={handleDesignAssetUpload}
      />
    </Loading>
  )
}

const Error = (props) => {
  const { error, closeModal } = props

  return (
    <Flex column gap="12px" maxWidth="300px">
      <Text>There was a problem uploading your asset: {error}</Text>
      <Button margin="0 16px 0 0" onClick={closeModal}>
        Close
      </Button>
    </Flex>
  )
}

const useDeisgnAssetModal = (props) => {
  const { setShowDesignAssetModal } = props

  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const inputFileDesignAsset = useRef(null)

  const handleError = useErrorHandler('DesignAssetModal')
  const action = useActions()

  const themeLayout = useRecoilValue(themeLayoutAtom)
  const setDesignAssets = useSetRecoilState(designAssetsAtom)
  const setPageLoading = useSetRecoilState(pageLoadingAtom)

  const closeModal = () => {
    setShowDesignAssetModal(false)
  }

  const clickDesignAssetUpload = () => {
    inputFileDesignAsset.current.click()
  }

  const uploadComplete = () => {
    setPageLoading(false)
    setLoading(false)
    setName('')
    closeModal()
  }

  const uploadFailed = (error) => {
    setPageLoading(false)
    setLoading(false)
    setName('')
    setError(error)
  }

  const uploadFunction = (fd) => {
    try {
      setLoading(true)
      setPageLoading(true)
      fd.append('name', name)
      return uploadDesignAsset(themeLayout.theme_id, fd)
    } catch (error) {
      handleError(
        error?.response?.data?.message || error.message,
        error,
        null,
        'uploadFunction',
      )
      uploadFailed(error)
    }
  }

  const setImages = async (data) => {
    try {
      const designAssetsData = await getDesignAssets(themeLayout.theme_id)
      setDesignAssets(designAssetsData.data.designAssets)
      await action.insertDesignAsset({
        designAsset: designAssetsData.data.designAssets.length - 1,
        url: data.url,
      })
      uploadComplete()
    } catch (error) {
      handleError(
        error?.response?.data?.message || error.message,
        error,
        null,
        'setImages',
      )
      uploadFailed(error)
    }
  }

  const handleDesignAssetUpload = useFileUpload({ uploadFunction, setImages })

  return {
    name,
    setName,
    closeModal,
    loading,
    error,
    inputFileDesignAsset,
    handleDesignAssetUpload,
    clickDesignAssetUpload,
  }
}
