import axios from 'api/axios.config'

export const getCustomThemes = async () => {
  try {
    const response = await axios.get(`themes?custom_themes=true`)
    return response
  } catch (error) {
    throw error
  }
}

export const getCustomThemesById = async (theme_id) => {
  try {
    const response = await axios.get(`themes/${theme_id}?custom=true`)
    return response
  } catch (error) {
    throw error
  }
}

export const createCustomTheme = async (payload) => {
  try {
    const response = await axios.post('createCustomTheme', payload)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const updateCustomTheme = async (theme_id, payload) => {
  try {
    const response = await axios.patch(`themes/${theme_id}`, payload)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const archiveCustomTheme = async (theme_id) => {
  try {
    const response = await axios.patch(`archiveTheme/${theme_id}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const uploadThemeImage = async (theme_id, payload) => {
  try {
    const response = await axios.post(`uploadThemeImage/${theme_id}`, payload, {
      headers: { 'content-type': 'form-data' },
    })
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const uploadOptimizedThemeImage = async (theme_id, payload) => {
  try {
    const response = await axios.post(
      `uploadOptimizedThemeImage/${theme_id}`,
      payload,
      {
        headers: { 'content-type': 'form-data' },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const uploadReplacementImage = async (layout_id, payload) => {
  try {
    const response = await axios.post(
      `uploadReplacementImage/${layout_id}`,
      payload,
      {
        headers: { 'content-type': 'form-data' },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const uploadOptimizedReplacementImage = async (layout_id, payload) => {
  try {
    const response = await axios.post(
      `uploadOptimizedReplacementImage/${layout_id}`,
      payload,
      {
        headers: { 'content-type': 'form-data' },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

export const uploadEditedBackgroundImage = async (layout_id, payload) => {
  try {
    const response = await axios.post(
      `uploadEditedBackgroundImage/${layout_id}`,
      payload,
      {
        headers: { 'content-type': 'form-data' },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const deleteEditedBackgroundImage = async (layout_id, payload) => {
  try {
    const response = await axios.post(
      `deleteEditedBackgroundImage/${layout_id}`,
      payload,
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const getProductsForCustomThemes = async () => {
  try {
    const response = await axios.get('products?custom_themes=true')
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const layoutForCustomThemes = async (product_id, theme_id) => {
  try {
    const response = await axios.get(
      `layoutsForCustomTheme?product_id=${product_id}&theme_id=${theme_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

//TODO: Why is this here?
export const updateThemeLayout = async (theme_layout_id, payload) => {
  try {
    const response = await axios.patch(
      `themeLayouts/${theme_layout_id}`,
      payload,
    )
    return response
  } catch (error) {
    throw error
  }
}
