import { useHistory } from 'react-router-dom'
import determineLink from 'utils/global/determineLink'

export default () => {
  let history = useHistory()

  return (location) => {
    switch (determineLink(location)) {
      case 'none':
        break
      case 'external':
        window.open(location)
        break
      case 'protocol':
        window.open(location)
        break
      default:
        history?.push(location)
    }
  }
}
