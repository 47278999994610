import handleHtmlData from 'utils/global/handleHtmlData'
import getThemeStylesForTemplate from 'utils/tinyMCE/templates/getThemeStylesForTemplate'

export default ({ editorProps, templateProps, firstInsertion = false }) => {
  const { caseDetails, selectedTheme } = editorProps
  const { fieldName } = templateProps

  const templateData = { fieldName }
  const encodedProperties = handleHtmlData.encode(templateData)

  const text = caseDetails[fieldName]

  if (!text || typeof text !== 'string' || text.trim() === '') return ''

  const firstLetter = text.trim().charAt(0)
  const uppercasedFirstLetter = firstLetter.toUpperCase()

  const letterParagraph = `<p><span>${uppercasedFirstLetter}</span></p>`

  let themeStyles = ''

  /* Apply theme styling if:
   * - This is a new template insertion
   * - Theme object is not null
   */
  if (firstInsertion && selectedTheme) {
    themeStyles = getThemeStylesForTemplate({ selectedTheme })
  }

  const html = `
    <div data-template="${fieldName}_first_letter" data-properties="${encodedProperties}" class="mceNonEditable" style="${themeStyles}">
      ${letterParagraph}
    </div>
  `

  return html
}
