import { default as fetch } from './fetch.config.js'
import axios from 'api/axios.config'
import sanitizeDashboardDates from 'utils/sanitizers/sanitizeDashboardDates'

// NOTE: This endpoint is not used
export const getFuneralHomeOrders = async (archived) => {
  try {
    const response = await axios.get(`/funeralHomeOrders?archived=${archived}`)
    response.data.orders = sanitizeDashboardDates(response.data.orders)
    return response
  } catch (error) {
    throw error
  }
}

export const createOrder = async (payload) => {
  try {
    const response = await axios.post(`createOrder`, payload)
    return response

    // expected payload:
    // {
    //   case_id: <String> (REQUIRED),
    //   collection_id: <String>,
    //   theme_id: <String>,
    // }
  } catch (error) {
    throw error
  }
}

export const updateOrder = async (order_id, payload) => {
  try {
    const response = await axios.patch(`orders/${order_id}`, payload)
    return response

    // expected payload:
    // {
    //   case_id: <String>,
    //   collection_id: <String> (allow null),
    //   theme_id: <String> (allow null),
    //   reset_edited_layouts: <Boolean>,
    //   selected_for_service: <Boolean> (OPTIONAL),
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const archiveOrder = async (order_id) => {
  try {
    const response = await axios.patch(`archiveOrder/${order_id}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const unarchiveOrder = async (order_id) => {
  try {
    const response = await axios.patch(`unarchiveOrder/${order_id}`)
    return response
  } catch (error) {
    throw error
  }
}

export const getOrder = async (order_id) => {
  try {
    const response = await axios.get(`orders/${order_id}`)
    return response
  } catch (error) {
    throw error
  }
}

//TODO: Expect this endpoint to change with rebuild
export const generatePDF = async (payload) => {
  try {
    const messengerOrderIdToken = localStorage.getItem('messengerOrderIdToken')

    const options = messengerOrderIdToken
      ? {
          headers: {
            'x-order': messengerOrderIdToken,
          },
        }
      : undefined

    const response = await fetch.post(`generatePDF`, payload, options)
    return response
  } catch (error) {
    throw error
  }
}

export const generatePDFStatus = async (pdf_id) => {
  try {
    const messengerOrderIdToken = localStorage.getItem('messengerOrderIdToken')

    const options = messengerOrderIdToken
      ? {
          headers: {
            'x-order': messengerOrderIdToken,
          },
        }
      : undefined

    const response = await fetch.get(
      `generatePDFStatus/${pdf_id}`,
      undefined,
      options,
    )
    return response

    // expected payload (ALL REQUIRED):
    // {
    //   url: <String>,
    //   html: <String>,
    //   page_height: <Number>,
    //   page_width: <Number>,
    // }
  } catch (error) {
    throw error
  }
}

//TODO: Expect this endpoint to change with rebuild
// NOTE: This endpoint is not used
export const generateJPEG = async (payload, _) => {
  try {
    const response = await axios.post(`generateJPEG`, payload)
    return response

    // expected payload:
    // {
    //   html: <String> (REQUIRED),
    // }
  } catch (error) {
    throw error
  }
}

//TODO: Expect this endpoint to change with rebuild
// NOTE: Endpoint missing in backend
export const duplicateOrder = async (payload) => {
  try {
    const response = await axios.post(`duplicateOrder`, payload)
    return response

    // expected payload (ALL REQUIRED):
    // {
    //   case_id: <UUID>,
    //   order_id: <UUID>,
    // }
  } catch (error) {
    throw error
  }
}
