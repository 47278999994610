import { List } from 'react-movable'
import { Button, LoadingInsertImages, PageShuffleItem } from 'components'
import Flex from 'components/priority/Flex/Flex'
import { selectedPageAtom, roleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import * as S from './Menus.styled'
import { usePageShuffler } from 'hooks'

export default ({ theme, themeLayout, basePages }) => {
  const {
    loading,
    disabled,
    preventDelete,
    pages,
    addPage,
    updatePages,
    deletePage,
    goToPage,
    isDeleting,
  } = usePageShuffler(basePages)

  const selectedPage = useRecoilValue(selectedPageAtom)
  const userRole = useRecoilValue(roleAtom)
  const isAdmin = userRole === 'messenger-user'

  return loading ? (
    <LoadingInsertImages />
  ) : (
    <Flex column>
      {!disabled && isAdmin && (
        <Flex full center>
          <Button
            margin="0 32px 0 16px"
            width="90%"
            icon="plus"
            onClick={() => addPage()}
          >
            Add Page
          </Button>
        </Flex>
      )}
      <List
        values={pages}
        lockVertically
        onChange={({ oldIndex, newIndex }) =>
          updatePages(pages, oldIndex, newIndex)
        }
        renderList={({ children, props, isDragged }) => (
          <S.SortableList isDragged={isDragged} {...props}>
            {children}
          </S.SortableList>
        )}
        renderItem={({ value, props, isDragged, isSelected, index }) => {
          const { key, ...restProps } = props
          return (
            <S.SortableItem
              key={key}
              {...restProps}
              isSelected={isSelected}
              isDragged={isDragged}
              currentPage={selectedPage === index + 1}
            >
              <PageShuffleItem
                deletePage={deletePage}
                value={value}
                index={index}
                theme={theme}
                themeLayout={themeLayout}
                goToPage={goToPage}
                disabled={disabled}
                preventDelete={preventDelete}
                isDeleting={isDeleting}
              />
            </S.SortableItem>
          )
        }}
      />
    </Flex>
  )
}
