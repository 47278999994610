const truncate = (text: string, length: number = 15): string => {
  try {
    return `${text.slice(0, length)}...`
  } catch (error) {
    console.error('Error truncating text:', error)
    return ''
  }
}

export default truncate
