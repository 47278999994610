import axios from 'api/axios.config'

// NOTE: Endpoint missing in backend
export const getFloralArrangements = async (case_id) => {
  try {
    const response = await axios.get(`floralArrangements?case_id=${case_id}`)
    return response
  } catch (error) {
    throw error
  }
}
