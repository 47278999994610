import { MenuButton } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import Text from 'components/priority/Text/Text'
import { TableFilter } from './'

export default ({
  getTableProps,
  nextPage,
  pageIndex,
  pageOptions,
  preGlobalFilteredRows,
  previousPage,
  searchPlaceholder,
  setGlobalFilter,
  showingArchived,
  state,
  toggleArchived,
}) => (
  <Flex justify="space-between" align="center" margin="0 0 20px 0">
    <Flex>
      {/* recent or archived dropdown — only shows if showingArchived exists */}
      {showingArchived !== undefined && (
        <MenuButton
          margin="0 16px 0 0"
          text={showingArchived ? 'Archived' : 'Recent'}
          items={[
            {
              text: 'Recent',
              onClick: () => toggleArchived(false),
            },
            {
              text: 'Archived',
              onClick: () => toggleArchived(true),
            },
          ]}
        />
      )}

      {/* filter input — Only shows if setGlobalFilter is declared */}
      {setGlobalFilter && (
        <TableFilter
          globalFilter={state.globalFilter}
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          searchPlaceholder={searchPlaceholder}
          {...getTableProps()}
        />
      )}
    </Flex>

    {/* page navigation */}
    <Flex>
      <Text margin="0 14px 0 0">
        Page {pageIndex + 1} of {pageOptions.length}
      </Text>
      <Icon
        icon="chevronLeft"
        onClick={() => previousPage()}
        fill={pageIndex === 0 ? 'gray4' : 'gray2'}
        margin="0 24px 0 0"
        clickable={pageIndex !== 0}
      />
      <Icon
        icon="chevronRight"
        onClick={() => nextPage()}
        fill={pageIndex === pageOptions.length - 1 ? 'gray4' : 'gray2'}
        clickable={pageIndex !== pageOptions.length - 1}
      />
    </Flex>
  </Flex>
)
