import styled, { css } from 'styled-components'
import spacing from 'styles/spacing'
import sizing from 'styles/sizing'

const responsiveGrid = ({ autoWidth, columns }) =>
  autoWidth
    ? css`
        width: 100%;
        justify-content: center;
        grid-template-columns: repeat(auto-fill, ${autoWidth});
      `
    : css`
        grid-template-columns: ${columns && `repeat(${columns}, auto)}`};
      `

export const Grid = styled.div(
  ({ columns, rows, gap, ...props }) => css`
    display: grid;
    grid-gap: ${gap};

    ${responsiveGrid}
    ${sizing(props)}
    ${spacing(props)}
  `,
)
