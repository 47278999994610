import { forwardRef, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Check } from 'components'

const RefWrapper = styled.div(
  ({ show }) => css`
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    padding-left: 0.75rem;
    opacity: ${show ? '1' : '0'};
    pointer-events: ${!show && 'none'};
    min-width: 20px;
    animation: ${show && 'fadeIn 0.25s'};
  `,
)

export default forwardRef(
  ({ show, indeterminate, checked, onChange: toggleCheck }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <RefWrapper show={show} ref={resolvedRef}>
        <Check
          checked={indeterminate || checked}
          icon={indeterminate && 'semiCheck'}
          onClick={() => toggleCheck({ target: resolvedRef })}
        />
      </RefWrapper>
    )
  },
)
